import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  Skeleton,
  Slide,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import "./CustomTable.css";
import React, { useEffect, useRef, useState } from "react";
import styles from "./CustomTable.module.css";
import { useDispatch, useSelector } from "react-redux";
import { getPathFromLocalStroage } from "../../utils";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import HistoryIcon from "@mui/icons-material/History";
import CloseIcon from "@mui/icons-material/Close";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SearchIcon from "@mui/icons-material/Search";

import moment from "moment";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import { toggleLoader } from "../../Store/loader/loaderReducer";
import axios from "axios";
import { toast } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ReviewsIcon from '@mui/icons-material/Reviews';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import ImageIcon from '@mui/icons-material/Image';
import { styled } from '@mui/material/styles';
import SocialPosts from "./SocialPosts";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const tabs = {
  0: "Edit",
  1: "Edit",
  2: "Edit",
  3: "Edit",
  4: "Review",
  5: "Restore",
};
const tableHeader = [
  "Drafts",
  "Scheduled",
  "Published",
  "Failed",
  "Pending Review",
  "Trash",
];
const hideDateTabs = [2, 4, 5];
const draftHead = ["Title", "Keywords", "Scheduled_Time", "Content"];

const social_icon = { 4: 'NewPost/facebook.svg', 5: 'NewPost/instagram.svg', 6: 'NewPost/x-icon.svg' };

const tagsOptions = [{ value: 'facebook' }, { value: 'instagram' }, { value: 'twitter' }];
const socialImg = [{ value: 'facebook', icon: 'NewPost/facebook.svg', img: '' }, { value: 'instagram', icon: 'NewPost/instagram.svg', img: '' }, { value: 'twitter', icon: 'NewPost/x-icon.svg', img: '' }];

const emojis = [
  "😀", "😁", "😂", "🤣", "😃", "😄", "😅", "😆", "😉", "😊", "😋", "😎", "😍", "😘",
  "😗", "😙", "😚", "🙂", "🤗", "🤔", "😐", "😑", "😶", "🙄", "😏", "😣", "😥", "😮",
  "🤐", "😯", "😪", "😫", "😴", "😌", "🤓", "😛", "😜", "😝", "🤤", "😒", "😓", "😔",
  "😕", "🙃", "🤑", "😲", "☹️", "🙁", "😖", "😞", "😟", "😤", "😢", "😭", "😦", "😧",
  "😨", "😩", "🤯", "😬", "😰", "😱", "🥵", "🥶", "😳", "🤪", "😵", "😡", "😠", "🤬",
  "😷", "🤒", "🤕", "🤢", "🤮", "🤧", "😇", "🥳", "🥴", "🥺", "🤠", "🤡", "🤥", "🤫",
  "🤭", "🧐", "🤓", "😈", "👿", "👹", "👺", "💀", "👻", "👽", "🤖", "💩", "😺", "😸",
  "😹", "😻", "😼", "😽", "🙀", "😿", "😾"
];

const CustomTable = ({ tabvalue, ids, draft, edit, search, searchValue, changeTabValue, usersDetails, nextPage, cursor }) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [drafts, setDrafts] = useState({
    Title: "",
    Keywords: "",
    Scheduled_Time: "",
    ConteScheduled_Timent: "",
    Org_Id: "",
    Id: "",
  });
  const [share, setShare] = useState(false);
  const [tags, setTags] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [posts, setPosts] = useState('');
  const [images, setImages] = useState('');
  const [slideOpen, setSlideOpen] = useState(false);
  const [formDetails, setFormDetails] = useState({});
  const [channelId, setChannelId] = useState([]);
  const [selectedPage, setSelectedPage] = useState(null);

  const dispatch = useDispatch();
  const handleClose = () => setOpen(false);
  const location = useLocation();
  const navigate = useNavigate();


  const scrollRef = useRef(null);
  const inputRef = useRef(null);
  const popupRef = useRef(null);

  const org_info = useSelector((state) => state?.org_info?.value);
  const org_id = org_info?.default_organizations?.Id;

  const post_info_val = useSelector((state) => state.post_info?.value?.data);
  const loader = useSelector((state) => state?.loader?.isLoading);

  let parts = location.pathname.split('/');
  const pathLocal = getPathFromLocalStroage();
  let editor = parts.slice(3).join('/');

  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [nextval, setNextval] = useState(null);

  const redioBtn = [0, 1];
  const Platform = ['Wix API', 'Centilio', 'Zoho', 'Facebook', 'Instagram', 'Twitter', 'WordPress API', '', 'Wix', 'WordPress'];

  useEffect(() => {
    setNextval(nextPage)
  }, [nextPage])

  const handleDraft = (item) => {

    let data = {
      title: item.Title,
      keywords: item.Keywords,
      scheduled_date: moment(item.Scheduled_Time, "DD/MM/YYYY hh:mm A").format("DD/MM/YYYY HH:mm"),
      content: item.Content,
      org_id: org_id,
      id: item.Id,
    };

    if (data.scheduled_date === 'Invalid date') {
      delete data.scheduled_date;
    }

    dispatch(toggleLoader(true));
    axios
      .post(process.env.REACT_APP_POST, data, {
        withCredentials: process.env.REACT_APP_CREDENTIALS,
      })
      .then((res) => {
        dispatch(toggleLoader(false));
        navigate(`/c${org_id}${pathLocal.path}`);
        changeTabValue(0)
        usersDetails()
      })
      .catch((err) => {
        toast.error(err.message);
        dispatch(toggleLoader(false));
      });
  };
  const handleSentForReview = (item) => {

    let data = {
      content: item.Content,
      org_id: org_id,
      post_id: item.Id,
    };

    // if (data.scheduled_date === 'Invalid date') {
    //   delete data.scheduled_date;
    // }

    dispatch(toggleLoader(true));
    axios
      .post(process.env.REACT_APP_SEND_FOR_REVIEW, data, {
        withCredentials: process.env.REACT_APP_CREDENTIALS,
      })
      .then((res) => {
        dispatch(toggleLoader(false));
        navigate(`/c${org_id}${pathLocal.path}`);
        changeTabValue(4);
        usersDetails()
      })
      .catch((err) => {
        // console.log('err', err.response.data.error.details);
        toast.error(err?.response?.data?.error?.details);
        dispatch(toggleLoader(false));
      });
  };

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  const handleView = (item) => {
    let data = {
      org_id: org_id,
      post_id: item.Id,
    };

    dispatch(toggleLoader(true));
    axios
      .post(process.env.REACT_APP_VIEWPOST, data, {
        withCredentials: process.env.REACT_APP_CREDENTIALS,
      })
      .then((res) => {
        dispatch(toggleLoader(false));
      })
      .catch((err) => {
        toast.error(err.message);
        dispatch(toggleLoader(false));
      });
  };

  const handleIntegrationChannel = (item) => {
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_INTEGRATION_INFO, { withCredentials: process.env.REACT_APP_CREDENTIALS, params: { org_id: org_id } })
      .then((res) => {
        const filteredData = res?.data?.integration_information.map(({ Channel_Id, Channel_Type }) => ({
          channelid: Channel_Id,
          channeltype: Channel_Type,
        }));
        handlePostDetails(item.Id);
        setChannelId(filteredData);
      })
      .catch((err) => {
        toast.error(err.message);
        dispatch(toggleLoader(false));
      });
  };

  const handlePostDetails = (post_id) => {
    axios.get(process.env.REACT_APP_POST_LINK, { withCredentials: process.env.REACT_APP_CREDENTIALS, params: { org_id: org_id, post_id: post_id } })
      .then((res) => {
        setPosts(res.data.link);
        let data = { ...formDetails };
        data['socialImg'] = res.data.socialImage;
        setFormDetails(data);
        setSelectedPage(res?.data?.socialImage[0]);
        setShare(true);
        dispatch(toggleLoader(false));
      })
      .catch((err) => {
        toast.error(err.message);
        dispatch(toggleLoader(false));
      });
  };

  const handleSharePost = (item, filteredData) => {
    let data = {
      org_id: org_id,
      post_id: item.Id,
      channel: filteredData
    };

    // axios.post(process.env.REACT_APP_SOCIAL_FEED, data, { withCredentials: process.env.REACT_APP_CREDENTIALS })
    //   .then((res) => {
    //     setShare(true);
    //     handlePostDetails(item.Id);
    //   })
    //   .catch((err) => {
    //     toast.error(err.message);
    //     dispatch(toggleLoader(false));
    //   });
  };

  const getTooltipTitle = (item) => {
    return (
      <Box className={styles.table_moreinfo_popper}>
        {tabvalue === 2 && <Button
          variant="text"
          startIcon={<ContentCopyIcon />}
          onClick={() => handlePostView(item)}
        >
          View Post
        </Button>}
        {tabvalue === 2 && <Button
          variant="text"
          startIcon={<ContentCopyIcon />}
          onClick={() => handleRevertToDraft(item)}
        >
          Revert to Draft
        </Button>}
        <Button
          startIcon={<ContentCopyIcon />}
          variant="text"
          onClick={() => handleDraft(item)}
        >
          Duplicate draft
        </Button>
        {redioBtn.includes(tabvalue) && <Button
          startIcon={<ReviewsIcon />}
          // className={styles.table_trash_btns_delete}
          variant="text"
          onClick={() => handleSentForReview(item)}
        >
          Send for review
        </Button>}
        {/* {tabvalue === 2 && <Button
          variant="text"
          startIcon={<ShareOutlinedIcon />}
          onClick={() => handleIntegrationChannel(item)}
        >
          Share Post
        </Button>} */}
        <Button
          startIcon={<DeleteOutlineIcon />}
          className={styles.table_trash_btns_delete}
          variant="text"
          onClick={() => ids([item], "Trash")}
        >
          Move to trash
        </Button>

      </Box>
    );
  };

  const isSelected = (item) => {
    return selectedItems.includes(item);
  };

  const handleCheckboxChange = (item) => {
    setSelectedItems((prevSelectedItems) => {
      if (prevSelectedItems.includes(item)) {
        return prevSelectedItems.filter((i) => i !== item);
      } else {
        return [...prevSelectedItems, item];
      }
    });
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  });

  useEffect(() => {
    if (post_info_val?.length > 0 && post_info_val?.length === selectedItems?.length) {
      setSelectAll(true);
    } else setSelectAll(false);
  }, [selectedItems]);

  useEffect(() => {
    setSelectedItems([]);
    setSelectAll(false);
  }, [tabvalue]);

  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedItems([]);
    } else setSelectedItems(post_info_val);
  };

  const handleTime = (e) => {
    if (e) {
      let val = dayjs(e.$d).format("DD-MMM-YYYY hh:mm");
      setDrafts(cur => ({ ...cur, Scheduled_Time: val }));
    }
  };

  const handleSaveDraft = () => {
    draft(drafts);
    handleClose();
    setSelectedItems([]);
  };

  const handleReject = () => {
    ids([drafts]);
    handleClose();
  };

  const handleTableButton = (item) => {
    if (tabvalue === 5) {
      ids([item], "Restore");
    } else edit(item);
  };

  const handleSearch = (e) => {
    search(e.target.value);
  };
  const handleRevertToDraft = (item) => {
    const id = item ? [item] : selectedItems;
    ids(id, "Revert")
    setSelectedItems([]);
  };


  const handlePostView = (item) => {
    let data = {
      org_id: org_id,
      post_id: item.Id,
    };

    dispatch(toggleLoader(true));
    axios
      .get(process.env.REACT_APP_VIEWPOST, {
        params: data,
        withCredentials: process.env.REACT_APP_CREDENTIALS,
      })
      .then((res) => {
        const URL = res.data.data.url
        window.open(URL, '_blank');
        dispatch(toggleLoader(false));
      })
      .catch((err) => {
        toast.error(err.message);
        dispatch(toggleLoader(false));
      });
  };

  const handleScroll = (e) => {
    if (nextval) {
      setScroll(true)
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
      if (scrollTop + clientHeight + 2 >= scrollHeight) {
        !loader && usersDetails('scroll');
        setScroll(false)
      }
    }

  }

  useEffect(() => {
    const divElement = scrollRef.current;
    if (divElement) {
      divElement.addEventListener('scroll', handleScroll);
      return () => divElement.removeEventListener('scroll', handleScroll);
    }
  }, []);


  const renderTabColumns = (item) => {
    switch (tabvalue) {
      case 0:
        return (
          <Box sx={{ display: "flex", gap: '5px' }}>
            <Box>{item.Created_Time}</Box>
            <Box sx={{ color: 'gray', padding: '2px', fontSize: '10px' }}>
              <FiberManualRecordIcon fontSize="none" /></Box>
            <Box sx={{ textTransform: 'capitalize !important' }}>{item.Channel_Type === 2 ? item.App_Name : Platform[item.Channel_Type - 1]}</Box>
            {item.Scheduled_Time && <Box sx={{ color: 'gray', padding: '2px', fontSize: '10px' }}>
              <FiberManualRecordIcon fontSize="none" /></Box>}
            {item.Scheduled_Time && <Box>Scheduled for {item.Scheduled_Time}</Box>}
          </Box>
        );
      case 1:
        return (
          <Box sx={{ display: "flex", gap: '5px' }}>
            <Box sx={{ textTransform: 'capitalize !important' }}>{item.Channel_Type === 2 ? item.App_Name : Platform[item.Channel_Type - 1]}</Box>
            {item.Scheduled_Time && <Box sx={{ color: 'gray', padding: '2px', fontSize: '10px' }}>
              <FiberManualRecordIcon fontSize="none" /></Box>}
            {item.Scheduled_Time && <Box>Scheduled for {item.Scheduled_Time}</Box>}
          </Box>
        );
      case 2:
        return (
          <Box sx={{ display: "flex", gap: '5px' }}>
            <Box>{item.Scheduled_Time}</Box>
            <Box sx={{ color: 'gray', padding: '2px', fontSize: '10px' }}>
              <FiberManualRecordIcon fontSize="none" /></Box>
            <Box sx={{ textTransform: 'capitalize !important' }}>{item.Channel_Type === 2 ? item.App_Name : Platform[item.Channel_Type - 1]}</Box>
          </Box>
        );
      case 3:
        return (
          <Box sx={{ display: "flex", gap: '5px' }}>
            <Box>{item.Modified_Time}</Box>
            <Box sx={{ color: 'gray', padding: '2px', fontSize: '10px' }}>
              <FiberManualRecordIcon fontSize="none" /></Box>
            <Box sx={{ textTransform: 'capitalize !important' }}>{item.Channel_Type === 2 ? item.App_Name : Platform[item.Channel_Type - 1]}</Box>
            <Box sx={{ color: 'gray', padding: '2px', fontSize: '10px' }}>
              <FiberManualRecordIcon fontSize="none" /></Box>
            <Box > <Tooltip title={item.Info}><Box maxWidth={'350px'} color={"red"} textOverflow={"ellipsis"} whiteSpace={"nowrap"} overflow={"hidden"}
            >{item.Info}</Box></Tooltip></Box>
          </Box>
        );
      case 4:
        return (
          <Box sx={{ display: "flex", gap: '5px' }}>
            <Box>{item.Created_Time}</Box>
            <Box sx={{ color: 'gray', padding: '2px', fontSize: '10px' }}>
              <FiberManualRecordIcon fontSize="none" /></Box>
            <Box sx={{ textTransform: 'capitalize !important' }}>{item.Channel_Type === 2 ? item.App_Name : Platform[item.Channel_Type - 1]}</Box>
            {item.Scheduled_Time && <Box sx={{ color: 'gray', padding: '2px', fontSize: '10px' }}>
              <FiberManualRecordIcon fontSize="none" /></Box>}
            {item.Scheduled_Time && <Box>Scheduled for {item.Scheduled_Time}</Box>}
          </Box>
        );
      case 5:
        return (
          <Box sx={{ display: "flex", gap: '5px' }}>
            <Box>{item.Created_Time}</Box>
            <Box sx={{ color: 'gray', padding: '2px', fontSize: '10px' }}>
              <FiberManualRecordIcon fontSize="none" /></Box>
            <Box sx={{ textTransform: 'capitalize !important' }}>{item.Channel_Type === 2 ? item.App_Name : Platform[item.Channel_Type - 1]}</Box>
          </Box>
        );
      default:
        return (
          <Box>
            <Box>{item.Created_Time}</Box>
            <Box sx={{ textTransform: 'capitalize !important' }}>{item.Channel_Type === 2 ? item.App_Name : Platform[item.Channel_Type - 1]}</Box>
          </Box>
        );
    }

  };

  const handleTags = (event) => {
    const tag = event.target.value;
    setTags(tag);
  };

  const handleEmojiClick = (emoji) => {
    setPosts((prev) => prev + emoji);
    setShowPopup(false);
  };

  const handleBlur = () => {
    if (showPopup) setShowPopup(false);
  };

  const handlePost = () => {
    let data = formDetails
    data?.socialImg?.forEach(obj => delete obj?.profileUrl);

    let formData = {
      org_id: org_id,
      channel: formDetails?.socialImg,
      caption: posts
    }
    dispatch(toggleLoader(true));
    axios.post(process.env.REACT_APP_SOCIAL_FEED, formData, { withCredentials: process.env.REACT_APP_CREDENTIALS })
      .then((res) => {
        console.log(res, '===============');
        dispatch(toggleLoader(false));
      })
      .catch((err) => {
        dispatch(toggleLoader(false));
      })
  };

  const handleUpload = (e) => {
    let form = {
      file: e,
      org_id: org_id,
    };

    const formData = new FormData();
    Object.keys(form).forEach((key) => {
      formData.append(key, form[key]);
    });

    dispatch(toggleLoader(true));
    axios.post(process.env.REACT_APP_SOCIAL_ATTACHMENT, formData, { withCredentials: process.env.REACT_APP_CREDENTIALS })
      .then((res) => {
        let data = { ...formDetails };
        const index = data.socialImg.findIndex(item => item.channelId === selectedPage?.channelId);
        data.socialImg[index]['file_ids'] = [...(data.socialImg[index]['file_ids'] || []), res?.data?.response?.fileId];
        data['total_file_id'] = [...(data['total_file_id'] || []), res?.data?.response?.fileId]
        setFormDetails(data);
        dispatch(toggleLoader(false));
      })
      .catch((err) => {
        dispatch(toggleLoader(false));
      })
  };

  const handleOpenSocial = (item) => {
    setSelectedPage(item);
    let data = { ...formDetails };
    if (posts?.length) {
      data['type'] = item?.channelType;
      data['image'] = images;
      setSlideOpen(!slideOpen);
      setFormDetails(data);
    }
  };
  const modalRef = useRef(null);

  const handleShareModalClose = () => {
    setShare(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        handleShareModalClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Box sx={{ padding: "2rem" }}>
      <Box className={styles.table_border_top}>
        <Box className={styles.table_border}>
          <Box>
            <FormGroup>
              <FormControlLabel
                className={styles.table_Select_box}
                control={
                  <Checkbox
                    checked={selectAll}
                    indeterminate={
                      selectedItems?.length !== 0 &&
                      selectedItems?.length !== post_info_val?.length
                    }
                    onChange={handleSelectAllChange}
                  />
                }
                label={
                  selectedItems?.length
                    ? `${selectedItems?.length} Selected`
                    : "Select all"
                }
                disabled={!post_info_val?.length}
              />
            </FormGroup>
          </Box>
          <Box className={styles.table_filter}>
            {selectedItems?.length ? (
              <Box className={styles.table_trash_btns}>
                {tabvalue === 2 && (
                  <Box>
                    <Button
                      startIcon={<HistoryIcon fontSize="small" />}
                      onClick={() => handleRevertToDraft()}
                      variant="text"
                    >
                      Revert to Draft
                    </Button>
                  </Box>
                )}
                <Box>
                  <Button
                    className={styles.table_trash_btns_delete}
                    startIcon={<DeleteOutlineIcon fontSize="small" />}
                    variant="text"
                    onClick={() => { ids(selectedItems, tabvalue === 5 ? "Delete" : "Trash"); setSelectedItems([]) }
                    }
                  >
                    {tabvalue === 5 ? "Delete" : "Move to Trash"}
                  </Button>
                </Box>
              </Box>
            ) : (
              <Box>
                <TextField
                  className="input_area_mui"
                  ref={inputRef}
                  sx={{
                    borderRadius: '30px',
                    height: '2rem',
                  }}
                  onChange={handleSearch}
                  placeholder="Search..."
                  value={searchValue}
                  id="myInput"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            )}
          </Box>
        </Box>
        {(loader && cursor === 1) ? (
          <div className={`${styles.table_body_data} ${styles.table_body_error_head}`} >
            <Box>
              <Box className={styles.table_body_error}>

              </Box>
            </Box>
          </div>
        ) : post_info_val?.length ? (
          <Box className={styles.table_body_data}
            onScroll={handleScroll}
            ref={scrollRef}
          >
            {post_info_val?.map((item, idx) => (
              <Box
                className={`${styles.table_user_title} ${isSelected(item) && styles.table_user_left_border
                  }`}
                key={idx}
              >
                <Box className={styles.table_user_title_border}>
                  <Box sx={{ width: '100%' }} className={styles.table_user_details}>
                    <Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isSelected(item)}
                            onChange={() => handleCheckboxChange(item)}
                          />
                        }
                      />
                    </Box>
                    <Box>
                      <img
                        alt="user_img"
                        style={{
                          padding: "1px 20px",
                          background: "#daeffe",
                          border: "12px solid #daeffe",
                          borderRadius: " 9px",
                        }}
                        src={
                          process.env.REACT_APP_IMAGE_URL +
                          "PostSvg/user_img.svg"
                        }
                      />
                    </Box>
                    <Box sx={{ width: '100%' }}>
                      <Box className={styles.table_title_name}>
                        <Tooltip title={item.Title}> {item.Title}</Tooltip>
                      </Box>
                      <Box sx={{ display: 'flex' }}>
                        <Box className={styles.table_title_phara}>
                          {renderTabColumns(item)}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box className={styles.table_btn_div}>

                    {tabvalue === 5 ? <Button
                      variant="contained"
                      className={styles.table_btn_edit}
                      disabled={item.Post_Processing_Status === 1}
                      onClick={() => handleTableButton(item)}
                    >
                      {tabs[tabvalue] ?? "Edit"}
                    </Button> :
                      <Button component={Link}
                        className={styles.table_btn_edit} // Use Link as the component for Button
                        to={`/c${org_id}/post/${editor}/${item.Id}`}// Set the path you want to navigate to
                        variant="contained"
                        disabled={item.Post_Processing_Status === 1}>
                        {tabs[tabvalue] ?? "Edit"}
                      </Button>
                    }

                    <Box>
                    </Box>

                    {item.Post_Processing_Status !== 1 ? <Box className={styles.table_user_more_icon}>
                      {tabvalue !== 5 ? (
                        <Tooltip
                          title={getTooltipTitle(item)}
                          componentsProps={{
                            tooltip: { sx: { bgcolor: "white" } },
                          }}
                          placement="left"
                          arrow
                        >
                          <MoreHorizIcon
                            className={styles.table_user_more_btn}
                            sx={{
                              border: "1px solid rgba(231, 234, 243, 0.70)",
                              borderRadius: "50%",
                              fill: "#377DFF",
                            }}
                          />
                        </Tooltip>
                      ) : (
                        <DeleteOutlineIcon
                          onClick={() => ids([item], "Delete")}
                          className={styles.table_user_delete_btn}
                          sx={{
                            border: "1px solid rgba(231, 234, 243, 0.70)",
                            borderRadius: "50%",
                            fill: "#377DFF",
                          }}
                        />
                      )}
                    </Box> : <CircularProgress size="1.4rem" />}
                  </Box>
                </Box>
              </Box>
            ))}
            {scroll && <Box sx={{ display: 'flex', textAlign: 'center', justifyContent: 'center', gap: "10px", padding: '10px' }}>
              {[...Array(3)].map((_, index) => (
                <Skeleton key={index} variant="circular" width={10} height={10} />
              ))}
            </Box>}
          </Box>
        ) : (
          <Box
            className={`${styles.table_body_data} ${styles.table_body_error_head}`}
          >
            <Box>
              <Box className={styles.table_body_error}>
                <img
                  alt="user_img"
                  style={{ width: "10%" }}
                  src={
                    process.env.REACT_APP_IMAGE_URL + "ErrorImg/errorimg.png"
                  }
                />
              </Box>
            </Box>
            <Box className={styles.table_body_error_text}>
              No {tableHeader[tabvalue]} Posts
            </Box>
          </Box>
        )}
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {draftHead?.map((item) => (
            <Box key={item} className={styles.modal_popup}>

              <Box className={styles.modal_popup_title}>{item}</Box>
              <FormControl size="small" fullWidth>
                {item !== "Scheduled_Time" ? (
                  <OutlinedInput
                    fullWidth
                    value={
                      drafts && item === "Scheduled_Time"
                        ? dayjs(moment(new Date(drafts[item])))
                        : drafts[item]
                    }
                    onChange={(e) =>
                      setDrafts(cur => ({ ...cur, [item]: e.target.value }))
                    }
                    className={styles.modal_popup_input}
                    placeholder={`Enter ${item} `}
                  />
                ) : (
                  // <LocalizationProvider dateAdapter={AdapterDayjs} slotProps={{ textField: { fullWidth: true } }}>
                  //     <DemoContainer
                  //         components={['DateTimePicker']}
                  //         sx={{
                  //             fieldset: { border: "1px solid #E6E7E9" },
                  //             '& .MuiOutlinedInput-root': {
                  //                 '&:hover fieldset': {
                  //                     borderColor: 'var(--primary) !important',
                  //                 },
                  //                 '& fieldset.MuiOutlinedInput-notchedOutline': {
                  //                     border: "1px solid #E6E7E9"
                  //                 },
                  //                 fontSize: '14px',
                  //                 paddingLeft: '2px',
                  //                 height: '41.92px'
                  //             },
                  //             paddingTop: '0px !important'
                  //         }}
                  //     >
                  //         <DemoItem>
                  //             <DateTimePicker
                  //                 format='DD-MMM-YYYY h:mm'
                  //                 value={dayjs(moment(new Date(drafts[item])))}
                  //                 viewRenderers={{ hours: renderTimeViewClock, minutes: renderTimeViewClock, seconds: renderTimeViewClock }}
                  //                 minDate={dayjs(new Date())}
                  //                 onChange={handleTime}
                  //                 sx={{ width: "100% !important", overflow: "hidden !important" }}
                  //             />
                  //         </DemoItem>
                  //     </DemoContainer>
                  // </LocalizationProvider>
                  <Box>
                    <Box className={styles.date_picker_wrap}>
                      <DatePicker
                        disabled={edit && tabvalue === 4}
                        selected={
                          drafts?.Scheduled_Time === "Invalid Date"
                            ? ""
                            : drafts?.Scheduled_Time
                        }
                        className={styles.date_picker}
                        onChange={handleTime}
                        minDate={new Date()}
                        timeInputLabel="Time:"
                        dateFormat="d-MMMM-yyy h:mm aa"
                        showTimeInput
                        wrapperClassName={styles.date_pickerr}
                        error={true}
                      />
                      <Box className={styles.clear_icon_wrap}>
                        {drafts?.Scheduled_Time && (
                          <CloseIcon
                            onClick={() =>
                              edit && tabvalue === 4
                                ? {}
                                : setDrafts({ ...drafts, Scheduled_Time: "" })
                            }
                            fontSize="small"
                            sx={{
                              display:
                                drafts?.Scheduled_Time === "Invalid Date" &&
                                "none",
                              "&:hover": {
                                color: "#ed4c78",
                                cursor:
                                  edit && tabvalue === 4
                                    ? "default"
                                    : "pointer",
                              },
                            }}
                          />
                        )}
                      </Box>
                    </Box>
                  </Box>
                )}
              </FormControl>
            </Box>
          ))}
          <Box
            className={styles.modal_popup_draft_btn}
            sx={{
              textAlign: "end",
              padding: "20px",
            }}
          >
            {tabvalue === 4 && (
              <Button
                variant="outlined"
                sx={{ colors: "red" }}
                onClick={() => handleReject()}
              >
                Reject
              </Button>
            )}
            <Button variant="contained" onClick={() => handleSaveDraft()}>
              Save as Draft
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={share}
        onClose={() => setShare(false)}
        sx={{ "& .MuiBackdrop-root": { backgroundColor: "#13214440" } }}
        closeAfterTransition
      >
        <Box style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, }}>
          <Box style={{ height: 'calc(100% - 60px)', padding: '30px', display: 'flex', justifyContent: 'center', marginTop: '25px' }}>
            <Box style={{ width: 'fit-content', position: 'relative' }} ref={modalRef}>
              <Box className={styles.file_wrap} style={{ marginRight: slideOpen && '384px', width: '720px' }} >
                <Box className={styles.title_wrap}>
                  <Box className={styles.title_text}>Create Post</Box>
                  <Select
                    value={tags}
                    onChange={handleTags}
                    className={styles.tags_btn}
                    sx={{ '& .MuiOutlinedInput-notchedOutline': { border: '.0625rem solid rgba(231,234,243,.7) !important' }, color: 'var(--table-header-font)', fontFamily: 'var(--primary-font-family) !important', '.MuiMenuItem-root': { height: '160px !important' } }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          '& .MuiMenuItem-root.Mui-selected': {
                            backgroundColor: 'rgba(189,197,209,.3)',
                            borderRadius: '5px',
                            color: 'var(--table-font-color) !important'
                          },
                        },
                      },
                    }}
                    displayEmpty
                    renderValue={(selected) => {
                      if (!selected || selected.length === 0) {
                        return <Box className={styles.tags_menu_item}>Add tags</Box>;
                      }
                      return selected;
                    }}
                  >
                    {tagsOptions.map((option) => (
                      <MenuItem
                        sx={{
                          color: 'var(--table-header-font) !important',
                          marginTop: '1px !important',
                          justifyContent: 'center',
                          display: 'flex',
                          "&:hover": {
                            backgroundColor: "rgba(189,197,209,.3)",
                            borderRadius: '5px'
                          }
                        }}
                        key={option.value} className={styles.tags_menu_item} value={option.value}>
                        {option.value}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                <Box className={styles.socialImg_icon_wrap}>
                  {formDetails?.socialImg?.map((item, i) => {
                    return (
                      <Box className={styles.socialImg_icons} onClick={() => handleOpenSocial(item)} key={i}>
                        {item?.profileUrl && <img src={item?.profileUrl} className={styles.img_icon} alt="social_icon" />}
                        {/* : <Box className={styles.profile_text}>{first_name?.charAt(0)?.toUpperCase()}</Box>} */}
                        <Box className={styles.icon_wrap}>
                          <img
                            src={process.env.REACT_APP_IMAGE_URL + social_icon[item?.channelType]}
                            className={styles.social_icon_img}
                            alt="social_icon"
                          />
                        </Box>
                      </Box>
                    )
                  })}
                </Box>
                <Box sx={{ display: 'flex' }}>
                  <img
                    src={process.env.REACT_APP_IMAGE_URL + social_icon[selectedPage?.channelType]}
                    className={styles.selected_icon_img}
                    alt="social_icon"
                  />
                  <Box className={styles.text_field} onBlur={handleBlur}>
                    <TextField
                      variant="outlined"
                      value={posts}
                      onChange={(e) => setPosts(e.target.value)}
                      sx={{
                        flex: 1,
                        "& .MuiOutlinedInput-root": {
                          "& fieldset.MuiOutlinedInput-notchedOutline": {
                            border: "none"
                          },
                          fontSize: "14px",
                          paddingLeft: "10px",
                        },
                      }}
                      placeholder="Start writing"
                      multiline
                      rows={15}
                      fullWidth
                    />
                    <Box>
                      <Box className={styles.post_upload_wrap}>
                        {formDetails?.total_file_id?.map((img) => {
                          return (
                            <Box className={styles.post_img_container}>
                              <img
                                src={`${process.env.REACT_APP_SOCIAL_ATTACHMENT_DRIVE}?file_id=${img}`}
                                alt='post_img' className={styles.post_images} />
                              <img
                                src={process.env.REACT_APP_IMAGE_URL + 'NewPost/remove.svg'}
                                className={styles.remove_icon}
                                alt="social_icon"
                              />
                            </Box>
                          )
                        })
                        }
                        <Box className={styles.drop_img}>
                          <Box component="label" tabIndex={-1} sx={{ cursor: 'pointer' }}>
                            <ImageIcon />
                            <Box className={styles.drop_img_text}>Drag & drop</Box><Box className={styles.drop_img_text}>or<span className={styles.drop_img_select_text} style={{ marginLeft: '2px' }}>select a</span><Box className={styles.drop_img_select_text}>file</Box></Box>
                            <VisuallyHiddenInput
                              type="file"
                              onChange={(event) => handleUpload(event.target.files[0])}
                              multiple
                            />
                          </Box>
                        </Box>
                      </Box>
                      <Box className={styles.upload_stickers}>
                        <Box className={styles.emoji_icon_wrap}>
                          <img
                            src={process.env.REACT_APP_IMAGE_URL + "NewPost/media-connect.svg"}
                            className={styles.social_icon_img}
                            alt="media_connect_icon"
                          />
                        </Box>
                        <Box className={styles.divider} />
                        <Box onClick={() => setShowPopup(true)} className={styles.emoji_icon_wrap}>
                          <img
                            src={process.env.REACT_APP_IMAGE_URL + "NewPost/emoji.svg"}
                            className={styles.social_icon_img}
                            alt="emoji_icon"
                          />
                        </Box>
                      </Box>
                      {showPopup && (
                        <Box className={styles.emoji_popup} ref={popupRef}>
                          {emojis.map((emoji, index) => (
                            <span
                              key={index}
                              className={styles.emoji_option}
                              onClick={() => handleEmojiClick(emoji)}
                            >
                              {emoji}
                            </span>
                          ))}
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
                {Object.keys(formDetails)?.map((item) => {
                  <Box sx={{ display: 'flex' }}>
                    <img
                      src={process.env.REACT_APP_IMAGE_URL + item?.image}
                      className={styles.selected_icon_img}
                      alt="social_icon"
                    />
                    <Box className={styles.text_field}>
                      <TextField
                        variant="outlined"
                        // onClick={}
                        sx={{
                          flex: 1,
                          "& .MuiOutlinedInput-root": {
                            "& fieldset.MuiOutlinedInput-notchedOutline": {
                              border: "none"
                            },
                            fontSize: "14px",
                            paddingLeft: "10px",
                          },
                        }}
                        placeholder="Start writing"
                        fullWidth
                      />
                    </Box>
                  </Box>
                })}
                <Button variant="contained" className={styles.post_btn} onClick={handlePost}>Post</Button>
              </Box>

              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  bottom: 0,
                  width: 364,
                  backgroundColor: '#fff',
                  padding: '25px',
                  zIndex: -1,
                  borderRadius: '4px',
                  transition: '250ms ease-in-out',
                  translate: slideOpen ? '0px' : '-97%'
                }}
                onClick={() => setShare(share)}
              >
                <SocialPosts formDetails={formDetails} posts={posts} seleceted={selectedPage} />
              </Box>
            </Box>

          </Box>
        </Box>
      </Modal >
    </Box >
  );
};

export default CustomTable;