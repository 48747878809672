import { Box, Tooltip } from "@mui/material";
import styles from "./CustomTable.module.css";

const SocialPosts = ({ formDetails, seleceted, posts }) => {
    switch (formDetails.type) {
        case 6:
            return <Box>
                <Box className={styles.social_page_name}>
                    <span>Twitter / X Preview</span>
                    <Tooltip title={'title'} placement={'top'} arrow componentsProps={{
                        tooltip: {
                            style: {
                                backgroundColor: 'var(--secondary)',
                                color: 'white',
                                fontSize: '.875rem',
                                padding: '.25rem .50rem',
                                borderRadius: '.3125rem'
                            },
                        },
                        arrow: {
                            style: {
                                color: 'var(--secondary)',
                            },
                        },
                    }}>
                        <img
                            src={process.env.REACT_APP_IMAGE_URL + "NewPost/info-icon.svg"}
                            className={styles.social_icon_img}
                            alt="media_connect_icon"
                        />
                    </Tooltip>
                </Box>
                <Box className={styles.common_wrap}>
                    <Box className={styles.social_page_img}>
                        <img
                            src={seleceted?.profileUrl}
                            className={styles.img_icon}
                            alt="profile"
                        />
                    </Box>
                    <Box className={styles.user_name}>The Flower Shop</Box>
                    <Box className={styles.user_sub_name}>The Flower Shop</Box>
                </Box>
                <Box sx={{ padding: '0px 0px 0px 37px' }}>
                    <Box className={styles.social_post_text}>{posts}</Box>
                    <Box className={formDetails?.total_file_id?.length > 2 && styles.img_container}>
                        {formDetails?.total_file_id?.map((img) => {
                            return (
                                <img
                                    src={`${process.env.REACT_APP_SOCIAL_ATTACHMENT_DRIVE}?file_id=${img}`}
                                    className={styles.facebook_post_page_img}
                                    style={{ width: formDetails?.total_file_id?.length > 2 ? 'calc(33.33% - 10px)' : '100%', maxWidth: formDetails?.total_file_id?.length > 2 && '100%' }}
                                    alt="media_connect_icon"
                                />)
                        })}
                    </Box>
                </Box>
            </Box>
        case 4:
            return <Box>
                <Box className={styles.social_page_name}>
                    <span>Facebook Preview</span>
                    <Tooltip title={'title'} placement={'top'} arrow componentsProps={{
                        tooltip: {
                            style: {
                                backgroundColor: 'var(--secondary)',
                                color: 'white',
                                fontSize: '.875rem',
                                padding: '.25rem .50rem',
                                borderRadius: '.3125rem'
                            },
                        },
                        arrow: {
                            style: {
                                color: 'var(--secondary)',
                            },
                        },
                    }}>
                        <img
                            src={process.env.REACT_APP_IMAGE_URL + "NewPost/info-icon.svg"}
                            className={styles.social_icon_img}
                            alt="media_connect_icon"
                        />
                    </Tooltip>
                </Box>
                <Box className={styles.facebook_preview}>
                    <Box className={styles.common_wrap} sx={{ padding: '10px', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex', gap: '10px' }}>
                            <Box className={styles.social_page_img}>
                                <img
                                    src={seleceted?.profileUrl}
                                    className={styles.img_icon}
                                    alt="profile"
                                />
                            </Box>
                            <Box>
                                <Box className={styles.user_name}>The-Flower-Shop</Box>
                                <Box className={styles.common_title_text}>
                                    <span className={styles.user_sub_name}>Just Now</span>
                                    <img
                                        src={process.env.REACT_APP_IMAGE_URL + "NewPost/earth-planet.svg"}
                                        className={styles.earth_planet_img}
                                        alt="media_connect_icon"
                                    />
                                </Box>
                            </Box>
                        </Box>
                        <img
                            src={process.env.REACT_APP_IMAGE_URL + "NewPost/three-dots.svg"}
                            className={styles.three_dot_img}
                            alt="media_connect_icon"
                        />
                    </Box>
                    <Box className={styles.social_post_text} sx={{ marginLeft: '12px' }}>{posts}</Box>
                    <Box className={formDetails?.total_file_id?.length > 2 && styles.img_container}>
                        {formDetails?.total_file_id?.map((img) => {
                            return (
                                <img
                                    src={`${process.env.REACT_APP_SOCIAL_ATTACHMENT_DRIVE}?file_id=${img}`}
                                    className={styles.facebook_post_page_img}
                                    style={{ width: formDetails?.total_file_id?.length > 2 ? 'calc(33.33% - 10px)' : '100%', maxWidth: formDetails?.total_file_id?.length > 2 && '100%' }}
                                    alt="media_connect_icon"
                                />)
                        })}
                    </Box>
                    <Box className={styles.facebook_btn}>
                        <Box className={styles.facebook_bottm_container}>
                            <img
                                src={process.env.REACT_APP_IMAGE_URL + "NewPost/thumbs-up.svg"}
                                className={styles.bottom_icon_img}
                                alt="media_connect_icon"
                            />
                            <span>Like</span>
                        </Box>
                        <Box className={styles.facebook_bottm_container}>
                            <img
                                src={process.env.REACT_APP_IMAGE_URL + "NewPost/comment-box.svg"}
                                className={styles.bottom_icon_img}
                                alt="media_connect_icon"
                            />
                            <span>Comment</span>
                        </Box>
                        <Box className={styles.facebook_bottm_container}>
                            <img
                                src={process.env.REACT_APP_IMAGE_URL + "NewPost/share.svg"}
                                className={styles.bottom_icon_img}
                                alt="media_connect_icon"
                            />
                            <span>Share</span>
                        </Box>
                    </Box>
                </Box>
            </Box>

        case 5:
            return <Box>
                <Box className={styles.social_page_name}>
                    <span>Instagram Preview</span>
                    <Tooltip title={'title'} placement={'top'} arrow componentsProps={{
                        tooltip: {
                            style: {
                                backgroundColor: 'var(--secondary)',
                                color: 'white',
                                fontSize: '.875rem',
                                padding: '.25rem .50rem',
                                borderRadius: '.3125rem'
                            },
                        },
                        arrow: {
                            style: {
                                color: 'var(--secondary)',
                            },
                        },
                    }}>
                        <img
                            src={process.env.REACT_APP_IMAGE_URL + "NewPost/info-icon.svg"}
                            className={styles.social_icon_img}
                            alt="media_connect_icon"
                        />
                    </Tooltip>
                </Box>
                <Box className={styles.facebook_preview}>
                    <Box className={styles.common_wrap} sx={{ justifyContent: 'space-between', padding: '10px' }}>
                        <Box className={styles.common_title_text}>
                            <Box className={styles.social_page_img}>
                                <img
                                    src={seleceted?.profileUrl}
                                    className={styles.img_icon}
                                    alt="profile"
                                />
                            </Box>
                            <Box className={styles.user_name}>The Flower Shop</Box>
                        </Box>
                        <img
                            src={process.env.REACT_APP_IMAGE_URL + "NewPost/three-dots.svg"}
                            className={styles.three_dot_img}
                            alt="media_connect_icon"
                        />
                    </Box>
                    <Box className={formDetails?.total_file_id?.length > 2 && styles.img_container}>
                        {formDetails?.total_file_id?.map((img) => {
                            return (
                                <img
                                    src={`${process.env.REACT_APP_SOCIAL_ATTACHMENT_DRIVE}?file_id=${img}`}
                                    className={styles.facebook_post_page_img}
                                    style={{ width: formDetails?.total_file_id?.length > 2 ? 'calc(33.33% - 10px)' : '100%', maxWidth: formDetails?.total_file_id?.length > 2 && '100%' }}
                                    alt="media_connect_icon"
                                />)
                        })}
                    </Box>
                    <Box className={styles.common_wrap} sx={{ justifyContent: 'space-between', padding: '10px' }}>
                        <Box className={styles.facebook_bottm_container} sx={{ gap: '10px' }}>
                            <img
                                src={process.env.REACT_APP_IMAGE_URL + "NewPost/insta-like.svg"}
                                className={styles.bottom_icon_img}
                                alt="insta_like_icon"
                            />
                            <img
                                src={process.env.REACT_APP_IMAGE_URL + "NewPost/insta-comment.svg"}
                                className={styles.bottom_icon_img}
                                alt="minsta_comment_icon"
                            />
                            <img
                                src={process.env.REACT_APP_IMAGE_URL + "NewPost/insta-share.svg"}
                                className={styles.bottom_icon_img}
                                alt="insta_share_icon"
                            />
                        </Box>
                        <img
                            src={process.env.REACT_APP_IMAGE_URL + "NewPost/bookmark.svg"}
                            className={styles.bottom_icon_img}
                            alt="bookmark_icon"
                        />
                    </Box>
                    {posts && <Box className={styles.insta_bottom_text}><span>The-Flower-Shop</span><span className={styles.insta_bottom_text_val}>{posts}</span></Box>}
                </Box>
            </Box>
        default:
            break;
    }
};

export default SocialPosts;