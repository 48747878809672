import { Box, Button, IconButton, Modal } from '@mui/material'
import React from 'react'
import styles from "./ConfirmationModal.module.css";
import CloseIcon from "@mui/icons-material/Close";

const ConfirmationModal = ({tabvalue,confirmation,id,type,setConfirmation,postOptions}) => {
  const DraftsTaps = [1, 2];
  return (
    <Box>
         <Modal
        open={confirmation}
        sx={{ "& .MuiBackdrop-root": { backgroundColor: "#13214440" } }}
        closeAfterTransition
      >
        <Box className={styles.file_wrap}>
          <Box>
            <Box className={styles.popup_head}>
              {type === "Trash"
                ? "Move post to trash?"
                : "Delete this post?"}
            </Box>
            <IconButton
              className={styles.close_btn}
              onClick={() => setConfirmation(false)}
            >
              <CloseIcon sx={{ "&:hover": { color: "#ed4c78" } }} />
            </IconButton>
          </Box>
          <Box className={styles.popup_paragraph}>
            {type === "Trash"
              ? DraftsTaps.includes(tabvalue) ? "Are you sure you want to move this post to trash? Its views, likes and comments will be deleted." : "You can always restore this post to Drafts later. "
              : "Are you sure you want to permanently delete this post?"}
          </Box>
          <Box className={styles.popup_btn}>
            <Button
              color="error"
              variant="outlined"
              onClick={() => setConfirmation(false)}
            >
              Cancel
            </Button>
            <Button
              color="error"
              variant="contained"
              onClick={() => postOptions(type)}
            >
              {type === "Trash" ? `Move to trash` : "Delete"}
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}

export default ConfirmationModal