import { Box, Button, IconButton, MenuItem, Modal, Select, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react'
import styles from './TopBar.module.css';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import CustomSelect from '../CustomTable/CustomSelect/CustomSelect';
import { persistStore } from 'redux-persist';
import store from '../../Store/store';
import axios from 'axios';
import { toast } from 'react-toastify';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { getPathFromLocalStroage } from '../../utils';
import { toggleLoader } from '../../Store/loader/loaderReducer';

const initial_account = ['My Account', 'Switch Organization', 'Current Plan', 'Logout'];

const TopBar = ({ setIsNavExpand, expand, isFetched }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isActive, setIsActive] = useState(false);
    const [account, setAccount] = useState(initial_account);
    const [switch_org, setSwitch_org] = useState(false);
    const [select_org, setSelect_org] = useState('');

    const org_info = useSelector((state) => state?.org_info?.value);

    const org_id = org_info?.default_organizations?.Id;
    const pathLocal = getPathFromLocalStroage();

    const user_role = org_info?.user_information?.userRole.includes('Super Admin') || org_info?.user_information?.userRole.includes('Admin');
    const first_name = org_info?.user_information?.First_Name;
    const last_name = org_info?.user_information?.Last_Name;
    const email = org_info?.user_information?.Email_Id;

    useEffect(() => {
        let menu_bar = org_info && JSON.parse(org_info?.properties)[0]?.MENU_BAR;
        if (expand === null) {
            setIsActive(menu_bar && JSON.parse(menu_bar));
        } else {
            setIsActive(expand);
        }
    }, [expand, org_info]);

    useEffect(() => {
        if (org_info?.other_organizations?.length > 1) {
            setSelect_org(org_info?.other_organizations[0]?.OrgName);
        };
    }, [org_info]);

    const handleProperties = () => {
        setIsNavExpand(!isActive);
        const data = {
            key_name: 'MENU_BAR',
            org_id: org_id,
            value: !isActive
        }
        // dispatch(toggleLoader(true));
        axios.post(process.env.REACT_APP_PROPERTIES, data, { withCredentials: process.env.REACT_APP_CREDENTIALS })
            .then((res) => {
                dispatch(toggleLoader(false));
                // setIsActive(!isActive);
            })
            .catch((err) => {
                // toast.error(err.message);
                dispatch(toggleLoader(false));
            });
    };

    const handleDayLeft = (date) => {
        let getdate = moment(new Date(date));
        const targetDate = new Date(getdate);
        return moment(targetDate).diff(moment(), 'days') === 0 ? 'Expires Today' : `${moment(targetDate).diff(moment(), 'days')} Days Left`;
    };

    const handleLogout = (option) => {
        switch (option) {
            case 'My Account':
                window.open(`${process.env.REACT_APP_ACCOUNT}/#/c${org_id}`, '_blank');
                break;
            case 'Go to Centilio Sign':
                window.open(process.env.REACT_APP_URL, '_blank');
                break;
            // case 'Subscription':
            //     window.open(`${process.env.REACT_APP_URL}/#/c${document?.OrgId}/priceList`, '_blank');
            //     break;
            case 'Switch Organization':
                setSwitch_org(true);
                break;
            default:
                const persistor = persistStore(store);
                axios.delete(process.env.REACT_APP_LOGOUT, { withCredentials: process.env.REACT_APP_CREDENTIALS })
                    .then((res) => {
                        persistor.purge();
                        window.open(process.env.REACT_APP_LOGIN, '_self');
                    })
                    .catch((err) => {
                        toast.error(err.message);
                    });
                break;
        }
    };

    const handleOrgUser = (e) => {
        setSelect_org(e.target.value);
    };

    const handleSwitchOrg = () => {
        setSwitch_org(false);
        let data = org_info?.other_organizations?.find(s => s.OrgName === select_org);
        if (data) {
            const persistor = persistStore(store);
            persistor.purge();
            navigate(`${data?.OrgUrlName}${pathLocal.path}`, { state: { switch_org: true } });
            window.location.reload(true);
        }
    };

    return (
        <Box className={styles.top_wrap}>
            {isFetched && <Box style={{ alignItems: 'center', display: 'contents' }}>
                <Box
                    style={{ cursor: 'pointer' }}
                    onClick={handleProperties}
                >
                    {isActive ? (
                        <div className={styles.expand} onClick={() => setIsActive(!isActive)}>
                            <img
                                src={process.env.REACT_APP_IMAGE_URL + 'sideBarSvg/expand-close.png'}
                                className={styles.expand_icon}
                                alt=''
                            />
                        </div>
                    ) : (
                        <div className={styles.expand_close} onClick={() => setIsActive(!isActive)}>
                            <img
                                src={process.env.REACT_APP_IMAGE_URL + 'sideBarSvg/expand.png'}
                                className={styles.expand_close_icon}
                                alt=''
                            />
                        </div>)}
                </Box>
                {user_role && <Box>
                    <Box className={styles.get_centilio}>Get Centilio Pro</Box>
                    <Box className={styles.days_left}>{org_info?.plan_details?.length && JSON.parse(org_info?.plan_details)?.plan_name !== 'Free' ? handleDayLeft(JSON.parse(org_info?.plan_details)?.expiry) : null}</Box>
                </Box>}
                <Box className={styles.left_container}>
                    {/* <Box className={styles.plan_wrapper}>
                        <Box className={styles.plan_name}>{org_info?.plan_details?.length ? JSON.parse(org_info?.plan_details)?.plan_name : null}</Box>
                    </Box> */}
                    <CustomSelect element={org_info?.user_information?.Profile_Id ? <img src={`${process.env.REACT_APP_PROFILE}?file_id=${org_info?.user_information?.Profile_Id}&org_id=${org_info?.default_organizations?.Id}`} className={styles.profile_image} alt='Profile_img' /> :
                        <div className={styles.profile_name}>
                            <div className={styles.profile_text}>{first_name?.charAt(0)?.toUpperCase()}</div>
                        </div>
                    }>
                        <Box style={{ width: '256px' }}>
                            <Box className={styles.info_wrap}>
                                {org_info?.user_information?.Profile_Id ? <img src={`${process.env.REACT_APP_PROFILE}?file_id=${org_info?.user_information?.Profile_Id}&org_id=${org_info?.default_organizations?.Id}`} className={styles.profile_image} alt='Profile_img' /> :
                                    <Box className={styles.profile_name}>
                                        <Box className={styles.profile_text}>{first_name?.charAt(0)?.toUpperCase()}</Box>
                                    </Box>}
                                <Box sx={{ marginLeft: '1rem' }}>
                                    <Box className={styles.first_name}>{`${first_name} ${last_name}`}</Box>
                                    <Tooltip title={email?.length > 22 && email} placement="top" arrow componentsProps={{
                                        tooltip: {
                                            style: {
                                                backgroundColor: 'var(--secondary)',
                                                color: 'white',
                                                fontSize: '.875rem',
                                                padding: '.25rem .50rem',
                                                borderRadius: '.3125rem'
                                            },
                                        },
                                        arrow: {
                                            style: {
                                                color: 'var(--secondary)',
                                            },
                                        },
                                    }}>
                                        <Box className={email?.length > 22 ? styles.email_text_len : styles.email_text}>{email}</Box>
                                    </Tooltip>
                                </Box>
                            </Box>
                            <Tooltip title={org_info?.default_organizations?.OrgName?.length > 30 && org_info?.default_organizations?.OrgName} placement="top" arrow componentsProps={{
                                tooltip: {
                                    style: {
                                        backgroundColor: 'var(--secondary)',
                                        color: 'white',
                                        fontSize: '.875rem',
                                        padding: '.25rem .50rem',
                                        borderRadius: '.3125rem'
                                    },
                                },
                                arrow: {
                                    style: {
                                        color: 'var(--secondary)',
                                    },
                                },
                            }}>
                                <Box className={org_info?.default_organizations?.OrgName?.length > 30 ? styles.org_name_text : styles.org_name}>{org_info?.default_organizations?.OrgName}</Box>
                            </Tooltip>
                            <Box className={styles.divider} />
                            <Box className={styles.dropdown_text}>
                                {account?.map((option, i) => {
                                    return (
                                        <React.Fragment key={i}>
                                            {option === 'Current Plan' ?
                                                <MenuItem
                                                    sx={{
                                                        "&:hover": {
                                                            backgroundColor: "0 0.1875rem 0.75rem #8c98a440",
                                                            borderRadius: '6px'
                                                        },
                                                    }}
                                                    key={option}
                                                    onClick={() => { }}>
                                                    <Box>
                                                        <Box className={styles.current_plan_container}>
                                                            <Box className={styles.current_plan_text}>{option}</Box>
                                                            <Box className={styles.plan_name_text}>{org_info?.plan_details?.length && JSON.parse(org_info?.plan_details)?.plan_name}</Box>
                                                        </Box>
                                                        <Box className={styles.current_plan_container} sx={{ marginTop: '2px' }}>
                                                            <Box className={styles.manage}>Manage</Box><img
                                                                src={process.env.REACT_APP_IMAGE_URL + 'new_tab_icon.svg'}
                                                                className={styles.new_tab_icon}
                                                                alt='new-tab'
                                                            />
                                                        </Box>
                                                    </Box>
                                                </MenuItem> :
                                                <MenuItem
                                                    sx={{
                                                        "&:hover": {
                                                            backgroundColor: "0 0.1875rem 0.75rem #8c98a440",
                                                            borderRadius: '6px'
                                                        },
                                                    }}
                                                    className={styles.account_text}
                                                    key={option}
                                                    onClick={() => handleLogout(option)}>
                                                    {option}
                                                </MenuItem>}
                                            {(account[i] === 'Switch Organization' || account[i] === 'Current Plan') && <Box className={styles.divider} />}
                                        </React.Fragment>
                                    );
                                })}
                            </Box>
                        </Box>
                    </CustomSelect>
                </Box>
            </Box>}
            <Modal
                open={switch_org}
                sx={{ '& .MuiBackdrop-root': { backgroundColor: '#13214440' } }}
                closeAfterTransition
            >
                <Box className={styles.org_wrap}>
                    <IconButton
                        className={styles.org_close}
                        onClick={() => setSwitch_org(false)}
                    >
                        <CloseIcon sx={{ "&:hover": { color: "#ed4c78" } }} />
                    </IconButton>
                    {org_info?.other_organizations?.length && <Box className={styles.org_container}>
                        <Box className={styles.org_textfield}>
                            Switch Organization
                        </Box>
                        <Select
                            MenuProps={{ classes: { paper: styles.select_pagination } }}
                            sx={{
                                "& .MuiOutlinedInput-notchedOutline": { border: 'none !important' },
                                mt: 5,
                                minWidth: 80,
                                width: '100%',
                                height: '38px',
                                borderBottom: '2px solid var(--border-color) !important',
                                fontFamily: 'var(--primary-font-family)',
                            }}
                            value={select_org ? select_org : org_info?.other_organizations[0]?.OrgName}
                            onChange={handleOrgUser}
                        >
                            {org_info?.other_organizations?.length && org_info?.other_organizations?.map((item, i) => (
                                <MenuItem className={styles.pagination_item} key={i} value={item?.OrgName}>
                                    {item?.OrgName}
                                </MenuItem>
                            ))}
                        </Select>
                        <Button variant='contained' className={styles.switch_btn} onClick={() => handleSwitchOrg()}>Switch</Button>
                    </Box>}
                </Box>
            </Modal>
        </Box>
    )
};

export default TopBar;