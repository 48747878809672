import React, { useEffect, useMemo, useRef, useState } from "react";
import { Box, Button, Checkbox, debounce, IconButton, InputAdornment, ListItemText, MenuItem, OutlinedInput, Select, TextField, Tooltip } from "@mui/material";
import styles from './Calendar.module.css';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import { toggleLoader } from "../../Store/loader/loaderReducer";
import axios from "axios";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from '@mui/icons-material/FilterList';
import moment from "moment";

const eventTypes = ['scheduled', 'published', 'failed', 'pending'];

const viewOptions = [
    { value: 'dayGridMonth', label: 'Month' },
    { value: 'timeGridWeek', label: 'Week' },
    { value: 'timeGridDay', label: 'Day' },
    { value: 'listWeek', label: 'List' },
];

const options = [{ name: "Scheduled", color: "#00C9A7" }, { name: "Published", color: "green" }, { name: "Pending", color: "#377DFF" }, { name: "Failed", color: "#ED4C78" }];

const Calendar = () => {
    const [currentView, setCurrentView] = useState("dayGridMonth");
    const [events, setEvents] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState(['Scheduled', 'Pending']);
    const [customTitle, setCustomTitle] = useState("");

    const calendarRef = useRef();
    const dispatch = useDispatch();
    const currentTimezone = moment.tz.guess();

    const org_info = useSelector((state) => state?.org_info?.value);
    const org_id = org_info?.default_organizations?.Id;

    const getMonthStartAndEndTimestamps = (timezone) => {
        const startOfMonth = moment.tz(timezone).startOf("month");
        const endOfMonth = moment.tz(timezone).endOf("month");

        return {
            startTimestamp: startOfMonth.valueOf(),
            endTimestamp: endOfMonth.valueOf(),
        };
    };

    const convertToTimestamp = (date) => {
        return new Date(date).getTime();
    };

    useEffect(() => {
        const { startTimestamp, endTimestamp } = getMonthStartAndEndTimestamps(currentTimezone);
        fetchPosts({ from: startTimestamp, to: endTimestamp, status: "1,4" });
    }, []);

    const fetchPosts = (item) => {
        let data = {
            status: item?.status,
            limit: 20,
            cursor: 1,
            org_id: org_id,
            from: item?.from,
            to: item?.to
        };

        if (item?.type === 'search') data['search'] = item?.value;
        dispatch(toggleLoader(true));
        axios.get(process.env.REACT_APP_POST, { params: data, withCredentials: process.env.REACT_APP_CREDENTIALS })
            .then((resp) => {
                const res = resp?.data?.data;
                const transformedData = res?.map(item => ({
                    id: item?.Id,
                    title: item?.Title,
                    start: moment(item.Scheduled_Time, 'DD/MM/YYYY hh:mm A').format('YYYY-MM-DDTHH:mm:ss'),
                    end: moment(item.Scheduled_Time, 'DD/MM/YYYY hh:mm A').format('YYYY-MM-DDTHH:mm:ss'),
                    eventType: eventTypes[item?.Status - 1]
                }));
                setEvents(transformedData);
                dispatch(toggleLoader(false));
            })
            .catch((err) => {
                toast.error(err.message);
                dispatch(toggleLoader(false));
            });
    };

    const handleToggle = (value) => {
        let arr;
        setSelectedOptions((prev) => {
            if (prev.includes(value)) {
                arr = prev.filter((item) => item !== value);
            } else {
                arr = [...prev, value];
            }
            return arr;
        });

        const calendarApi = calendarRef.current.getApi();
        calendarApi.changeView(currentView);

        const status = arr?.map(item => eventTypes.findIndex(el => el.toLowerCase() === item.toLowerCase())).filter(index => index !== -1).map(index => index + 1).join(', ');
        debouncedSendRequest({ from: convertToTimestamp(calendarApi.currentData.dateProfile.activeRange.start), to: convertToTimestamp(calendarApi.currentData.dateProfile.activeRange.end), status: status });
    };

    const handleViewChange = (event) => {
        const selectedView = event.target.value;
        setCurrentView(selectedView);
        const calendarApi = calendarRef.current.getApi();
        calendarApi.changeView(selectedView);

        const status = selectedOptions?.map(item => eventTypes.findIndex(el => el.toLowerCase() === item.toLowerCase())).filter(index => index !== -1).map(index => index + 1).join(', ');
        fetchPosts({ from: convertToTimestamp(calendarApi.currentData.dateProfile.activeRange.start), to: convertToTimestamp(calendarApi.currentData.dateProfile.activeRange.end), status: status });

        if (selectedView !== 'dayGridMonth') {
            const timeSlotLabels = document.querySelectorAll('.fc-timegrid-slot-label');
            timeSlotLabels.forEach((label) => {
                label.style.fontSize = '13px';
                label.style.color = '#97A4A7';
            });
            const events = document.querySelectorAll('.fc-timegrid-event .fc-event-main');
            events.forEach(event => {
                event.style.setProperty('padding', '0', 'important');
            });
        }
    };

    const handleEventDrop = (info) => {
        let eventType = info?.event?._def?.extendedProps?.eventType;

        //|| eventType === 'pending'
        if ((eventType === "scheduled") && (moment(new Date()).format('YYYY-MM-DDTHH:mm:ss') <= moment(info.event.start).format('YYYY-MM-DDTHH:mm:ss'))) {
            const newEvent = events.map((item) => {
                if (item.id === Number(info.event?._def?.publicId)) return { ...item, start: moment(info.event.start).format('YYYY-MM-DDTHH:mm:ss'), end: moment(info.event.start).format('YYYY-MM-DDTHH:mm:ss') }
                return item;
            });
            setEvents(newEvent);

            dispatch(toggleLoader(true));
            let data = { org_id: org_id, post_id: info.event?._def?.publicId, scheduled_date: moment(info.event.start).format('DD-MMM-YYYY hh:mm a') };
            axios.put(process.env.REACT_APP_SCHEDULE, data, { withCredentials: process.env.REACT_APP_CREDENTIALS })
                .then((res) => {
                    dispatch(toggleLoader(false));
                })
                .catch((err) => {
                    toast.error(err.message);
                    dispatch(toggleLoader(false));
                    setEvents(events);
                });
        } else info.revert();
    };

    const handleTodayClick = () => {
        const calendarApi = calendarRef.current.getApi();
        calendarApi.today();
    };

    const handlePrevClick = () => {
        const calendarApi = calendarRef.current.getApi();
        calendarApi.prev();

        const status = selectedOptions?.map(item => eventTypes.findIndex(el => el.toLowerCase() === item.toLowerCase())).filter(index => index !== -1).map(index => index + 1).join(', ');
        fetchPosts({ from: convertToTimestamp(calendarApi.currentData.dateProfile.activeRange.start), to: convertToTimestamp(calendarApi.currentData.dateProfile.activeRange.end), status: status });
        if (calendarApi?.currentData?.currentViewType !== 'dayGridMonth') {
            const timeSlotLabels = document.querySelectorAll('.fc-timegrid-slot-label');
            timeSlotLabels.forEach((label) => {
                label.style.fontSize = '13px';
                label.style.color = '#97A4A7';
            });
        }
    };

    const handleNextClick = () => {
        const calendarApi = calendarRef.current.getApi();
        calendarApi.next();

        const status = selectedOptions?.map(item => eventTypes.findIndex(el => el.toLowerCase() === item.toLowerCase())).filter(index => index !== -1).map(index => index + 1).join(', ');
        fetchPosts({ from: convertToTimestamp(calendarApi.currentData.dateProfile.activeRange.start), to: convertToTimestamp(calendarApi.currentData.dateProfile.activeRange.end), status: status });
        if (calendarApi?.currentData?.currentViewType !== 'dayGridMonth') {
            const timeSlotLabels = document.querySelectorAll('.fc-timegrid-slot-label');
            timeSlotLabels.forEach((label) => {
                label.style.fontSize = '13px';
                label.style.color = '#97A4A7';
            });
        }
    };

    const handleBackGroundColor = (eventInfo) => {
        switch (eventInfo) {
            case "scheduled":
                return "#B1F1FB";
            case "pending":
                return "#EAF1FF";
            case "published":
                return "#caf1d8cc";
            case "failed":
                return "#FDEEF2";
            default:
                break;
        };
    };

    const updateMonthYearTitle = (view, type) => {
        if (type) {
            const currentDate = new Date(view.view.currentStart);
            if (view.view.type === 'timeGridWeek' || view.view.type === 'listWeek') {
                const startOfWeek = new Date(view.view.currentStart);
                const endOfWeek = new Date(view.view.currentEnd);
                endOfWeek.setDate(startOfWeek.getDate() + 6);

                const startMonth = startOfWeek.toLocaleString("default", { month: "short" });
                const startDay = startOfWeek.getDate();
                const endMonth = endOfWeek.toLocaleString("default", { month: "short" });
                const endDay = endOfWeek.getDate();
                const year = startOfWeek.getFullYear();
                setCustomTitle(`${startMonth} ${startDay} – ${endMonth} ${endDay}, ${year}`);
            } else if (view.view.type === 'timeGridDay') {
                const month = currentDate.toLocaleString("default", { month: "long" });
                const day = currentDate.getDate();
                const year = currentDate.getFullYear();
                setCustomTitle(`${month} ${day}, ${year}`);
            }
        } else {
            const month = view.toLocaleString("default", { month: "long" });
            const year = view.getFullYear();
            setCustomTitle(`${month} ${year}`);
        }
    };

    useEffect(() => {
        const date = new Date();
        updateMonthYearTitle(date);
    }, []);

    // document.querySelectorAll('.fc-scroller.fc-scroller-liquid-absolute').forEach((element) => {
    //     element.style.overflow = 'hidden';
    // });

    // document.querySelectorAll('.fc-scroller').forEach((element) => {
    //     element.style.overflow = 'hidden';
    // });

    const handleDateChange = (view) => {
        const currentDate = new Date(view.view.currentStart);
        if (view?.view?.type === "dayGridMonth") updateMonthYearTitle(currentDate);
        else updateMonthYearTitle(view, 'change_month');

        const todayCells = document.querySelectorAll(".fc-day-today .fc-daygrid-day-number");
        todayCells.forEach((cell) => {
            cell.style.backgroundColor = "var(--primary)";
            cell.style.borderRadius = "50%";
            cell.style.width = "30px";
            cell.style.height = "30px";
            cell.style.lineHeight = "30px";
            cell.style.color = "#fff";
            cell.style.display = "flex";
            cell.style.justifyContent = "center";
            cell.style.alignItems = "center";
        });
    };

    const handleEventDidMount = (info) => {
        const view = info.view.type;

        if (view === 'timeGridDay') {
            info.el.style.width = '30%';
            info.el.style.borderRadius = '6px';
            info.el.style.overflow = 'hidden';
            info.el.style.whiteSpace = 'nowrap';
            info.el.style.textOverflow = 'ellipsis';
            info.el.style.setProperty('padding', '0', 'important');
        } else if (view === "listWeek") {
            info.el.style.backgroundColor = "#fff";
            info.el.style.display = "flex";
            info.el.style.alignItems = "center";
            info.el.style.justifyContent = "flex-start";
            info.el.style.border = "none";
            info.el.style.gap = "20px";
            info.el.style.paddingLeft = "3px";

            const cells = info.el.querySelectorAll("td");
            cells.forEach((cell) => {
                cell.style.backgroundColor = "transparent";
                cell.style.border = 'none';
            });

            const timeCell = info.el.querySelector(".fc-list-event-time");
            const titleCell = info.el.querySelector(".fc-list-event-title");

            if (timeCell) {
                timeCell.style.fontSize = "14px";
                timeCell.style.color = 'var(--table-header-font)';
                timeCell.style.border = 'none';
                timeCell.style.width = '16%';
            }

            if (titleCell) {
                titleCell.style.width = "30%";
                titleCell.style.border = 'none';
                titleCell.style.marginLeft = '20px';
                titleCell.style.setProperty('background-color', 'transparent', 'important');
            }

            const dot = info.el.querySelector(".fc-list-event-dot");
            if (dot) {
                dot.style.backgroundColor = handleBackGroundColor(info.event.extendedProps.eventType);
                dot.style.borderRadius = "50%";
                dot.style.width = "10px";
                dot.style.height = "10px";
                dot.style.border = "none";
            }

            const dayHeaders = document.querySelectorAll(".fc-list-day-cushion");
            dayHeaders.forEach((header) => {
                header.style.backgroundColor = "#E7EAF380";
                header.style.textAlign = "left";
                header.style.padding = "10px";
                header.style.height = "37px";
                header.style.display = "flex";
                header.style.alignItems = "center";
            });
        } else {
            info.el.style.width = '96%';
            info.el.style.setProperty('width', '96%', 'important');
            info.el.style.marginLeft = '4px';
            info.el.style.marginRight = '4px';
            info.el.style.borderRadius = '3px';
            info.el.style.overflow = 'hidden';
            info.el.style.whiteSpace = 'nowrap';
            info.el.style.textOverflow = 'ellipsis';
            info.el.style.setProperty('padding', '0', 'important');
            info.el.style.setProperty('background-color', 'transparent', 'important');
            info.el.style.setProperty('border', 'none', 'important');
            info.el.style.setProperty('box-shadow', 'none', 'important');

            const cell = info.el.closest('.fc-daygrid-day');
            const events = cell?.querySelectorAll('.fc-daygrid-event');

            if (events && events[0] === info.el) {
                info.el.style.marginTop = '7px';
            }
        };
    };

    const debouncedSendRequest = useMemo(() => debounce(fetchPosts, 300), []);

    const handleSearch = (e) => {
        const { startTimestamp, endTimestamp } = getMonthStartAndEndTimestamps(currentTimezone);
        if (e.target.value?.length) debouncedSendRequest({ from: startTimestamp, to: endTimestamp, status: "1,2,4", value: e.target.value, type: 'search' });
        else fetchPosts({ from: startTimestamp, to: endTimestamp, status: "1,2,4" });
    };

    const handleEditor = (e) => {
        let item = e?.event?._def;
        if (item?.extendedProps?.eventType === 'pending') {
            window.open(`${process.env.REACT_APP_URL}/#/c${org_id}/post/pending-review/${item?.publicId}`, '_blank');
        } else {
            window.open(`${process.env.REACT_APP_URL}/#/c${org_id}/post/${item?.extendedProps?.eventType}/${item?.publicId}`, '_blank')
        }
    };

    return (
        <Box className={styles.main_wrap}>
            <Box className={styles.header_body}><Box className={styles.header_name}>Calendar</Box></Box>
            <Box className={styles.calendar_wrapper}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} mt={2}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Box>
                            <Button className={styles.today_btn} variant="outlined" onClick={handleTodayClick}>
                                Today
                            </Button>
                            <Tooltip title={"Previous month"} placement="top" arrow componentsProps={{
                                tooltip: {
                                    style: {
                                        backgroundColor: 'var(--secondary)',
                                        color: 'white',
                                        fontSize: '.875rem',
                                        padding: '.25rem .50rem',
                                        borderRadius: '.3125rem'
                                    },
                                },
                                arrow: {
                                    style: {
                                        color: 'var(--secondary)',
                                    },
                                },
                            }}>
                                <IconButton sx={{ marginLeft: '5px' }} color="primary" onClick={handlePrevClick}>
                                    <ChevronLeftIcon sx={{ color: 'var(--table-header-font)', "&:hover": { color: "var(--primary)" } }} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={"Next month"} placement="top" arrow componentsProps={{
                                tooltip: {
                                    style: {
                                        backgroundColor: 'var(--secondary)',
                                        color: 'white',
                                        fontSize: '.875rem',
                                        padding: '.25rem .50rem',
                                        borderRadius: '.3125rem'
                                    },
                                },
                                arrow: {
                                    style: {
                                        color: 'var(--secondary)',
                                    },
                                },
                            }}>
                                <IconButton color="primary" sx={{ color: 'var(--table-header-font)', "&:hover": { color: "var(--primary)" }, marginLeft: '5px' }} onClick={handleNextClick}>
                                    <ChevronRightIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <Box className={styles.calendar_header}>{customTitle}</Box>
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <TextField
                            variant="outlined"
                            placeholder="Search by title"
                            onChange={handleSearch}
                            sx={{
                                flex: 1,
                                width: '25.25rem',
                                marginRight: '15px !important',
                                "& .MuiOutlinedInput-root": {
                                    "& fieldset.MuiOutlinedInput-notchedOutline": {
                                        border: "1px solid #E6E7E9"
                                    },
                                    fontSize: "14px",
                                    paddingLeft: "10px",
                                    height: "42.58px",
                                },
                            }}
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start" sx={{ marginLeft: "8px" }}>
                                        <SearchIcon sx={{ color: 'var(--table-header-font) !important', fontSize: "18px" }} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Select
                            value={['Filter']}
                            multiple
                            renderValue={() => ['Filter'].join(', ')}
                            className={styles.year_month_btn}
                            sx={{
                                '& .MuiOutlinedInput-notchedOutline': { border: '.0625rem solid rgba(231,234,243,.7) !important' }, color: 'var(--table-header-font)', fontFamily: 'var(--primary-font-family) !important', '.MuiMenuItem-root': { width: '100px !important', height: '160px !important' }, '& .MuiSelect-select': {
                                    backgroundColor: 'transparent !important',
                                    '&:hover': {
                                        backgroundColor: 'transparent',
                                    }, '&.Mui-selected:hover': { backgroundColor: 'transparent !important' }
                                },
                            }}
                            input={
                                <OutlinedInput
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <FilterListIcon fontSize="1rem" />
                                        </InputAdornment>
                                    }
                                    label="Select Option"
                                />
                            }
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        borderRadius: '4px',
                                    },
                                },
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                },
                                transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'left',
                                },
                            }}
                        >
                            <MenuItem className={styles.card_subtitle} disabled>My calendars</MenuItem>
                            {options.map((option) => (
                                <MenuItem disableRipple={false} key={option.name} onClick={() => handleToggle(option.name)} sx={{ marginLeft: '-10px', minHeight: '0 !important', padding: '4px 16px !important', '&:hover': { backgroundColor: 'transparent' }, '&.Mui-selected': { backgroundColor: 'none !important' } }}>
                                    <Checkbox disableRipple
                                        icon={<Box sx={{
                                            width: 15,
                                            height: 15,
                                            borderRadius: '5px',
                                            border: '2px solid var(--border-color)'
                                        }}></Box>}
                                        checkedIcon={<Box sx={{
                                            backgroundColor: option.color,
                                            width: 15,
                                            height: 15,
                                            borderRadius: '5px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}>
                                            <img
                                                style={{
                                                    width: 'inherit',
                                                    height: 'inherit',
                                                }}

                                                alt="checked"
                                                src={process.env.REACT_APP_IMAGE_URL + 'sideBarSvg/checked.svg'}
                                            />
                                        </Box>} checked={selectedOptions.includes(option.name)} />
                                    <ListItemText
                                        primaryTypographyProps={{ style: { fontSize: '14px', fontFamily: 'var(--primary-font-family) !important', color: 'var(--table-header-font) !important' } }}
                                        sx={{
                                            "&:hover": {
                                                backgroundColor: "none",
                                            }
                                        }} primary={option.name} />
                                </MenuItem>
                            ))}
                        </Select>
                        <Select
                            value={currentView}
                            onChange={handleViewChange}
                            className={styles.year_month_btn}
                            sx={{ '& .MuiOutlinedInput-notchedOutline': { border: '.0625rem solid rgba(231,234,243,.7) !important' }, color: 'var(--table-header-font)', fontFamily: 'var(--primary-font-family) !important', '.MuiMenuItem-root': { height: '160px !important' } }}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        '& .MuiMenuItem-root.Mui-selected': {
                                            backgroundColor: 'rgba(189,197,209,.3)',
                                            borderRadius: '5px',
                                            color: 'var(--table-font-color) !important'
                                        },
                                    },
                                },
                            }}
                        >
                            {viewOptions.map((option) => (
                                <MenuItem
                                    sx={{
                                        color: 'var(--table-header-font) !important',
                                        marginTop: '1px !important',
                                        justifyContent: 'center',
                                        display: 'flex',
                                        "&:hover": {
                                            backgroundColor: "rgba(189,197,209,.3)",
                                            borderRadius: '5px'
                                        }
                                    }}
                                    key={option.value} className={styles.year_month_menu_item} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                </Box>
                <FullCalendar
                    ref={calendarRef}
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
                    initialView='dayGridMonth'
                    weekends={true}
                    dayMaxEvents={2}
                    moreLinkClick={(arg) => {
                        return false;
                    }}
                    moreLinkContent={(args) => {
                        const { num } = args;
                        return `+${num} more`;
                    }}
                    events={(info, successCallback, failureCallback) => {
                        successCallback(events);
                    }}
                    eventContent={(eventInfo) => {
                        let backgroundColor = handleBackGroundColor(eventInfo.event.extendedProps.eventType);
                        const titleLength = eventInfo.event.title.length;
                        const calculatedWidth = Math.max(50, titleLength * 10);
                        return (
                            <Tooltip title={eventInfo.event.title} placement="top" arrow componentsProps={{
                                tooltip: {
                                    style: {
                                        backgroundColor: 'var(--secondary)',
                                        color: 'white',
                                        fontSize: '.875rem',
                                        padding: '.30rem .50rem',
                                        borderRadius: '.3125rem'
                                    },
                                },
                                arrow: {
                                    style: {
                                        color: 'var(--secondary)',
                                    },
                                },
                            }}>
                                <Box className={styles.calendar_title}
                                    sx={{
                                        backgroundColor: eventInfo.view.type === 'listWeek' ? `unset !important` : `${backgroundColor} !important`,
                                        textOverflow: calculatedWidth > 170 ? 'ellipsis !important' : 'unset',
                                        color: eventInfo.view.type === 'listWeek' ? 'var(--table-header-font)' : 'var(--table-font-color)',
                                        fontWeight: eventInfo.view.type !== 'listWeek' && 600,
                                        marginTop: eventInfo.view.type === 'dayGridMonth' && !eventInfo?.isToday && '5px !important',
                                        borderRadius: eventInfo.view.type === 'timeGridWeek' ? '3px' : '5px'
                                    }}
                                    onClick={() => handleEditor(eventInfo)}>
                                    {eventInfo.event.title}
                                </Box>
                            </Tooltip>
                        )
                    }}
                    dayCellDidMount={(info) => {
                        const dateNumberElement = info.el.querySelector(".fc-daygrid-day-number");
                        if (info.isToday) {
                            info.el.style.backgroundColor = "transparent";
                        }

                        if (dateNumberElement) {
                            dateNumberElement.style.position = "relative";
                            dateNumberElement.style.display = "inline-block";
                            dateNumberElement.style.fontSize = "14px";
                            dateNumberElement.style.color = "#132144";
                            dateNumberElement.style.fontFamily = "var(--primary-font-family)";
                            dateNumberElement.style.top = "5px";
                            dateNumberElement.style.right = "5px";
                        }
                        // info.el.style.height = '10rem';

                        const dayCell = info.el;
                        if (info?.view?.type === "dayGridMonth") {
                            dayCell.style.height = '160px';
                            dayCell.style.minHeight = '160px';
                        }
                    }}
                    headerToolbar={false}
                    eventDrop={handleEventDrop}
                    editable={true}
                    droppable={true}
                    datesSet={handleDateChange}
                    eventDidMount={handleEventDidMount}
                    dayHeaderContent={(arg) => {
                        return <Box className={arg.view.type === 'listWeek' ? styles.day_header_text_list : styles.day_header_text}>{arg?.text}</Box>;
                    }}
                    dayNumberContent={(arg) => {
                        return <Box className={styles.day_number_text}>{arg.dayNumberText}</Box>;
                    }}
                    eventClassNames={() => styles.remove_border}
                    slotEventOverlap={false}
                />
            </Box>
        </Box>
    )
};

export default Calendar;