import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  Modal,
  OutlinedInput,
  Skeleton,
  TextField,
  Tooltip,
} from "@mui/material";
import "./CustomTable.css";
import React, { useEffect, useRef, useState } from "react";
import styles from "./CustomTable.module.css";
import { useDispatch, useSelector } from "react-redux";
import { getPathFromLocalStroage } from "../../utils";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import HistoryIcon from "@mui/icons-material/History";
import CloseIcon from "@mui/icons-material/Close";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SearchIcon from "@mui/icons-material/Search";

import moment from "moment";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import { toggleLoader } from "../../Store/loader/loaderReducer";
import axios from "axios";
import { toast } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ReviewsIcon from '@mui/icons-material/Reviews';



const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const tabs = {
  0: "Edit",
  1: "Edit",
  2: "Edit",
  3: "Edit",
  4: "Review",
  5: "Restore",
};
const tableHeader = [
  "Drafts",
  "Scheduled",
  "Published",
  "Failed",
  "Pending Review",
  "Trash",
];
const hideDateTabs = [2, 4, 5];
const draftHead = ["Title", "Keywords", "Scheduled_Time", "Content"];





const CustomTable = ({ tabvalue, ids, draft, edit, search, searchValue, changeTabValue, usersDetails, nextPage, cursor }) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [drafts, setDrafts] = useState({
    Title: "",
    Keywords: "",
    Scheduled_Time: "",
    ConteScheduled_Timent: "",
    Org_Id: "",
    Id: "",
  });



  const dispatch = useDispatch();
  const handleClose = () => setOpen(false);
  const location = useLocation();
  const navigate = useNavigate();


  const scrollRef = useRef(null);
  const inputRef = useRef(null);

  const org_info = useSelector((state) => state?.org_info?.value);
  const org_id = org_info?.default_organizations?.Id;

  const post_info_val = useSelector((state) => state.post_info?.value?.data);
  const loader = useSelector((state) => state?.loader?.isLoading);

  let parts = location.pathname.split('/');
  const pathLocal = getPathFromLocalStroage();
  let editor = parts.slice(3).join('/');

  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [nextval, setNextval] = useState(null);

  const redioBtn = [0, 1];
  const Platform = ['Wix', 'Centilio', 'Zoho', 'Facebook', 'Instagram', 'Twitter', 'WordPress'];

  useEffect(() => {
    setNextval(nextPage)
  }, [nextPage])

  const handleDraft = (item) => {

    let data = {
      title: item.Title,
      keywords: item.Keywords,
      scheduled_date: moment(item.Scheduled_Time, "DD/MM/YYYY hh:mm A").format("DD/MM/YYYY HH:mm"),
      content: item.Content,
      org_id: org_id,
      id: item.Id,
    };

    if (data.scheduled_date === 'Invalid date') {
      delete data.scheduled_date;
    }

    dispatch(toggleLoader(true));
    axios
      .post(process.env.REACT_APP_POST, data, {
        withCredentials: process.env.REACT_APP_CREDENTIALS,
      })
      .then((res) => {
        dispatch(toggleLoader(false));
        navigate(`/c${org_id}${pathLocal.path}`);
        changeTabValue(0)
        usersDetails()
      })
      .catch((err) => {
        toast.error(err.message);
        dispatch(toggleLoader(false));
      });
  };
  const handleSentForReview = (item) => {

    let data = {
      content: item.Content,
      org_id: org_id,
      post_id: item.Id,
    };

    // if (data.scheduled_date === 'Invalid date') {
    //   delete data.scheduled_date;
    // }

    dispatch(toggleLoader(true));
    axios
      .post(process.env.REACT_APP_SEND_FOR_REVIEW, data, {
        withCredentials: process.env.REACT_APP_CREDENTIALS,
      })
      .then((res) => {
        dispatch(toggleLoader(false));
        navigate(`/c${org_id}${pathLocal.path}`);
        changeTabValue(4);
        usersDetails()
      })
      .catch((err) => {
        // console.log('err', err.response.data.error.details);
        toast.error(err?.response?.data?.error?.details);
        dispatch(toggleLoader(false));
      });
  };

  const handleView = (item) => {
    let data = {
      org_id: org_id,
      post_id: item.Id,
    };

    dispatch(toggleLoader(true));
    axios
      .post(process.env.REACT_APP_VIEWPOST, data, {
        withCredentials: process.env.REACT_APP_CREDENTIALS,
      })
      .then((res) => {
        dispatch(toggleLoader(false));
      })
      .catch((err) => {
        toast.error(err.message);
        dispatch(toggleLoader(false));
      });
  }

  const getTooltipTitle = (item) => {
    return (
      <Box className={styles.table_moreinfo_popper}>
        {tabvalue === 2 && <Button
          variant="text"
          startIcon={<ContentCopyIcon />}
          onClick={() => handlePostView(item)}
        >
          View Post
        </Button>}
        {tabvalue === 2 && <Button
          variant="text"
          startIcon={<ContentCopyIcon />}
          onClick={() => handleRevertToDraft(item)}
        >
          Revert to Draft
        </Button>}
        <Button
          startIcon={<ContentCopyIcon />}
          variant="text"
          onClick={() => handleDraft(item)}
        >
          Duplicate draft
        </Button>
        {redioBtn.includes(tabvalue) && <Button
          startIcon={<ReviewsIcon />}
          // className={styles.table_trash_btns_delete}
          variant="text"
          onClick={() => handleSentForReview(item)}
        >
          Send for review
        </Button>}
        <Button
          startIcon={<DeleteOutlineIcon />}
          className={styles.table_trash_btns_delete}
          variant="text"
          onClick={() => ids([item], "Trash")}
        >
          Move to trash
        </Button>

      </Box>
    );
  };

  const isSelected = (item) => {
    return selectedItems.includes(item);
  };

  const handleCheckboxChange = (item) => {
    setSelectedItems((prevSelectedItems) => {
      if (prevSelectedItems.includes(item)) {
        return prevSelectedItems.filter((i) => i !== item);
      } else {
        return [...prevSelectedItems, item];
      }
    });
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  });

  useEffect(() => {
    if (post_info_val?.length > 0 && post_info_val?.length === selectedItems?.length) {
      setSelectAll(true);
    } else setSelectAll(false);
  }, [selectedItems]);

  useEffect(() => {
    setSelectedItems([]);
    setSelectAll(false);
  }, [tabvalue]);

  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedItems([]);
    } else setSelectedItems(post_info_val);
  };

  const handleTime = (e) => {
    if (e) {
      let val = dayjs(e.$d).format("DD-MMM-YYYY hh:mm");
      setDrafts(cur => ({ ...cur, Scheduled_Time: val }));
    }
  };

  const handleSaveDraft = () => {
    draft(drafts);
    handleClose();
    setSelectedItems([]);
  };

  const handleReject = () => {
    ids([drafts]);
    handleClose();
  };

  const handleTableButton = (item) => {
    if (tabvalue === 5) {
      ids([item], "Restore");
    } else edit(item);
  };

  const handleSearch = (e) => {
    search(e.target.value);
  };
  const handleRevertToDraft = (item) => {
    const id = item ? [item] : selectedItems;
    ids(id, "Revert")
    setSelectedItems([]);
  };


  const handlePostView = (item) => {
    let data = {
      org_id: org_id,
      post_id: item.Id,
    };

    dispatch(toggleLoader(true));
    axios
      .get(process.env.REACT_APP_VIEWPOST, {
        params: data,
        withCredentials: process.env.REACT_APP_CREDENTIALS,
      })
      .then((res) => {
        const URL = res.data.data.url
        window.open(URL, '_blank');
        dispatch(toggleLoader(false));
      })
      .catch((err) => {
        toast.error(err.message);
        dispatch(toggleLoader(false));
      });
  };

  const handleScroll = (e) => {
    if (nextval) {
      setScroll(true)
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
      if (scrollTop + clientHeight + 2 >= scrollHeight) {
        !loader && usersDetails('scroll');
        setScroll(false)
      }
    }

  }

  useEffect(() => {
    const divElement = scrollRef.current;
    if (divElement) {
      divElement.addEventListener('scroll', handleScroll);
      return () => divElement.removeEventListener('scroll', handleScroll);
    }
  }, []);


  const renderTabColumns = (item) => {

    switch (tabvalue) {
      case 0:
        return (
          <Box sx={{ display: "flex", gap: '5px' }}>
            <Box>{item.Created_Time}</Box>
            <Box sx={{ color: 'gray', padding: '2px', fontSize: '10px' }}>
              <FiberManualRecordIcon fontSize="none" /></Box>
            <Box>{Platform[item.Channel_Type - 1]}</Box>
            {item.Scheduled_Time && <Box sx={{ color: 'gray', padding: '2px', fontSize: '10px' }}>
              <FiberManualRecordIcon fontSize="none" /></Box>}
            {item.Scheduled_Time && <Box>Scheduled for {item.Scheduled_Time}</Box>}
          </Box>
        );
      case 1:
        return (
          <Box sx={{ display: "flex", gap: '5px' }}>
            <Box>{Platform[item.Channel_Type - 1]}</Box>
            {item.Scheduled_Time && <Box sx={{ color: 'gray', padding: '2px', fontSize: '10px' }}>
              <FiberManualRecordIcon fontSize="none" /></Box>}
            {item.Scheduled_Time && <Box>Scheduled for {item.Scheduled_Time}</Box>}
          </Box>
        );
      case 2:
        return (
          <Box sx={{ display: "flex", gap: '5px' }}>
            <Box>{item.Created_Time}</Box>
            <Box sx={{ color: 'gray', padding: '2px', fontSize: '10px' }}>
              <FiberManualRecordIcon fontSize="none" /></Box>
            <Box>{Platform[item.Channel_Type - 1]}</Box>
          </Box>
        );
      case 3:
        return (
          <Box sx={{ display: "flex", gap: '5px' }}>
            <Box>{item.Created_Time}</Box>
            <Box sx={{ color: 'gray', padding: '2px', fontSize: '10px' }}>
              <FiberManualRecordIcon fontSize="none" /></Box>
            <Box>{Platform[item.Channel_Type - 1]}</Box>
            <Box sx={{ color: 'gray', padding: '2px', fontSize: '10px' }}>
              <FiberManualRecordIcon fontSize="none" /></Box>
            <Box > <Tooltip title={item.Info}><Box maxWidth={'350px'} color={"red"} textOverflow={"ellipsis"} whiteSpace={"nowrap"} overflow={"hidden"}
            >{item.Info}</Box></Tooltip></Box>
          </Box>
        );
      case 4:
        return (
          <Box sx={{ display: "flex", gap: '5px' }}>
            <Box>{item.Created_Time}</Box>
            <Box sx={{ color: 'gray', padding: '2px', fontSize: '10px' }}>
              <FiberManualRecordIcon fontSize="none" /></Box>
            <Box>{Platform[item.Channel_Type - 1]}</Box>
            {item.Scheduled_Time && <Box sx={{ color: 'gray', padding: '2px', fontSize: '10px' }}>
              <FiberManualRecordIcon fontSize="none" /></Box>}
            {item.Scheduled_Time && <Box>Scheduled for {item.Scheduled_Time}</Box>}
          </Box>
        );
      case 5:
        return (
          <Box sx={{ display: "flex", gap: '5px' }}>
            <Box>{item.Created_Time}</Box>
            <Box sx={{ color: 'gray', padding: '2px', fontSize: '10px' }}>
              <FiberManualRecordIcon fontSize="none" /></Box>
            <Box>{Platform[item.Channel_Type - 1]}</Box>
          </Box>
        );
      default:
        return (
          <Box>
            <Box>{item.Created_Time}</Box>
            <Box>{Platform[item.Channel_Type - 1]}</Box>
          </Box>
        );
    }

  }

  // console.log('post_info_val',post_info_val);


  return (
    <Box sx={{ padding: "2rem" }}>
      <Box className={styles.table_border_top}>
        <Box className={styles.table_border}>
          <Box>
            <FormGroup>
              <FormControlLabel
                className={styles.table_Select_box}
                control={
                  <Checkbox
                    checked={selectAll}
                    indeterminate={
                      selectedItems?.length !== 0 &&
                      selectedItems?.length !== post_info_val?.length
                    }
                    onChange={handleSelectAllChange}
                  />
                }
                label={
                  selectedItems?.length
                    ? `${selectedItems?.length} Selected`
                    : "Select all"
                }
                disabled={!post_info_val?.length}
              />
            </FormGroup>
          </Box>
          <Box className={styles.table_filter}>
            {selectedItems?.length ? (
              <Box className={styles.table_trash_btns}>
                {tabvalue === 2 && (
                  <Box>
                    <Button
                      startIcon={<HistoryIcon fontSize="small" />}
                      onClick={() => handleRevertToDraft()}
                      variant="text"
                    >
                      Revert to Draft
                    </Button>
                  </Box>
                )}
                <Box>
                  <Button
                    className={styles.table_trash_btns_delete}
                    startIcon={<DeleteOutlineIcon fontSize="small" />}
                    variant="text"
                    onClick={() => { ids(selectedItems, tabvalue === 5 ? "Delete" : "Trash"); setSelectedItems([]) }
                    }
                  >
                    {tabvalue === 5 ? "Delete" : "Move to Trash"}
                  </Button>
                </Box>
              </Box>
            ) : (
              <Box>
                <TextField
                  className="input_area_mui"
                  ref={inputRef}
                  sx={{
                    borderRadius: '30px',
                    height: '2rem',
                  }}
                  onChange={handleSearch}
                  placeholder="Search..."
                  value={searchValue}
                  id="myInput"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            )}
          </Box>
        </Box>
        {(loader && cursor === 1) ? (
          <div className={`${styles.table_body_data} ${styles.table_body_error_head}`} >
            <Box>
              <Box className={styles.table_body_error}>

              </Box>
            </Box>
          </div>
        ) : post_info_val?.length ? (
          <Box className={styles.table_body_data}
            onScroll={handleScroll}
            ref={scrollRef}
          >
            {post_info_val?.map((item, idx) => (
              <Box
                className={`${styles.table_user_title} ${isSelected(item) && styles.table_user_left_border
                  }`}
                key={idx}
              >
                <Box className={styles.table_user_title_border}>
                  <Box sx={{ width: '100%' }} className={styles.table_user_details}>
                    <Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isSelected(item)}
                            onChange={() => handleCheckboxChange(item)}
                          />
                        }
                      />
                    </Box>
                    <Box>
                      <img
                        alt="user_img"
                        style={{
                          padding: "1px 20px",
                          background: "#daeffe",
                          border: "12px solid #daeffe",
                          borderRadius: " 9px",
                        }}
                        src={
                          process.env.REACT_APP_IMAGE_URL +
                          "PostSvg/user_img.svg"
                        }
                      />
                    </Box>
                    <Box sx={{ width: '100%' }}>
                      <Box className={styles.table_title_name}>
                        <Tooltip title={item.Title}> {item.Title}</Tooltip>
                      </Box>
                      <Box sx={{ display: 'flex' }}>
                        <Box className={styles.table_title_phara}>
                          {renderTabColumns(item)}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box className={styles.table_btn_div}>

                    {tabvalue === 5 ? <Button
                      variant="contained"
                      className={styles.table_btn_edit}
                      disabled={item.Post_Processing_Status === 1}
                      onClick={() => handleTableButton(item)}
                    >
                      {tabs[tabvalue] ?? "Edit"}
                    </Button> :
                      <Button component={Link}
                        className={styles.table_btn_edit} // Use Link as the component for Button
                        to={`/c${org_id}/post/${editor}/${item.Id}`}// Set the path you want to navigate to
                        variant="contained"
                        disabled={item.Post_Processing_Status === 1}>
                        {tabs[tabvalue] ?? "Edit"}
                      </Button>
                    }

                    <Box>
                    </Box>

                    {item.Post_Processing_Status !== 1 ? <Box className={styles.table_user_more_icon}>
                      {tabvalue !== 5 ? (
                        <Tooltip
                          title={getTooltipTitle(item)}
                          componentsProps={{
                            tooltip: { sx: { bgcolor: "white" } },
                          }}
                          placement="left"
                          arrow
                        >
                          <MoreHorizIcon
                            className={styles.table_user_more_btn}
                            sx={{
                              border: "1px solid rgba(231, 234, 243, 0.70)",
                              borderRadius: "50%",
                              fill: "#377DFF",
                            }}
                          />
                        </Tooltip>
                      ) : (
                        <DeleteOutlineIcon
                          onClick={() => ids([item], "Delete")}
                          className={styles.table_user_delete_btn}
                          sx={{
                            border: "1px solid rgba(231, 234, 243, 0.70)",
                            borderRadius: "50%",
                            fill: "#377DFF",
                          }}
                        />
                      )}
                    </Box> : <CircularProgress size="1.4rem" />}
                  </Box>
                </Box>
              </Box>
            ))}
            {scroll && <Box sx={{ display: 'flex', textAlign: 'center', justifyContent: 'center', gap: "10px", padding: '10px' }}>
              {[...Array(3)].map((_, index) => (
                <Skeleton key={index} variant="circular" width={10} height={10} />
              ))}
            </Box>}
          </Box>
        ) : (
          <Box
            className={`${styles.table_body_data} ${styles.table_body_error_head}`}
          >
            <Box>
              <Box className={styles.table_body_error}>
                <img
                  alt="user_img"
                  style={{ width: "10%" }}
                  src={
                    process.env.REACT_APP_IMAGE_URL + "ErrorImg/errorimg.png"
                  }
                />
              </Box>
            </Box>
            <Box className={styles.table_body_error_text}>
              No {tableHeader[tabvalue]} Posts
            </Box>
          </Box>
        )}
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {draftHead?.map((item) => (
            <Box key={item} className={styles.modal_popup}>

              <Box className={styles.modal_popup_title}>{item}</Box>
              <FormControl size="small" fullWidth>
                {item !== "Scheduled_Time" ? (
                  <OutlinedInput
                    fullWidth
                    value={
                      drafts && item === "Scheduled_Time"
                        ? dayjs(moment(new Date(drafts[item])))
                        : drafts[item]
                    }
                    onChange={(e) =>
                      setDrafts(cur => ({ ...cur, [item]: e.target.value }))
                    }
                    className={styles.modal_popup_input}
                    placeholder={`Enter ${item} `}
                  />
                ) : (
                  // <LocalizationProvider dateAdapter={AdapterDayjs} slotProps={{ textField: { fullWidth: true } }}>
                  //     <DemoContainer
                  //         components={['DateTimePicker']}
                  //         sx={{
                  //             fieldset: { border: "1px solid #E6E7E9" },
                  //             '& .MuiOutlinedInput-root': {
                  //                 '&:hover fieldset': {
                  //                     borderColor: 'var(--primary) !important',
                  //                 },
                  //                 '& fieldset.MuiOutlinedInput-notchedOutline': {
                  //                     border: "1px solid #E6E7E9"
                  //                 },
                  //                 fontSize: '14px',
                  //                 paddingLeft: '2px',
                  //                 height: '41.92px'
                  //             },
                  //             paddingTop: '0px !important'
                  //         }}
                  //     >
                  //         <DemoItem>
                  //             <DateTimePicker
                  //                 format='DD-MMM-YYYY h:mm'
                  //                 value={dayjs(moment(new Date(drafts[item])))}
                  //                 viewRenderers={{ hours: renderTimeViewClock, minutes: renderTimeViewClock, seconds: renderTimeViewClock }}
                  //                 minDate={dayjs(new Date())}
                  //                 onChange={handleTime}
                  //                 sx={{ width: "100% !important", overflow: "hidden !important" }}
                  //             />
                  //         </DemoItem>
                  //     </DemoContainer>
                  // </LocalizationProvider>
                  <Box>
                    <Box className={styles.date_picker_wrap}>
                      <DatePicker
                        disabled={edit && tabvalue === 4}
                        selected={
                          drafts?.Scheduled_Time === "Invalid Date"
                            ? ""
                            : drafts?.Scheduled_Time
                        }
                        className={styles.date_picker}
                        onChange={handleTime}
                        minDate={new Date()}
                        timeInputLabel="Time:"
                        dateFormat="d-MMMM-yyy h:mm aa"
                        showTimeInput
                        wrapperClassName={styles.date_pickerr}
                        error={true}
                      />
                      <Box className={styles.clear_icon_wrap}>
                        {drafts?.Scheduled_Time && (
                          <CloseIcon
                            onClick={() =>
                              edit && tabvalue === 4
                                ? {}
                                : setDrafts({ ...drafts, Scheduled_Time: "" })
                            }
                            fontSize="small"
                            sx={{
                              display:
                                drafts?.Scheduled_Time === "Invalid Date" &&
                                "none",
                              "&:hover": {
                                color: "#ed4c78",
                                cursor:
                                  edit && tabvalue === 4
                                    ? "default"
                                    : "pointer",
                              },
                            }}
                          />
                        )}
                      </Box>
                    </Box>
                  </Box>
                )}
              </FormControl>
            </Box>
          ))}
          <Box
            className={styles.modal_popup_draft_btn}
            sx={{
              textAlign: "end",
              padding: "20px",
            }}
          >
            {tabvalue === 4 && (
              <Button
                variant="outlined"
                sx={{ colors: "red" }}
                onClick={() => handleReject()}
              >
                Reject
              </Button>
            )}
            <Button variant="contained" onClick={() => handleSaveDraft()}>
              Save as Draft
            </Button>
          </Box>
        </Box>
      </Modal>

    </Box>
  );
};

export default CustomTable;