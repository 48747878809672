import { Autocomplete, Box, Button, FormControl, FormControlLabel, FormLabel, InputLabel, Link, MenuItem, Radio, RadioGroup, Select, Skeleton, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from './Settings.module.css';
import { useDispatch, useSelector } from "react-redux";
import { toggleLoader } from "../../Store/loader/loaderReducer";
import axios from "axios";
import { toast } from "react-toastify";
import moment from 'moment-timezone';
import TimeZones from "../../Utils/timeZone";
import { OrgNavigation } from "../../Utils/handleOrgNavigation";

const blog_label = { 1: { label: 'Wix API' }, 7: { label: 'Wordpress API' }, 9: { label: 'Wix' }, 10: { label: 'WordPress' } };
const Settings = () => {
    const [apiKey, setApiKey] = useState({ timezone: moment.tz.guess(), company_name: "", company_description: '' });
    const [editApiKey, setEditApiKey] = useState(null);
    const [pageShow, setPageShow] = useState(false);
    const [toggle, setToggle] = useState([]);

    const dispatch = useDispatch();
    const navPath = OrgNavigation();

    const org_info = useSelector((state) => state?.org_info?.value);
    const json_Features = JSON.parse(org_info.show_features);
    const FeaturesAllTypes = json_Features[0].features
    const features = FeaturesAllTypes.CENTILIO_INTEGRATION_SETTING === undefined ? true : FeaturesAllTypes.CENTILIO_INTEGRATION_SETTING;

    useEffect(() => {
        fetchSettings();
        if (org_info['blog platform']?.length) setToggle(org_info['blog platform']);
    }, []);

    const timeZoneOptions = Object.entries(TimeZones).map(([key, value]) => ({
        key,
        ...value,
    }));

    const fetchSettings = () => {
        axios.get(process.env.REACT_APP_SETTINGS, { withCredentials: process.env.REACT_APP_CREDENTIALS, params: { org_id: org_info?.default_organizations?.Id } })
            .then((res) => {
                dispatch(toggleLoader(false));
                setPageShow(true);
                let data = res.data.setting;
                let obj = { ...apiKey };
                if (data) {
                    obj['api_key'] = data?.Api_Key;
                    obj['member_id'] = data?.Member_Id;
                    obj['site_id'] = data?.Site_Id;
                    obj['channel_type'] = data?.Channel_Type;
                    obj['company_name'] = data?.Company_Name;
                    obj['company_description'] = data?.Company_Description;
                    if (data?.timezone) {
                        const getTimeZone = timeZoneOptions.find((zone) => zone.key === data?.timezone);
                        obj['timezone'] = getTimeZone;
                    }
                    setApiKey(obj);
                    setEditApiKey(obj);
                };
            })
            .catch((err) => {
                toast.error(err.message);
                dispatch(toggleLoader(false));
            });
    };


    const getEditInputField = (values) => {
        let old_obj = Object.keys(editApiKey)?.find(ele => values[ele] !== editApiKey[ele]);
        return old_obj;
    };

    const channelType = (e) => {
        axios.put(process.env.REACT_APP_SETTINGS, { channel_type: e, org_id: org_info?.default_organizations?.Id }, { withCredentials: process.env.REACT_APP_CREDENTIALS })
            .then((res) => {
                dispatch(toggleLoader(false));
            })
            .catch((err) => {
                dispatch(toggleLoader(false));
                toast.error(err.message);
            });
    }

    const handlePost = () => {
        let params = { org_id: org_info?.default_organizations?.Id, company_name: apiKey?.company_name, timezone: apiKey?.timezone?.key };

        if (!apiKey?.timezone) {
            toast.warning('timezone required');
            return;
        }

        let edit_value = getEditInputField(apiKey);
        if (!edit_value) {
            toast.warning('No changes');
            return;
        };

        let update_value = {};
        Object.entries(editApiKey).forEach(([key, value]) => {
            if (value !== apiKey[key]) {
                if (key === 'timezone') update_value[key] = apiKey[key]?.key;
                else update_value[key] = apiKey[key];
            };
        });

        dispatch(toggleLoader(true));
        if (update_value && Object.keys(update_value)?.length) {
            axios.put(process.env.REACT_APP_SETTINGS, { ...update_value, org_id: org_info?.default_organizations?.Id }, { withCredentials: process.env.REACT_APP_CREDENTIALS })
                .then((res) => {
                    dispatch(toggleLoader(false));
                    toast.success('Success');
                })
                .catch((err) => {
                    dispatch(toggleLoader(false));
                    toast.error(err.message);
                });
        } else {
            axios.post(process.env.REACT_APP_SETTINGS, params, { withCredentials: process.env.REACT_APP_CREDENTIALS })
                .then((res) => {
                    dispatch(toggleLoader(false));
                    toast.success('Success');
                })
                .catch((err) => {
                    dispatch(toggleLoader(false));
                    if (err?.response?.data?.error?.message === 'duplicate_record') toast.error(err?.response?.data?.error?.details);
                    else toast.error(err.message);
                });
        }
    };

    const handleNavigate = () => {
        navPath('/Integration');
    };

    return (
        <>
            {pageShow ?
                <Box>
                    <Box className={styles.header_title}>
                        <Box className={styles.title}>
                            <Box className={styles.header_text}>Settings</Box>
                        </Box>
                    </Box>
                    <Box className={styles.top_bar} >
                        <Box className={styles.main_wrap}>
                            <Box className={styles.settings_body}>
                                <Box className={styles.wix_setting}>Post Setting</Box>
                                <Box className={styles.wix_list} sx={{ padding: '20px' }}>
                                    <FormControl sx={{ display: 'flex', flexDirection: "row" }}>
                                        <FormLabel id="demo-radio-buttons-group-label" className={styles.blog_label}>Blog Platform</FormLabel>
                                        {org_info['blog platform']?.length ?
                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                value={apiKey?.channel_type}
                                                name="radio-buttons-group"
                                                sx={{ display: "flex", flexDirection: "row" }}
                                                onChange={(e) => setApiKey({ ...apiKey, channel_type: Number(e.target.value) })}
                                            >
                                                {toggle?.map((item, i) => {
                                                    return (
                                                        ((features && item?.channel_type === 2) || item?.channel_type !== 2) && <FormControlLabel key={i} value={item?.Channel_Type} onClick={(e) => channelType(item?.Channel_Type)}
                                                            sx={{
                                                                "& .MuiFormControlLabel-label": {
                                                                    fontSize: "14px",
                                                                    fontFamily: "var(--primary-font-family)",
                                                                    color: 'var(--table-font-color)'
                                                                },
                                                            }}
                                                            control={<Radio sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }} />} label={item?.Channel_Type === 2 ? item?.App_Name : blog_label[item?.Channel_Type]?.label} />
                                                    )
                                                })}
                                            </RadioGroup> :
                                            <Box className={styles.not_connected_text}>No platform connected. Go to <Typography onClick={handleNavigate} sx={{ cursor: 'pointer', textDecoration: 'underline', color: 'var(--primary)', margin: '0px 5px', fontSize: '14px' }} >Integration tab</Typography> to connect to your platform</Box>}
                                    </FormControl>
                                    <Box className={styles.file_post_container}>
                                        <InputLabel className={styles.post_label}>Company Name</InputLabel>
                                        <TextField
                                            multiline
                                            minRows={1}
                                            className={styles.file_post_input}
                                            onChange={(e) => setApiKey({ ...apiKey, company_name: e.target.value })}
                                            sx={{
                                                fieldset: { border: "1px solid #E6E7E9" },
                                                '& .MuiOutlinedInput-root': {
                                                    '&:hover fieldset': {
                                                        borderColor: 'var(--primary) !important',
                                                    },
                                                    '& fieldset.MuiOutlinedInput-notchedOutline': {
                                                        border: "1px solid #E6E7E9"
                                                    },
                                                    fontSize: '14px',
                                                    // paddingLeft: '2px',
                                                },
                                            }}
                                            value={apiKey?.company_name}
                                            placeholder='Enter Company Name'
                                        />
                                    </Box>
                                    <Box className={styles.file_post_container}>
                                        <InputLabel className={styles.post_label}>Company Description </InputLabel>
                                        <TextField
                                            multiline
                                            minRows={3}
                                            maxRows={10}
                                            className={styles.file_post_input}
                                            onChange={(e) => setApiKey({ ...apiKey, company_description: e.target.value })}
                                            sx={{
                                                fieldset: { border: "1px solid #E6E7E9" },
                                                '& .MuiOutlinedInput-root': {
                                                    '&:hover fieldset': {
                                                        borderColor: 'var(--primary) !important',
                                                    },
                                                    '& fieldset.MuiOutlinedInput-notchedOutline': {
                                                        border: "1px solid #E6E7E9"
                                                    },
                                                    fontSize: '14px',
                                                    // paddingLeft: '2px',
                                                },
                                            }}
                                            value={apiKey?.company_description}
                                            placeholder='Company Description'
                                        />
                                    </Box>

                                    <Box className={styles.file_post_container}>
                                        <InputLabel className={styles.post_label}>Time Zone</InputLabel>
                                        <Autocomplete
                                            sx={{
                                                fieldset: { border: "1px solid #E6E7E9" },
                                                '&:hover': {
                                                    '&& fieldset': {
                                                        border: '1px solid var(--primary) !important',
                                                        outline: 0,
                                                    },
                                                },
                                                "&.Mui-focused .MuiOutlinedInput-notchedOutline": { border: '1px solid var(--primary) !important' },
                                                fontSize: '14px',
                                                "& .MuiInputBase-root": { height: "41.92px" },
                                                width: '85%',
                                            }}
                                            options={timeZoneOptions}
                                            autoHighlight
                                            getOptionLabel={(option) => option?.display_text || ''}
                                            renderOption={(props, option) => {
                                                const { key, ...otherProps } = props;
                                                return (
                                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 }, fontSize: '14px', fontFamily: 'var(--primary-font-family)' }} key={key} {...otherProps}>
                                                        {option?.display_text}
                                                    </Box>
                                                )
                                            }}
                                            onChange={(event, newValue) => {
                                                setApiKey({ ...apiKey, timezone: newValue })
                                            }}
                                            isOptionEqualToValue={(option, value) => option.key === value.key}
                                            value={apiKey?.timezone}
                                            renderInput={(params) => (
                                                <TextField
                                                    sx={{ "& .MuiInputBase-root": { height: "41.92px", padding: '0px 0px 0px 10px !important', fontSize: '14px' } }}
                                                    {...params}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: 'new-password'
                                                    }}
                                                />
                                            )}
                                        />
                                    </Box>
                                    <Box className={styles.btn_container}>
                                        <Button variant='contained' className={styles.review_btn} onClick={handlePost}>Submit</Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>

                </Box> : <Skeleton sx={{ bgcolor: 'rgba(189,197,209,.2)', margin: '10px' }} animation="wave" variant="rounded" width={'calc(100% - 20px)'} height={'calc(100vh - 80px)'} />}
        </>
    );
};

export default Settings;