import { Box, Button, Checkbox, CircularProgress, FormControlLabel, FormGroup, InputAdornment, Skeleton, TextField, Tooltip } from '@mui/material'
import axios from 'axios';
import styles from "../CustomTable/CustomTable.module.css";
import thisstyles from "./CrawlLog.module.css";
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { toggleLoader } from '../../Store/loader/loaderReducer';
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from 'react-router-dom';
import { paaQuestionsAction } from '../../Store/PaaQuestions/paaQuestionsAction';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { getPathFromLocalStroage } from '../../utils';
import DeleteModal from '../Modal/DeleteModal/DeleteModal';
const CrawlLog = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const org_info = useSelector((state) => state?.org_info?.value);
    const org_id = org_info?.default_organizations?.Id;
    const loader = useSelector((state) => state?.loader?.isLoading);

    const scrollRef = useRef(null);

    const [selectedItems, setSelectedItems] = useState([]);

    //pagination
    const [cursor, setCursor] = useState(1);
    const [nextPage, setNextPage] = useState(true)
    const [crawlPage, setCrawlPage] = useState([]);
    const [filteredData, SetFilteredData] = useState([]);
    const [selectedIndexes, setSelectedIndexes] = useState([]);
    const [selectedValue, setSelectedValue] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [ids, setIds] = useState([]);
    const [scroll, setScroll] = useState(false);
    const [open, setOpen] = useState(false);
    const pathLocal = getPathFromLocalStroage();

    useEffect(() => {
        crawlDetails();
    }, [])


    // const filteredData = crawlPage.filter(item =>
    //     item.Keywords.toLowerCase().includes(searchTerm.toLowerCase())
    // );

    const displayData = searchTerm ? filteredData : crawlPage;

    // console.log('displayData',displayData,'crawlPage',crawlPage,'ids',ids);

    const crawlDetails = () => {

        let data = {
            limit: 200,
            cursor: cursor,
            org_id: org_info?.default_organizations?.Id,
        };

        dispatch(toggleLoader(true));
        axios
            .get(process.env.REACT_APP_CRAWL_LOG, {
                params: data,
                withCredentials: process.env.REACT_APP_CREDENTIALS,
            })
            .then((res) => {
                setCrawlPage(prevCrawlPage => [...prevCrawlPage, ...res?.data?.data]);
                dispatch(toggleLoader(false));
                setNextPage(res?.data?.next_page)
                setCursor(res?.data?.next_cursor)
            })
            .catch((err) => {
                toast.error(err.message);
                dispatch(toggleLoader(false));
            });
    };
    const crawlDetailsFilter = () => {

        let data = {
            limit: 200,
            cursor: 1,
            org_id: org_info?.default_organizations?.Id,
            search: searchTerm,
        };

        dispatch(toggleLoader(true));
        axios
            .get(process.env.REACT_APP_CRAWL_LOG, {
                params: data,
                withCredentials: process.env.REACT_APP_CREDENTIALS,
            })
            .then((res) => {
                SetFilteredData(res.data.data);
                dispatch(toggleLoader(false));
                setNextPage(res?.data?.next_page)
                setCursor(res?.data?.next_cursor)
            })
            .catch((err) => {
                toast.error(err.message);
                dispatch(toggleLoader(false));
            });
    };

    const crawlQuestions = (id) => {

        let data = {
            limit: 50,
            cursor: 1,
            org_id: org_info?.default_organizations?.Id,
            id: id
        };

        dispatch(toggleLoader(true));
        axios
            .get(process.env.REACT_APP_CRAWL_LOG, {
                params: data,
                withCredentials: process.env.REACT_APP_CREDENTIALS,
            })
            .then((res) => {
                dispatch(paaQuestionsAction(res?.data));
                dispatch(toggleLoader(false));
                navigate(`/c${org_id}/related-questions`, { state: { type: 'crawl' } })
            })
            .catch((err) => {
                toast.error(err.message);
                dispatch(toggleLoader(false));
            });
    };

    const handleDelete = (id) => {


        const data = {
            org_id: org_info?.default_organizations?.Id,
            ids: id ? id : ids,
        }
        axios
            .delete(process.env.REACT_APP_CRAWL_LOG, {
                data: data,
                withCredentials: process.env.REACT_APP_CREDENTIALS,
            })
            .then((res) => {
                dispatch(toggleLoader(false));
                toast.success('Success');
                setCrawlPage([]);
                setSelectedIndexes([]);
                crawlDetails();
                setOpen(false);
            })
            .catch((err) => {
                toast.error(err.message);
                dispatch(toggleLoader(false));
            });
    }

    const handleCheckboxChange = (index) => {
        if (selectedIndexes.includes(index)) {
            setSelectedIndexes(selectedIndexes.filter(i => i !== index));
        } else {
            setSelectedIndexes([...selectedIndexes, index]);
        }
    };

    // Handle selecting/deselecting all checkboxes
    const handleSelectAllChange = (event) => {
        if (event.target.checked) {
            const allIndexes = displayData?.map((_, index) => index);
            setSelectedIndexes(allIndexes);
        } else {
            setSelectedIndexes([]);
        }
    };

    const handleScroll = (e) => {
        if (nextPage) {
            setScroll(true)
            const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
            if (scrollTop + clientHeight + 2 >= scrollHeight) {
                !loader && searchTerm ? crawlDetailsFilter() : crawlDetails();
                setScroll(false)
            }
        }
    }
    const handleBack = () => {
        navigate(`c${org_id}/${pathLocal.path}`)
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            crawlDetailsFilter();
        }
    };

    useEffect(() => {
        const selectedValue = selectedIndexes.map(index => displayData[index]);
        setSelectedValue(selectedValue);
        const id = selectedValue.map(item => item.Id);
        setIds(id)
    }, [selectedIndexes]);

    const isAllSelected = displayData?.length === selectedIndexes?.length;


    return (
        <Box sx={{ padding: "2rem" }}>
            <Box className={thisstyles.table_filter_head}>Recent Searches</Box>
            <Box className={styles.table_filter} sx={{ padding: "10px 1px", justifyContent: 'space-between' }}>
                <Box>
                    <Button variant="outlined" startIcon={<ArrowBackIosIcon />} onClick={() => handleBack()}>Back</Button>
                </Box>

                <Box className={thisstyles.table_filter_delete_search}>
                    <Box>
                        <Button color="error" variant="outlined" onClick={() => setOpen(true)} disabled={!selectedValue.length} startIcon={<DeleteIcon />}>
                            Delete
                        </Button>
                    </Box>
                    <Box>
                        <TextField
                            className="input_area_mui"
                            // ref={inputRef}
                            sx={{
                                borderRadius: '30px',
                                height: '2rem',
                            }}
                            placeholder="Search..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            onKeyDown={handleKeyDown}
                            id="myInput"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                </Box>
            </Box>
            <DeleteModal open={open} btn_text={'Delete'} closeModal={() => setOpen(false)} handleDelete={() => handleDelete()} />
            <Box className={styles.table_border_top}>
                <Box className={styles.table_border}>
                    <Box sx={
                        { width: "100%" }
                    }>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }} >
                            <Box className={styles.table_user_details}>
                                <FormGroup>
                                    <FormControlLabel
                                        className={styles.table_Select_box}
                                        control={
                                            <Checkbox
                                                checked={isAllSelected}
                                                onChange={handleSelectAllChange}
                                            />
                                        }
                                        // label={
                                        //     selectedIndexes?.length
                                        //         ? `${selectedIndexes?.length} Selected`
                                        //         : "Select all"
                                        // }
                                        disabled={!displayData?.length}
                                    />
                                </FormGroup>
                                <Box sx={{ width: '100%' }}>
                                    <Box className={styles.table_title_name} >
                                        Keywords
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}> </Box>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}> Date</Box>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                width: "10%",
                                alignItems: 'center'
                            }}></Box>
                        </Box>
                    </Box>
                </Box>

                <Box className={styles.table_body_data} onScroll={handleScroll}
                    ref={scrollRef}>
                    {displayData?.map((item, idx) => (
                        <Box
                            className={`${styles.table_user_title} `}
                            // ${isSelected(item) && styles.table_user_left_border}'

                            key={idx}
                        >
                            <Box className={styles.table_user_title_border} sx={{ padding: "10px 30px" }}>
                                <Box className={styles.table_user_details}>
                                    <Box>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={selectedIndexes.includes(idx)}
                                                    onChange={() => handleCheckboxChange(idx)}
                                                />
                                            }
                                        />
                                    </Box>
                                    <Box sx={{ width: '100%' }}>
                                        <Box className={styles.table_title_name} sx={{ cursor: 'pointer' }} onClick={() => crawlQuestions(item?.Id)}  >
                                            {item?.Keywords}
                                        </Box>
                                    </Box>
                                </Box>
                                <Tooltip
                                    title={item?.url}
                                    componentsProps={{ tooltip: { sx: { bgcolor: "#3a3a3a" } } }}
                                    placement="top"
                                >
                                    <Box className={styles.table_btn_div} style={{ width: '35%', whiteSpace: 'nowrap' }}>
                                        <Box className={styles.table_user_more_icon}
                                            component="a"

                                            href={item?.url}
                                            target="_blank"
                                            sx={(theme) => ({
                                                textDecoration: 'none',
                                                textOverflow: 'ellipsis',
                                                overflow: 'hidden',
                                                // my: 2,
                                                p: 1,
                                                fontSize: '0.875rem',
                                                fontWeight: '700',
                                                ...theme.applyStyles('dark', {
                                                    bgcolor: '#101010',
                                                    color: 'grey.300',
                                                    borderColor: 'grey.800',
                                                }),
                                            })} >
                                            {item?.Title}
                                        </Box>
                                    </Box>
                                </Tooltip>
                                <Tooltip
                                    title={item?.url}
                                    componentsProps={{ tooltip: { sx: { bgcolor: "#3a3a3a" } } }}
                                    placement="top"
                                >
                                    <Box className={styles.table_btn_div} style={{ width: '35%', whiteSpace: 'nowrap' }}>
                                        <Box className={styles.table_user_more_icon}
                                            component="a"

                                            href={item?.url}
                                            target="_blank"
                                            sx={(theme) => ({
                                                textDecoration: 'none',
                                                textOverflow: 'ellipsis',
                                                overflow: 'hidden',
                                                // my: 2,
                                                p: 1,
                                                fontSize: '0.875rem',
                                                // fontWeight: '700',
                                                ...theme.applyStyles('dark', {
                                                    bgcolor: '#101010',
                                                    color: 'grey.300',
                                                    borderColor: 'grey.800',
                                                }),
                                            })} >
                                            {item?.Created_Time}
                                        </Box>
                                    </Box>

                                </Tooltip>
                                {item?.Processing_Status === 2 ?
                                    // <Box className={thisstyles.delete_icon} onClick={() => handleDelete([item.Id])}>
                                    //     <DeleteIcon fontSize="small" />
                                    // </Box>
                                    <></>
                                    :
                                    <Box display={'flex'} alignItems={"center"}>
                                        <CircularProgress size="1.4rem" />
                                    </Box>}
                            </Box>

                        </Box>
                    ))}
                    {scroll && <Box sx={{ display: 'flex', textAlign: 'center', justifyContent: 'center', gap: "10px", padding: '10px' }}>
                        {[...Array(3)].map((_, index) => (
                            <Skeleton key={index} variant="circular" width={10} height={10} />
                        ))}
                    </Box>}
                </Box>

            </Box>
        </Box >
    )
}

export default CrawlLog;