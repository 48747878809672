import { Box, Button, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { getChannelConnect, getWixConnect } from '../../Api/UploadChannel';
import { useDispatch, useSelector } from "react-redux";
import { toggleLoader } from '../../Store/loader/loaderReducer';
import { toast } from 'react-toastify';
import styles from './Integration.module.css';
import axios from 'axios';
import IntegrationModal from '../../Component/Modal/IntegrationModal/IntegrationModal';

const integration = [
    { name: 'Wix', channel: 1, describe: 'Upload documents directly from WIX to here.', img: 'Integrations/wix.svg' },
    { name: 'Zoho CRM', channel: 3, describe: 'Upload documents directly from Zoho CRM to here.', img: 'Integrations/zoho.svg' },
    { name: 'WordPress', channel: 7, describe: 'Publish blogs on your WordPress site.', img: 'Integrations/wordpress.svg' },
    { name: 'Custom Site', channel: 2, describe: 'Publish blogs on your Custom Server site.', img: 'Integrations/custom_site.png' }
];

const initialState = { app_name: '', base_domain: '', blog_path: '', target_location: '', username: '', password: '' };
const wixInitialState = { api_key: '', member_id: '', site_id: '' };

const settings = [1, 2, 7];

const keyErrorText = {
    api_key: "Api Key",
    member_id: "Member ID",
    site_id: "Site ID",
    app_name: 'Application Name',
    base_domain: 'Base Domain',
    blog_path: 'Blog Path',
    target_location: 'Server Location Path',
    username: 'Username',
    password: 'Password'
};

const Integration = () => {
    const [modal, setModal] = useState(false);
    const [apiKey, setApiKey] = useState(wixInitialState);
    const [formDetails, setFormDetails] = useState(initialState);
    const [connected, setConnected] = useState(false);
    const [channel, setChannel] = useState([]);
    const [channelDetails, setChannelDetails] = useState([]);
    const [channelId, setChannelId] = useState({});
    const [update, setUpdate] = useState(false);
    const [disButton, setDisButton] = useState(null);
    const [move, setMove] = useState(null);

    const org_info = useSelector((state) => state?.org_info?.value);
    const org_id = org_info?.default_organizations?.Id;

    const dispatch = useDispatch();

    useEffect(() => {
        if (org_info?.integration_information?.length) handleCheckConnected();
    }, [org_info?.integration_information]);

    const handleCheckConnected = () => {
        let arr = [];
        let obj = {};
        let channel_id = {};
        org_info?.integration_information?.map((list, i) => {
            if (arr?.indexOf(list.Channel_Type) === -1) {
                obj = { ...obj, [list.Channel_Type]: true };
                channel_id = { ...channel_id, [list.Channel_Type]: list.Channel_Id ?? list.Account_Id };
                arr.push(list.Channel_Type);
            }
        });
        setChannel(arr);
        setConnected(obj);
        setChannelId(channel_id);
        setChannelDetails(org_info?.integration_information);
    };

    const handleConnect = (item) => {
        let options = { org_id: org_id, channel_type: item.channel };
        if (item.channel === 3) {
            getChannelConnect(options, (res) => {
                dispatch(toggleLoader(false));
                window.open(res.data.auth_url, "_blank");
                // console.log('childWin',childWin);
                // let userAgent = navigator.userAgent;
                // let winClosed = setInterval(function () {
                //     if (childWin.closed) {
                //         clearInterval(winClosed);
                //         handleChannelType(options, type, re_connect);
                //         setIs_closed(false);
                //     }
                //     else if (childWin.length === 0 && !(userAgent.indexOf("Firefox") !== -1)) {
                //         if (childWin.location.href.includes('callback')) {
                //             childWin.close()
                //         }
                //     } else if (childWin.length === 0 && userAgent.indexOf("Firefox") !== -1) {
                //     }
                // }, 250);
            }, (err) => {
                toast.error(err.message);
                dispatch(toggleLoader(false));
            });
        } else {
            switch (item.channel) {
                case 1:
                    setApiKey(wixInitialState);
                    setModal('wix');
                    break;
                case 2:
                    setFormDetails(initialState);
                    setModal('custom_site');
                    break;
                case 7:
                    setFormDetails(initialState);
                    setModal('word_press');
                    break;
                default:
                    break;
            }
        }
    };

    const handleOpenModal = (list) => {
        switch (list) {
            case 1:
                dispatch(toggleLoader(true));
                axios.get(process.env.REACT_APP_WIX, { withCredentials: process.env.REACT_APP_CREDENTIALS, params: { org_id: org_id } })
                    .then((res) => {
                        let data = res?.data?.data;
                        let obj = {
                            api_key: data?.Access_Token,
                            member_id: data?.Account_Id,
                            site_id: data?.Site_Id,
                        };
                        setChannelId((pre) => ({ ...pre, [list]: data.Id }));
                        setApiKey(obj);
                        setModal('wix');
                        setUpdate(true);
                        dispatch(toggleLoader(false));
                    })
                    .catch((err) => {
                        dispatch(toggleLoader(false));
                        toast.error(err.message);
                    });
                break;
            case 2:
                dispatch(toggleLoader(true));
                axios.get(process.env.REACT_APP_CUSTOM_SERVER, { withCredentials: process.env.REACT_APP_CREDENTIALS, params: { org_id: org_id } })
                    .then((res) => {
                        let data = res?.data?.data;
                        let obj = {
                            app_name: data?.App_Name,
                            base_domain: data?.Base_Domain,
                            blog_path: data?.Blog_Path,
                            target_location: data?.Target_Location,
                            username: data?.Username,
                            password: data?.Password
                        };
                        setChannelId((pre) => ({ ...pre, [list]: data.Id }));
                        setFormDetails(obj);
                        setModal('custom_site');
                        setUpdate(true);
                        dispatch(toggleLoader(false));
                    })
                    .catch((err) => {
                        dispatch(toggleLoader(false));
                        toast.error(err.message);
                    });
                break;
            case 7:
                dispatch(toggleLoader(true));
                axios.get(process.env.REACT_APP_WORD_PRESS, { withCredentials: process.env.REACT_APP_CREDENTIALS, params: { org_id: org_id } })
                    .then((res) => {
                        let data = res?.data?.data;
                        let obj = {
                            app_name: data?.App_Name,
                            base_domain: data?.Base_Domain,
                            blog_path: data?.Blog_Path,
                            target_location: '',
                            username: data?.Username,
                            password: data?.Password
                        };
                        setChannelId((pre) => ({ ...pre, [list]: data.Id }));
                        setFormDetails(obj);
                        setModal('word_press');
                        setUpdate(true);
                        dispatch(toggleLoader(false));
                    })
                    .catch((err) => {
                        dispatch(toggleLoader(false));
                        toast.error(err.message);
                    });
                break;
            default:
                break;
        }
    };

    const handleWixSubmit = () => {
        let params = {
            api_key: apiKey?.api_key,
            member_id: apiKey?.member_id,
            site_id: apiKey?.site_id,
        };

        const emptyFields = Object.keys(params).filter(
            (field) => typeof params[field] !== "string" || params[field].trim() === ""
        );

        params['org_id'] = org_id;
        params['id'] = channelId[1];
        if (!emptyFields?.length) {
            dispatch(toggleLoader(true));
            if (!update) {
                axios.post(process.env.REACT_APP_WIX, params, { withCredentials: process.env.REACT_APP_CREDENTIALS })
                    .then((res) => {
                        let data = res?.data?.data;
                        setChannel(pre => [...pre, data.Channel_Type]);
                        setConnected((prevState) => ({ ...prevState, [data.Channel_Type]: true }));
                        setChannelId((pre) => ({ ...pre, [data.Channel_Type]: data.Channel_Id }));
                        setModal(false);
                        setApiKey({});
                        setChannelDetails(pre => [...pre, data]);
                        dispatch(toggleLoader(false));
                        toast.success('Success');
                    })
                    .catch((err) => {
                        dispatch(toggleLoader(false));
                        let error = err?.response?.data?.error;
                        if (error?.message === 'invalid_data') {
                            toast.error(err.response.data.error.details);
                        } else {
                            toast.error(err.message);
                        }
                    });
            } else {
                axios.put(process.env.REACT_APP_WIX, params, { withCredentials: process.env.REACT_APP_CREDENTIALS })
                    .then((res) => {
                        setModal(false);
                        setUpdate(false);
                        setApiKey({});
                        dispatch(toggleLoader(false));
                        toast.success('Success');
                    })
                    .catch((err) => {
                        dispatch(toggleLoader(false));
                        let error = err?.response?.data?.error;
                        if (error?.message === 'channel_already_connected' || error?.message === 'invalid_data') {
                            toast.error(err.response.data.error.details);
                        } else {
                            toast.error(err.message);
                        }
                    });
            }
        } else {
            toast.error(`${keyErrorText[emptyFields[0]]} is required.`);
        }
    };

    const handleFormsSubmit = () => {
        let params = {
            app_name: formDetails?.app_name,
            base_domain: formDetails?.base_domain,
            blog_path: formDetails?.blog_path,
            username: formDetails?.username,
            password: formDetails?.password,
        };

        if (modal === 'custom_site') params['target_location'] = formDetails?.target_location;

        const emptyFields = Object.keys(params).filter(
            (field) => typeof params[field] !== "string" || params[field].trim() === ""
        );

        params['org_id'] = org_id;
        if (!emptyFields?.length) {
            dispatch(toggleLoader(true));
            if (!update) {
                axios.post(formDetails?.target_location ? process.env.REACT_APP_CUSTOM_SERVER : process.env.REACT_APP_WORD_PRESS, params, { withCredentials: process.env.REACT_APP_CREDENTIALS })
                    .then((res) => {
                        let data = res.data.data;
                        setChannel(pre => [...pre, data.Channel_Type]);
                        setConnected((prevState) => ({ ...prevState, [data.Channel_Type]: true }));
                        setChannelId((pre) => ({ ...pre, [data.Channel_Type]: data.Channel_Id }));
                        setModal(false);
                        setFormDetails({});
                        setChannelDetails(pre => [...pre, data]);
                        dispatch(toggleLoader(false));
                        toast.success('Success');
                    })
                    .catch((err) => {
                        dispatch(toggleLoader(false));
                        let error = err?.response?.data?.error;
                        if (error?.message === 'invalid_data') {
                            toast.error(err.response.data.error.details);
                        } else {
                            toast.error(err.message);
                        }
                    });
            } else {
                let type = modal === 'custom_site' ? 2 : 7;
                params['id'] = channelId[type];
                axios.put(formDetails?.target_location ? process.env.REACT_APP_CUSTOM_SERVER : process.env.REACT_APP_WORD_PRESS, params, { withCredentials: process.env.REACT_APP_CREDENTIALS })
                    .then((res) => {
                        setModal(false);
                        setUpdate(false);
                        setFormDetails({});
                        dispatch(toggleLoader(false));
                        toast.success('Success');
                    })
                    .catch((err) => {
                        dispatch(toggleLoader(false));
                        let error = err?.response?.data?.error;
                        if (error?.message === 'channel_already_connected' || error?.message === 'invalid_data') {
                            toast.error(err.response.data.error.details);
                        } else {
                            toast.error(err.message);
                        }
                    });
            }
        } else {
            toast.error(`${keyErrorText[emptyFields[0]]} is required.`);
        }
    };

    const handleDisconnect = (type) => {
        dispatch(toggleLoader(true));
        let data = { org_id: org_id, channel_type: type, channel_id: channelId[type] };
        axios.delete(process.env.REACT_APP_DISCONNECT, { withCredentials: process.env.REACT_APP_CREDENTIALS, data: data })
            .then((res) => {
                let obj = channel?.indexOf(type);
                let data = channel?.filter((k, i) => i !== obj);
                setChannel(data);
                setConnected({ ...connected, [type]: false });
                let remove_channel = { ...channelId };
                delete remove_channel[type];
                setChannelId(remove_channel);
                dispatch(toggleLoader(false));
            })
            .catch((err) => {
                toast.error(err.message);
                dispatch(toggleLoader(false));
            });
    };

    let expired = {};
    return (
        <Box>
            {integration?.map((item, index) => {
                let channel_connect = connected[item?.channel] && channel?.find((channel) => channel === item?.channel);
                channelDetails?.map((s, i) => channel_connect && s.Status === 2 ? expired[s.Channel_Type] = s.Channel_Type : null);
                return (
                    <Box className={styles.integration_body} key={index} onMouseOver={() => setDisButton(index)} onMouseOut={() => [setDisButton(null), setMove(null)]}>
                        <Box sx={{ '&:hover': { opacity: channel_connect && disButton === index ? '0.4' : '1.0' }, padding: '20px !important', position: 'relative', opacity: move === index ? '0.4' : '1.0' }}>
                            <Box className={styles.integration_box}>
                                <img
                                    src={process.env.REACT_APP_IMAGE_URL + item?.img}
                                    className={styles.dropbox_img}
                                    alt='dropbox'
                                />
                                <Box sx={{ display: 'flex' }}>
                                    <Button variant="outlined" sx={{ opacity: channel_connect && disButton === index && '0 !important' }} className={channel_connect ? styles.box_connected : styles.box_connect} style={{ cursor: channel_connect ? 'not-allowed' : 'pointer' }} onClick={() => !channel_connect && handleConnect(item)}>{channel_connect ? 'Connected' : 'Connect'}</Button>
                                </Box>
                            </Box>
                            <Box className={styles.integration_text}>
                                <Typography className={styles.top_text}>
                                    {item?.name}
                                </Typography>
                                <Typography className={styles.bottom_text}>
                                    {item?.describe}
                                </Typography>
                            </Box>
                        </Box>
                        {channel_connect && disButton === index &&
                            <Box className={styles.disconnect} onMouseOver={() => setMove(index)} sx={{ opacity: disButton === index && '1 !important', zIndex: '999 !important' }}>
                                {settings.includes(item?.channel) && channel_connect &&
                                    <Box className={styles.settings_img}>
                                        <img className={styles.nav_name} alt={item.name} src={process.env.REACT_APP_IMAGE_URL + 'sideBarSvg/Settings.svg'} onClick={() => handleOpenModal(item?.channel)} />
                                    </Box>}
                                {channel_connect && <Button variant="outlined" className={styles.disconnect_button} onClick={() => handleDisconnect(item?.channel)}>Disconnect</Button>}
                            </Box>}
                    </Box>)
            })}
            <IntegrationModal
                modal={modal}
                setModal={() => [setModal(false), update && setUpdate(false)]}
                apiKey={apiKey}
                formDetails={formDetails}
                handleChange={(value, key) => setApiKey({ ...apiKey, [key]: value })}
                handleFormDetails={(value, key) => setFormDetails({ ...formDetails, [key]: value })}
                handleWixSubmit={handleWixSubmit}
                update={update}
                handleFormsSubmit={handleFormsSubmit}
            />

        </Box>
    )
}

export default Integration;