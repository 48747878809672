import React, { useEffect, useState } from 'react'
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import * as Pages from './Pages/index';
import NavBar from './Component/NavBar/NavBar';
import TopBar from './Component/TopBar/TopBar';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import LinearProgress from '@mui/material/LinearProgress';
import './global.css';
import { getPathFromLocalStroage } from './utils';


const AppRoute = () => {

    const [isInitFetched, setIsInitFetched] = useState(false);
    const [toggle, setToggle] = useState(null);
    const [prePath, setPrePath] = useState('/');
    const [editor, setEditor] = useState('');
    const pathLocal = getPathFromLocalStroage();

    const navigate = useNavigate();
    const location = useLocation();
    const loader = useSelector((state) => state?.loader);
    const org_info = useSelector((state) => state?.org_info?.value);
    const org_Id = org_info?.default_organizations?.Id
    const isOrg_id = location.pathname.includes(org_Id)
    let reload = window.performance.getEntriesByType('navigation').map((nav) => nav.type);

    let enabled_init = ['post', 'settings','create-new-post','crawl','post-settings','related-questions'];
    let parts = location.pathname.split('/');

    let path = ['scheduled', 'drafts', 'published', 'pending-review', 'failed', 'trash']
    let commonValues = parts.find(value => path.includes(value));

    const FindPath = path.includes(commonValues)

    useEffect(() => {
        if (FindPath) {
            setEditor(commonValues)
        }
    }, [location])

    // useEffect(() => {
    //     console.log('pathLocal.pathLocal ', pathLocal.path);
    //     if (`/c${org_Id}` === location.pathname) {
    //         console.log("=======");
    //         // setPrePath('/post/published');
    //         pathLocal.path? navigate(`/${org_Id}${pathLocal.path}`): navigate(`/${org_Id}/post/drafts`);
    //     }
    // }, [location])

    useEffect(() => {
        if (reload[0] === 'reload' && location.pathname !== '/error-404' && location.pathname !== '/admintool') {
            navigate('/');
            setPrePath(location.pathname);
          
        } else {
            if (reload[0] === 'reload' && location.pathname === '/error-404') {
                navigate('/')
            }
        }
    }, []);

    useEffect(() => {
       
        if (!isInitFetched && enabled_init?.indexOf(location?.pathname?.split('/')[2]) !== -1) {
            setPrePath(location.pathname);
            navigate('/');
            // console.log('location.pathname',location.pathname,location?.pathname?.split('/')[2]);
        }

    }, [location]);

    return (
        <>
            {loader.isLoading && (
                <Box sx={{ width: "100%", height: "2px", top: 0, zIndex: 4, position: 'absolute' }}>
                    <LinearProgress value={0} valueBuffer={10} />
                </Box>
            )}
            <Box className='main_wrap'>
                <NavBar isFetched={isInitFetched} expand={toggle} />
                <Box className='main_wrapper'>
                    <TopBar isFetched={isInitFetched} expand={toggle} setIsNavExpand={setToggle} />
                    <Box className='main_content'>
                        <Routes>
                            <Route path='/' exact element={<Pages.OrgInit prePath={prePath} isFetched={(fetched) => setIsInitFetched(fetched)} />} />
                            <Route path="/admintool" element={<Pages.AdminTool />} />
                            <Route path={`c${org_Id}`} element={ <Pages.Home />}/> 
                            <Route exact path=":id/post/:type" index element={isInitFetched ? <Pages.Home /> : null} />
                            <Route path=":id/settings" element={isInitFetched ? <Pages.Settings /> : null} />
                            <Route path=":id/Connector" element={isInitFetched ? <Pages.Connector /> : null} />
                            <Route path=":id/Integration" element={isInitFetched ? <Pages.Integration /> : null} />
                            <Route path=":id/upload" element={isInitFetched ? <Pages.Upload /> : null} />
                            <Route path=":id/create-new-post" element={isInitFetched ? <Pages.NewPost /> : null} />
                            <Route path=":id/related-questions" element={isInitFetched ? <Pages.PostTableView /> : null} />
                            <Route path=":id/post-settings" element={isInitFetched ? <Pages.PostSettings/> : null} />
                            <Route path=":id/crawl" element={isInitFetched ? <Pages.CrawlLog/> : null} />
                            <Route path=":id/calendar" element={isInitFetched ? <Pages.FullCalendar/> : null} />
                            {editor && <Route path={`:id/post/:${editor}/:id`} element={isInitFetched ? <Pages.Editor /> : null} />}
                            {/* {editor && <Route path={`:id/post/:${editor}/:id/view`} element={isInitFetched ? <Pages.ViewPage /> : null} />} */}
                            <Route path="/*" element={<Navigate to={`c${org_Id}${pathLocal.path}`} />} />
                            <Route path="/error-404" element={<Pages.ErrorPage />} />
                        </Routes>
                    </Box>
                </Box>
            </Box >
        </>
    )
};

export default AppRoute;