// const Country = () => [
//     "America/Aruba",
//     "Asia/Kabul",
//     "Africa/Luanda",
//     "America/Anguilla",
//     "Europe/Mariehamn",
//     "Europe/Tirane",
//     "Europe/Andorra",
//     "Asia/Dubai",
//     "America/Argentina/Buenos_Aires",
//     "America/Argentina/Cordoba",
//     "America/Argentina/Salta",
//     "America/Argentina/Jujuy",
//     "America/Argentina/Tucuman",
//     "America/Argentina/Catamarca",
//     "America/Argentina/La_Rioja",
//     "America/Argentina/San_Juan",
//     "America/Argentina/Mendoza",
//     "America/Argentina/San_Luis",
//     "America/Argentina/Rio_Gallegos",
//     "America/Argentina/Ushuaia",
//     "Asia/Yerevan",
//     "Pacific/Pago_Pago",
//     "Antarctica/McMurdo",
//     "Antarctica/Casey",
//     "Antarctica/Davis",
//     "Antarctica/DumontDUrville",
//     "Antarctica/Mawson",
//     "Antarctica/Palmer",
//     "Antarctica/Rothera",
//     "Antarctica/Syowa",
//     "Antarctica/Troll",
//     "Antarctica/Vostok",
//     "Indian/Kerguelen",
//     "America/Antigua",
//     "Australia/Lord_Howe",
//     "Antarctica/Macquarie",
//     "Australia/Hobart",
//     "Australia/Currie",
//     "Australia/Melbourne",
//     "Australia/Sydney",
//     "Australia/Broken_Hill",
//     "Australia/Brisbane",
//     "Australia/Lindeman",
//     "Australia/Adelaide",
//     "Australia/Darwin",
//     "Australia/Perth",
//     "Australia/Eucla",
//     "Europe/Vienna",
//     "Asia/Baku",
//     "Africa/Bujumbura",
//     "Europe/Brussels",
//     "Africa/Porto-Novo",
//     "Africa/Ouagadougou",
//     "Asia/Dhaka",
//     "Europe/Sofia",
//     "Asia/Bahrain",
//     "America/Nassau",
//     "Europe/Sarajevo",
//     "America/St_Barthelemy",
//     "Europe/Minsk",
//     "America/Belize",
//     "Atlantic/Bermuda",
//     "America/La_Paz",
//     "America/Noronha",
//     "America/Belem",
//     "America/Fortaleza",
//     "America/Recife",
//     "America/Araguaina",
//     "America/Maceio",
//     "America/Bahia",
//     "America/Sao_Paulo",
//     "America/Campo_Grande",
//     "America/Cuiaba",
//     "America/Santarem",
//     "America/Porto_Velho",
//     "America/Boa_Vista",
//     "America/Manaus",
//     "America/Eirunepe",
//     "America/Rio_Branco",
//     "America/Barbados",
//     "Asia/Brunei",
//     "Asia/Thimphu",
//     "Africa/Gaborone",
//     "Africa/Bangui",
//     "America/St_Johns",
//     "America/Halifax",
//     "America/Glace_Bay",
//     "America/Moncton",
//     "America/Goose_Bay",
//     "America/Blanc-Sablon",
//     "America/Toronto",
//     "America/Nipigon",
//     "America/Thunder_Bay",
//     "America/Iqaluit",
//     "America/Pangnirtung",
//     "America/Atikokan",
//     "America/Winnipeg",
//     "America/Rainy_River",
//     "America/Resolute",
//     "America/Rankin_Inlet",
//     "America/Regina",
//     "America/Swift_Current",
//     "America/Edmonton",
//     "America/Cambridge_Bay",
//     "America/Yellowknife",
//     "America/Inuvik",
//     "America/Creston",
//     "America/Dawson_Creek",
//     "America/Fort_Nelson",
//     "America/Vancouver",
//     "America/Whitehorse",
//     "America/Dawson",
//     "Indian/Cocos",
//     "Europe/Zurich",
//     "America/Santiago",
//     "Pacific/Easter",
//     "Asia/Shanghai",
//     "Asia/Urumqi",
//     "Africa/Abidjan",
//     "Africa/Douala",
//     "Africa/Kinshasa",
//     "Africa/Lubumbashi",
//     "Africa/Brazzaville",
//     "Pacific/Rarotonga",
//     "America/Bogota",
//     "Indian/Comoro",
//     "Atlantic/Cape_Verde",
//     "America/Costa_Rica",
//     "America/Havana",
//     "America/Curacao",
//     "Indian/Christmas",
//     "America/Cayman",
//     "Asia/Nicosia",
//     "Europe/Prague",
//     "Europe/Berlin",
//     "Europe/Busingen",
//     "Africa/Djibouti",
//     "America/Dominica",
//     "Europe/Copenhagen",
//     "America/Santo_Domingo",
//     "Africa/Algiers",
//     "America/Guayaquil",
//     "Pacific/Galapagos",
//     "Africa/Cairo",
//     "Africa/Asmara",
//     "Africa/El_Aaiun",
//     "Europe/Madrid",
//     "Africa/Ceuta",
//     "Atlantic/Canary",
//     "Europe/Tallinn",
//     "Africa/Addis_Ababa",
//     "Europe/Helsinki",
//     "Pacific/Fiji",
//     "Atlantic/Stanley",
//     "Europe/Paris",
//     "Atlantic/Faroe",
//     "Pacific/Chuuk",
//     "Pacific/Pohnpei",
//     "Pacific/Kosrae",
//     "Africa/Libreville",
//     "Europe/London",
//     "Asia/Tbilisi",
//     "Europe/Guernsey",
//     "Africa/Accra",
//     "Europe/Gibraltar",
//     "Africa/Conakry",
//     "America/Guadeloupe",
//     "Africa/Banjul",
//     "Africa/Bissau",
//     "Africa/Malabo",
//     "Europe/Athens",
//     "America/Grenada",
//     "America/Godthab",
//     "America/Danmarkshavn",
//     "America/Scoresbysund",
//     "America/Thule",
//     "America/Guatemala",
//     "America/Cayenne",
//     "Pacific/Guam",
//     "America/Guyana",
//     "Asia/Hong_Kong",
//     "America/Tegucigalpa",
//     "Europe/Zagreb",
//     "America/Port-au-Prince",
//     "Europe/Budapest",
//     "Asia/Jakarta",
//     "Asia/Pontianak",
//     "Asia/Makassar",
//     "Asia/Jayapura",
//     "Europe/Isle_of_Man",
//     "Asia/Kolkata",
//     "Asia/Calcutta",
//     "Indian/Chagos",
//     "Europe/Dublin",
//     "Asia/Tehran",
//     "Asia/Baghdad",
//     "Atlantic/Reykjavik",
//     "Asia/Jerusalem",
//     "Europe/Rome",
//     "America/Jamaica",
//     "Europe/Jersey",
//     "Asia/Amman",
//     "Asia/Tokyo",
//     "Asia/Almaty",
//     "Asia/Qyzylorda",
//     "Asia/Aqtobe",
//     "Asia/Aqtau",
//     "Asia/Oral",
//     "Africa/Nairobi",
//     "Asia/Bishkek",
//     "Asia/Phnom_Penh",
//     "Pacific/Tarawa",
//     "Pacific/Enderbury",
//     "Pacific/Kiritimati",
//     "America/St_Kitts",
//     "Asia/Seoul",
//     "Europe/Belgrade",
//     "Asia/Kuwait",
//     "Asia/Vientiane",
//     "Asia/Beirut",
//     "Africa/Monrovia",
//     "Africa/Tripoli",
//     "America/St_Lucia",
//     "Europe/Vaduz",
//     "Asia/Colombo",
//     "Africa/Maseru",
//     "Europe/Vilnius",
//     "Europe/Luxembourg",
//     "Europe/Riga",
//     "Asia/Macau",
//     "America/Marigot",
//     "Africa/Casablanca",
//     "Europe/Monaco",
//     "Europe/Chisinau",
//     "Indian/Antananarivo",
//     "Indian/Maldives",
//     "America/Mexico_City",
//     "America/Cancun",
//     "America/Merida",
//     "America/Monterrey",
//     "America/Matamoros",
//     "America/Mazatlan",
//     "America/Chihuahua",
//     "America/Ojinaga",
//     "America/Hermosillo",
//     "America/Tijuana",
//     "America/Bahia_Banderas",
//     "Pacific/Majuro",
//     "Pacific/Kwajalein",
//     "Europe/Skopje",
//     "Africa/Bamako",
//     "Europe/Malta",
//     "Asia/Rangoon",
//     "Europe/Podgorica",
//     "Asia/Ulaanbaatar",
//     "Asia/Hovd",
//     "Asia/Choibalsan",
//     "Pacific/Saipan",
//     "Africa/Maputo",
//     "Africa/Nouakchott",
//     "America/Montserrat",
//     "America/Martinique",
//     "Indian/Mauritius",
//     "Africa/Blantyre",
//     "Asia/Kuala_Lumpur",
//     "Asia/Kuching",
//     "Indian/Mayotte",
//     "Africa/Windhoek",
//     "Pacific/Noumea",
//     "Africa/Niamey",
//     "Pacific/Norfolk",
//     "Africa/Lagos",
//     "America/Managua",
//     "Pacific/Niue",
//     "Europe/Amsterdam",
//     "Europe/Oslo",
//     "Asia/Kathmandu",
//     "Pacific/Nauru",
//     "Pacific/Auckland",
//     "Pacific/Chatham",
//     "Asia/Muscat",
//     "Asia/Karachi",
//     "America/Panama",
//     "Pacific/Pitcairn",
//     "America/Lima",
//     "Asia/Manila",
//     "Pacific/Palau",
//     "Pacific/Port_Moresby",
//     "Pacific/Bougainville",
//     "Europe/Warsaw",
//     "America/Puerto_Rico",
//     "Asia/Pyongyang",
//     "Europe/Lisbon",
//     "Atlantic/Madeira",
//     "Atlantic/Azores",
//     "America/Asuncion",
//     "Asia/Gaza",
//     "Asia/Hebron",
//     "Pacific/Tahiti",
//     "Pacific/Marquesas",
//     "Pacific/Gambier",
//     "Asia/Qatar",
//     "Indian/Reunion",
//     "Europe/Bucharest",
//     "Europe/Kaliningrad",
//     "Europe/Moscow",
//     "Europe/Simferopol",
//     "Europe/Volgograd",
//     "Europe/Kirov",
//     "Europe/Astrakhan",
//     "Europe/Samara",
//     "Europe/Ulyanovsk",
//     "Asia/Yekaterinburg",
//     "Asia/Omsk",
//     "Asia/Novosibirsk",
//     "Asia/Barnaul",
//     "Asia/Tomsk",
//     "Asia/Novokuznetsk",
//     "Asia/Krasnoyarsk",
//     "Asia/Irkutsk",
//     "Asia/Chita",
//     "Asia/Yakutsk",
//     "Asia/Khandyga",
//     "Asia/Vladivostok",
//     "Asia/Ust-Nera",
//     "Asia/Magadan",
//     "Asia/Sakhalin",
//     "Asia/Srednekolymsk",
//     "Asia/Kamchatka",
//     "Asia/Anadyr",
//     "Africa/Kigali",
//     "Asia/Riyadh",
//     "Africa/Khartoum",
//     "Africa/Dakar",
//     "Asia/Singapore",
//     "Atlantic/South_Georgia",
//     "Arctic/Longyearbyen",
//     "Pacific/Guadalcanal",
//     "Africa/Freetown",
//     "America/El_Salvador",
//     "Europe/San_Marino",
//     "Africa/Mogadishu",
//     "America/Miquelon",
//     "Africa/Juba",
//     "Africa/Sao_Tome",
//     "America/Paramaribo",
//     "Europe/Bratislava",
//     "Europe/Ljubljana",
//     "Europe/Stockholm",
//     "Africa/Mbabane",
//     "America/Lower_Princes",
//     "Indian/Mahe",
//     "Asia/Damascus",
//     "America/Grand_Turk",
//     "Africa/Ndjamena",
//     "Africa/Lome",
//     "Asia/Bangkok",
//     "Asia/Dushanbe",
//     "Pacific/Fakaofo",
//     "Asia/Ashgabat",
//     "Asia/Dili",
//     "Pacific/Tongatapu",
//     "America/Port_of_Spain",
//     "Africa/Tunis",
//     "Europe/Istanbul",
//     "Pacific/Funafuti",
//     "Asia/Taipei",
//     "Africa/Dar_es_Salaam",
//     "Africa/Kampala",
//     "Europe/Kiev",
//     "Europe/Uzhgorod",
//     "Europe/Zaporozhye",
//     "Pacific/Johnston",
//     "Pacific/Midway",
//     "Pacific/Wake",
//     "America/Montevideo",
//     "America/New_York",
//     "America/Detroit",
//     "America/Kentucky/Louisville",
//     "America/Kentucky/Monticello",
//     "America/Indiana/Indianapolis",
//     "America/Indiana/Vincennes",
//     "America/Indiana/Winamac",
//     "America/Indiana/Marengo",
//     "America/Indiana/Petersburg",
//     "America/Indiana/Vevay",
//     "America/Chicago",
//     "America/Indiana/Tell_City",
//     "America/Indiana/Knox",
//     "America/Menominee",
//     "America/North_Dakota/Center",
//     "America/North_Dakota/New_Salem",
//     "America/North_Dakota/Beulah",
//     "America/Denver",
//     "America/Boise",
//     "America/Phoenix",
//     "America/Los_Angeles",
//     "America/Anchorage",
//     "America/Juneau",
//     "America/Sitka",
//     "America/Metlakatla",
//     "America/Yakutat",
//     "America/Nome",
//     "America/Adak",
//     "Pacific/Honolulu",
//     "Asia/Samarkand",
//     "Asia/Tashkent",
//     "Europe/Vatican",
//     "America/St_Vincent",
//     "America/Caracas",
//     "America/Tortola",
//     "America/St_Thomas",
//     "Asia/Ho_Chi_Minh",
//     "Pacific/Efate",
//     "Pacific/Wallis",
//     "Pacific/Apia",
//     "Asia/Aden",
//     "Africa/Johannesburg",
//     "Africa/Lusaka",
//     "Africa/Harare"
// ];

const TimeZones = {
    "Africa/Bamako": {
        "display_text": "(GMT+0) Africa/Bamako",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Africa/Banjul": {
        "display_text": "(GMT+0) Africa/Banjul",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Africa/Conakry": {
        "display_text": "(GMT+0) Africa/Conakry",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Africa/Dakar": {
        "display_text": "(GMT+0) Africa/Dakar",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Africa/Freetown": {
        "display_text": "(GMT+0) Africa/Freetown",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Africa/Lome": {
        "display_text": "(GMT+0) Africa/Lome",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Africa/Nouakchott": {
        "display_text": "(GMT+0) Africa/Nouakchott",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Africa/Ouagadougou": {
        "display_text": "(GMT+0) Africa/Ouagadougou",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Africa/Timbuktu": {
        "display_text": "(GMT+0) Africa/Timbuktu",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Atlantic/St_Helena": {
        "display_text": "(GMT+0) Atlantic/St Helena",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Egypt": {
        "display_text": "(GMT+0) Egypt",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Africa/Maseru": {
        "display_text": "(GMT+0) Africa/Maseru",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Africa/Mbabane": {
        "display_text": "(GMT+0) Africa/Mbabane",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Africa/Bangui": {
        "display_text": "(GMT+0) Africa/Bangui",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Africa/Brazzaville": {
        "display_text": "(GMT+0) Africa/Brazzaville",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Africa/Douala": {
        "display_text": "(GMT+0) Africa/Douala",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Africa/Kinshasa": {
        "display_text": "(GMT+0) Africa/Kinshasa",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Africa/Libreville": {
        "display_text": "(GMT+0) Africa/Libreville",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Africa/Luanda": {
        "display_text": "(GMT+0) Africa/Luanda",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Africa/Malabo": {
        "display_text": "(GMT+0) Africa/Malabo",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Africa/Niamey": {
        "display_text": "(GMT+0) Africa/Niamey",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Africa/Porto-Novo": {
        "display_text": "(GMT+0) Africa/Porto-Novo",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Africa/Blantyre": {
        "display_text": "(GMT+0) Africa/Blantyre",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Africa/Bujumbura": {
        "display_text": "(GMT+0) Africa/Bujumbura",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Africa/Gaborone": {
        "display_text": "(GMT+0) Africa/Gaborone",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Africa/Harare": {
        "display_text": "(GMT+0) Africa/Harare",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Africa/Kigali": {
        "display_text": "(GMT+0) Africa/Kigali",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Africa/Lubumbashi": {
        "display_text": "(GMT+0) Africa/Lubumbashi",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Africa/Lusaka": {
        "display_text": "(GMT+0) Africa/Lusaka",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Africa/Addis_Ababa": {
        "display_text": "(GMT+0) Africa/Addis Ababa",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Africa/Asmara": {
        "display_text": "(GMT+0) Africa/Asmara",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Africa/Asmera": {
        "display_text": "(GMT+0) Africa/Asmera",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Africa/Dar_es_Salaam": {
        "display_text": "(GMT+0) Africa/Dar es Salaam",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Africa/Djibouti": {
        "display_text": "(GMT+0) Africa/Djibouti",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Africa/Kampala": {
        "display_text": "(GMT+0) Africa/Kampala",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Africa/Mogadishu": {
        "display_text": "(GMT+0) Africa/Mogadishu",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Indian/Antananarivo": {
        "display_text": "(GMT+0) Indian/Antananarivo",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Indian/Comoro": {
        "display_text": "(GMT+0) Indian/Comoro",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Indian/Mayotte": {
        "display_text": "(GMT+0) Indian/Mayotte",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Libya": {
        "display_text": "(GMT+0) Libya",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "America/Atka": {
        "display_text": "(GMT+0) America/Atka",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "US/Aleutian": {
        "display_text": "(GMT+0) US/Aleutian",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "US/Alaska": {
        "display_text": "(GMT+0) US/Alaska",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "America/Buenos_Aires": {
        "display_text": "(GMT+0) America/Buenos Aires",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "America/Argentina/ComodRivadavia": {
        "display_text": "(GMT+0) America/Argentina/ComodRivadavia",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "America/Catamarca": {
        "display_text": "(GMT+0) America/Catamarca",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "America/Cordoba": {
        "display_text": "(GMT+0) America/Cordoba",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "America/Rosario": {
        "display_text": "(GMT+0) America/Rosario",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "America/Jujuy": {
        "display_text": "(GMT+0) America/Jujuy",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "America/Mendoza": {
        "display_text": "(GMT+0) America/Mendoza",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "America/Coral_Harbour": {
        "display_text": "(GMT+0) America/Coral Harbour",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "US/Central": {
        "display_text": "(GMT+0) US/Central",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "America/Aruba": {
        "display_text": "(GMT+0) America/Aruba",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "America/Kralendijk": {
        "display_text": "(GMT+0) America/Kralendijk",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "America/Lower_Princes": {
        "display_text": "(GMT+0) America/Lower Princes",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "America/Shiprock": {
        "display_text": "(GMT+0) America/Shiprock",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Navajo": {
        "display_text": "(GMT+0) Navajo",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "US/Mountain": {
        "display_text": "(GMT+0) US/Mountain",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "US/Michigan": {
        "display_text": "(GMT+0) US/Michigan",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Canada/Mountain": {
        "display_text": "(GMT+0) Canada/Mountain",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "America/Indiana/Indianapolis": {
        "display_text": "(GMT+0) America/Indiana/Indianapolis",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "America/Indianapolis": {
        "display_text": "(GMT+0) America/Indianapolis",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "US/East-Indiana": {
        "display_text": "(GMT+0) US/East-Indiana",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "America/Nuuk": {
        "display_text": "(GMT-3) America/Nuuk",
        "offset": -3.0,
        "gmt": "GMT-3"
    },
    "Canada/Atlantic": {
        "display_text": "(GMT+0) Canada/Atlantic",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Cuba": {
        "display_text": "(GMT+0) Cuba",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "America/Knox_IN": {
        "display_text": "(GMT+0) America/Knox IN",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "US/Indiana-Starke": {
        "display_text": "(GMT+0) US/Indiana-Starke",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Jamaica": {
        "display_text": "(GMT+0) Jamaica",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "America/Louisville": {
        "display_text": "(GMT+0) America/Louisville",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "US/Pacific": {
        "display_text": "(GMT+0) US/Pacific",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "US/Pacific-New": {
        "display_text": "(GMT+0) US/Pacific-New",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Brazil/West": {
        "display_text": "(GMT+0) Brazil/West",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Mexico/BajaSur": {
        "display_text": "(GMT+0) Mexico/BajaSur",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Mexico/General": {
        "display_text": "(GMT+0) Mexico/General",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "US/Eastern": {
        "display_text": "(GMT+0) US/Eastern",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Brazil/DeNoronha": {
        "display_text": "(GMT+0) Brazil/DeNoronha",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "America/Cayman": {
        "display_text": "(GMT+0) America/Cayman",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "US/Arizona": {
        "display_text": "(GMT+0) US/Arizona",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "America/Anguilla": {
        "display_text": "(GMT+0) America/Anguilla",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "America/Antigua": {
        "display_text": "(GMT+0) America/Antigua",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "America/Dominica": {
        "display_text": "(GMT+0) America/Dominica",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "America/Grenada": {
        "display_text": "(GMT+0) America/Grenada",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "America/Guadeloupe": {
        "display_text": "(GMT+0) America/Guadeloupe",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "America/Marigot": {
        "display_text": "(GMT+0) America/Marigot",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "America/Montserrat": {
        "display_text": "(GMT+0) America/Montserrat",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "America/St_Barthelemy": {
        "display_text": "(GMT+0) America/St Barthelemy",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "America/St_Kitts": {
        "display_text": "(GMT+0) America/St Kitts",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "America/St_Lucia": {
        "display_text": "(GMT+0) America/St Lucia",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "America/St_Thomas": {
        "display_text": "(GMT+0) America/St Thomas",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "America/St_Vincent": {
        "display_text": "(GMT+0) America/St Vincent",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "America/Tortola": {
        "display_text": "(GMT+0) America/Tortola",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "America/Virgin": {
        "display_text": "(GMT+0) America/Virgin",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Canada/Saskatchewan": {
        "display_text": "(GMT+0) Canada/Saskatchewan",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "America/Porto_Acre": {
        "display_text": "(GMT+0) America/Porto Acre",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Brazil/Acre": {
        "display_text": "(GMT+0) Brazil/Acre",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Chile/Continental": {
        "display_text": "(GMT+0) Chile/Continental",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Brazil/East": {
        "display_text": "(GMT+0) Brazil/East",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Canada/Newfoundland": {
        "display_text": "(GMT+0) Canada/Newfoundland",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "America/Ensenada": {
        "display_text": "(GMT+0) America/Ensenada",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "America/Santa_Isabel": {
        "display_text": "(GMT+0) America/Santa Isabel",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Mexico/BajaNorte": {
        "display_text": "(GMT+0) Mexico/BajaNorte",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "America/Montreal": {
        "display_text": "(GMT+0) America/Montreal",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Canada/Eastern": {
        "display_text": "(GMT+0) Canada/Eastern",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Canada/Pacific": {
        "display_text": "(GMT+0) Canada/Pacific",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Canada/Yukon": {
        "display_text": "(GMT+0) Canada/Yukon",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Canada/Central": {
        "display_text": "(GMT+0) Canada/Central",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Asia/Ashkhabad": {
        "display_text": "(GMT+0) Asia/Ashkhabad",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Asia/Phnom_Penh": {
        "display_text": "(GMT+0) Asia/Phnom Penh",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Asia/Vientiane": {
        "display_text": "(GMT+0) Asia/Vientiane",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Asia/Dacca": {
        "display_text": "(GMT+0) Asia/Dacca",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Asia/Muscat": {
        "display_text": "(GMT+0) Asia/Muscat",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Asia/Saigon": {
        "display_text": "(GMT+0) Asia/Saigon",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Hongkong": {
        "display_text": "(GMT+0) Hongkong",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Asia/Tel_Aviv": {
        "display_text": "(GMT+0) Asia/Tel Aviv",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Israel": {
        "display_text": "(GMT+0) Israel",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Asia/Katmandu": {
        "display_text": "(GMT+0) Asia/Katmandu",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Asia/Calcutta": {
        "display_text": "(GMT+0) Asia/Calcutta",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Asia/Singapore": {
        "display_text": "(GMT+0) Asia/Singapore",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Singapore": {
        "display_text": "(GMT+0) Singapore",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Asia/Macao": {
        "display_text": "(GMT+0) Asia/Macao",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Asia/Ujung_Pandang": {
        "display_text": "(GMT+0) Asia/Ujung Pandang",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Europe/Nicosia": {
        "display_text": "(GMT+0) Europe/Nicosia",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Asia/Bahrain": {
        "display_text": "(GMT+0) Asia/Bahrain",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Asia/Yangon": {
        "display_text": "(GMT+0) Asia/Yangon",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Asia/Aden": {
        "display_text": "(GMT+0) Asia/Aden",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Asia/Kuwait": {
        "display_text": "(GMT+0) Asia/Kuwait",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "ROK": {
        "display_text": "(GMT+0) ROK",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Asia/Chongqing": {
        "display_text": "(GMT+0) Asia/Chongqing",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Asia/Chungking": {
        "display_text": "(GMT+0) Asia/Chungking",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Asia/Harbin": {
        "display_text": "(GMT+0) Asia/Harbin",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "PRC": {
        "display_text": "(GMT+0) PRC",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "ROC": {
        "display_text": "(GMT+0) ROC",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Iran": {
        "display_text": "(GMT+0) Iran",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Asia/Thimbu": {
        "display_text": "(GMT+0) Asia/Thimbu",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Japan": {
        "display_text": "(GMT+0) Japan",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Asia/Ulan_Bator": {
        "display_text": "(GMT+0) Asia/Ulan Bator",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Asia/Kashgar": {
        "display_text": "(GMT+0) Asia/Kashgar",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Atlantic/Faeroe": {
        "display_text": "(GMT+0) Atlantic/Faeroe",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Iceland": {
        "display_text": "(GMT+0) Iceland",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Etc/GMT+2": {
        "display_text": "(GMT+0) Etc/GMT+2",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Australia/South": {
        "display_text": "(GMT+0) Australia/South",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Australia/Queensland": {
        "display_text": "(GMT+0) Australia/Queensland",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Australia/Yancowinna": {
        "display_text": "(GMT+0) Australia/Yancowinna",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Australia/North": {
        "display_text": "(GMT+0) Australia/North",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Australia/Tasmania": {
        "display_text": "(GMT+0) Australia/Tasmania",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Australia/LHI": {
        "display_text": "(GMT+0) Australia/LHI",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Australia/Victoria": {
        "display_text": "(GMT+0) Australia/Victoria",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Australia/West": {
        "display_text": "(GMT+0) Australia/West",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Australia/ACT": {
        "display_text": "(GMT+0) Australia/ACT",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Australia/Canberra": {
        "display_text": "(GMT+0) Australia/Canberra",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Australia/NSW": {
        "display_text": "(GMT+0) Australia/NSW",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Etc/GMT": {
        "display_text": "(GMT+0) Etc/GMT",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Etc/GMT+0": {
        "display_text": "(GMT+0) Etc/GMT+0",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Etc/GMT0": {
        "display_text": "(GMT+0) Etc/GMT0",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Etc/Greenwich": {
        "display_text": "(GMT+0) Etc/Greenwich",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "GMT": {
        "display_text": "(GMT+0) GMT",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "GMT+0": {
        "display_text": "(GMT+0) GMT+0",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "GMT-0": {
        "display_text": "(GMT+0) GMT-0",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "GMT0": {
        "display_text": "(GMT+0) GMT0",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Greenwich": {
        "display_text": "(GMT+0) Greenwich",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Etc/UCT": {
        "display_text": "(GMT+0) Etc/UCT",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Etc/Universal": {
        "display_text": "(GMT+0) Etc/Universal",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Etc/Zulu": {
        "display_text": "(GMT+0) Etc/Zulu",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "UCT": {
        "display_text": "(GMT+0) UCT",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "UTC": {
        "display_text": "(GMT+0) UTC",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Universal": {
        "display_text": "(GMT+0) Universal",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Zulu": {
        "display_text": "(GMT+0) Zulu",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Europe/Ljubljana": {
        "display_text": "(GMT+0) Europe/Ljubljana",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Europe/Podgorica": {
        "display_text": "(GMT+0) Europe/Podgorica",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Europe/Sarajevo": {
        "display_text": "(GMT+0) Europe/Sarajevo",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Europe/Skopje": {
        "display_text": "(GMT+0) Europe/Skopje",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Europe/Zagreb": {
        "display_text": "(GMT+0) Europe/Zagreb",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Europe/Tiraspol": {
        "display_text": "(GMT+0) Europe/Tiraspol",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Eire": {
        "display_text": "(GMT+0) Eire",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Europe/Mariehamn": {
        "display_text": "(GMT+0) Europe/Mariehamn",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Asia/Istanbul": {
        "display_text": "(GMT+0) Asia/Istanbul",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Turkey": {
        "display_text": "(GMT+0) Turkey",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Portugal": {
        "display_text": "(GMT+0) Portugal",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Europe/Belfast": {
        "display_text": "(GMT+0) Europe/Belfast",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Europe/Guernsey": {
        "display_text": "(GMT+0) Europe/Guernsey",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Europe/Isle_of_Man": {
        "display_text": "(GMT+0) Europe/Isle of Man",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Europe/Jersey": {
        "display_text": "(GMT+0) Europe/Jersey",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "GB": {
        "display_text": "(GMT+0) GB",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "GB-Eire": {
        "display_text": "(GMT+0) GB-Eire",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "W-SU": {
        "display_text": "(GMT+0) W-SU",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Arctic/Longyearbyen": {
        "display_text": "(GMT+0) Arctic/Longyearbyen",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Atlantic/Jan_Mayen": {
        "display_text": "(GMT+0) Atlantic/Jan Mayen",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Europe/Bratislava": {
        "display_text": "(GMT+0) Europe/Bratislava",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Europe/San_Marino": {
        "display_text": "(GMT+0) Europe/San Marino",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Europe/Vatican": {
        "display_text": "(GMT+0) Europe/Vatican",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Poland": {
        "display_text": "(GMT+0) Poland",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Europe/Busingen": {
        "display_text": "(GMT+0) Europe/Busingen",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Europe/Vaduz": {
        "display_text": "(GMT+0) Europe/Vaduz",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Etc/GMT-7": {
        "display_text": "(GMT+0) Etc/GMT-7",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Antarctica/McMurdo": {
        "display_text": "(GMT+0) Antarctica/McMurdo",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Antarctica/South_Pole": {
        "display_text": "(GMT+0) Antarctica/South Pole",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "NZ": {
        "display_text": "(GMT+0) NZ",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "NZ-CHAT": {
        "display_text": "(GMT+0) NZ-CHAT",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Pacific/Truk": {
        "display_text": "(GMT+0) Pacific/Truk",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Pacific/Yap": {
        "display_text": "(GMT+0) Pacific/Yap",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Chile/EasterIsland": {
        "display_text": "(GMT+0) Chile/EasterIsland",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Pacific/Saipan": {
        "display_text": "(GMT+0) Pacific/Saipan",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Pacific/Johnston": {
        "display_text": "(GMT+0) Pacific/Johnston",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "US/Hawaii": {
        "display_text": "(GMT+0) US/Hawaii",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Kwajalein": {
        "display_text": "(GMT+0) Kwajalein",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Pacific/Midway": {
        "display_text": "(GMT+0) Pacific/Midway",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Pacific/Samoa": {
        "display_text": "(GMT+0) Pacific/Samoa",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "US/Samoa": {
        "display_text": "(GMT+0) US/Samoa",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Etc/GMT-9": {
        "display_text": "(GMT+0) Etc/GMT-9",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Pacific/Ponape": {
        "display_text": "(GMT+0) Pacific/Ponape",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Etc/GMT-10": {
        "display_text": "(GMT+0) Etc/GMT-10",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Etc/GMT-12": {
        "display_text": "(GMT+0) Etc/GMT-12",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Pacific/Funafuti": {
        "display_text": "(GMT+0) Pacific/Funafuti",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Pacific/Wake": {
        "display_text": "(GMT+0) Pacific/Wake",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Pacific/Wallis": {
        "display_text": "(GMT+0) Pacific/Wallis",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Africa/Abidjan": {
        "display_text": "(GMT+0) Africa/Abidjan",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Africa/Accra": {
        "display_text": "(GMT+0) Africa/Accra",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Africa/Nairobi": {
        "display_text": "(GMT+3) Africa/Nairobi",
        "offset": 3.0,
        "gmt": "GMT+3"
    },
    "Africa/Algiers": {
        "display_text": "(GMT+1) Africa/Algiers",
        "offset": 1.0,
        "gmt": "GMT+1"
    },
    "Africa/Lagos": {
        "display_text": "(GMT+1) Africa/Lagos",
        "offset": 1.0,
        "gmt": "GMT+1"
    },
    "Africa/Bissau": {
        "display_text": "(GMT+0) Africa/Bissau",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Africa/Maputo": {
        "display_text": "(GMT+2) Africa/Maputo",
        "offset": 2.0,
        "gmt": "GMT+2"
    },
    "Africa/Cairo": {
        "display_text": "(GMT+2) Africa/Cairo",
        "offset": 2.0,
        "gmt": "GMT+2"
    },
    "Africa/Casablanca": {
        "display_text": "(GMT+0) Africa/Casablanca",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Africa/Ceuta": {
        "display_text": "(GMT+1) Africa/Ceuta",
        "offset": 1.0,
        "gmt": "GMT+1"
    },
    "Africa/El_Aaiun": {
        "display_text": "(GMT+0) Africa/El Aaiun",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Africa/Johannesburg": {
        "display_text": "(GMT+2) Africa/Johannesburg",
        "offset": 2.0,
        "gmt": "GMT+2"
    },
    "Africa/Juba": {
        "display_text": "(GMT+3) Africa/Juba",
        "offset": 3.0,
        "gmt": "GMT+3"
    },
    "Africa/Khartoum": {
        "display_text": "(GMT+2) Africa/Khartoum",
        "offset": 2.0,
        "gmt": "GMT+2"
    },
    "Africa/Monrovia": {
        "display_text": "(GMT+0) Africa/Monrovia",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Africa/Ndjamena": {
        "display_text": "(GMT+1) Africa/Ndjamena",
        "offset": 1.0,
        "gmt": "GMT+1"
    },
    "Africa/Sao_Tome": {
        "display_text": "(GMT+0) Africa/Sao Tome",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Africa/Tripoli": {
        "display_text": "(GMT+2) Africa/Tripoli",
        "offset": 2.0,
        "gmt": "GMT+2"
    },
    "Africa/Tunis": {
        "display_text": "(GMT+1) Africa/Tunis",
        "offset": 1.0,
        "gmt": "GMT+1"
    },
    "Africa/Windhoek": {
        "display_text": "(GMT+2) Africa/Windhoek",
        "offset": 2.0,
        "gmt": "GMT+2"
    },
    "America/Adak": {
        "display_text": "(GMT-10) America/Adak",
        "offset": -10.0,
        "gmt": "GMT-10"
    },
    "America/Anchorage": {
        "display_text": "(GMT-9) America/Anchorage",
        "offset": -9.0,
        "gmt": "GMT-9"
    },
    "America/Port_of_Spain": {
        "display_text": "(GMT-4) America/Port of Spain",
        "offset": -4.0,
        "gmt": "GMT-4"
    },
    "America/Araguaina": {
        "display_text": "(GMT-3) America/Araguaina",
        "offset": -3.0,
        "gmt": "GMT-3"
    },
    "America/Argentina/Buenos_Aires": {
        "display_text": "(GMT-3) America/Argentina/Buenos Aires",
        "offset": -3.0,
        "gmt": "GMT-3"
    },
    "America/Argentina/Catamarca": {
        "display_text": "(GMT-3) America/Argentina/Catamarca",
        "offset": -3.0,
        "gmt": "GMT-3"
    },
    "America/Argentina/Cordoba": {
        "display_text": "(GMT-3) America/Argentina/Cordoba",
        "offset": -3.0,
        "gmt": "GMT-3"
    },
    "America/Argentina/Jujuy": {
        "display_text": "(GMT-3) America/Argentina/Jujuy",
        "offset": -3.0,
        "gmt": "GMT-3"
    },
    "America/Argentina/La_Rioja": {
        "display_text": "(GMT-3) America/Argentina/La Rioja",
        "offset": -3.0,
        "gmt": "GMT-3"
    },
    "America/Argentina/Mendoza": {
        "display_text": "(GMT-3) America/Argentina/Mendoza",
        "offset": -3.0,
        "gmt": "GMT-3"
    },
    "America/Argentina/Rio_Gallegos": {
        "display_text": "(GMT-3) America/Argentina/Rio Gallegos",
        "offset": -3.0,
        "gmt": "GMT-3"
    },
    "America/Argentina/Salta": {
        "display_text": "(GMT-3) America/Argentina/Salta",
        "offset": -3.0,
        "gmt": "GMT-3"
    },
    "America/Argentina/San_Juan": {
        "display_text": "(GMT-3) America/Argentina/San Juan",
        "offset": -3.0,
        "gmt": "GMT-3"
    },
    "America/Argentina/San_Luis": {
        "display_text": "(GMT-3) America/Argentina/San Luis",
        "offset": -3.0,
        "gmt": "GMT-3"
    },
    "America/Argentina/Tucuman": {
        "display_text": "(GMT-3) America/Argentina/Tucuman",
        "offset": -3.0,
        "gmt": "GMT-3"
    },
    "America/Argentina/Ushuaia": {
        "display_text": "(GMT-3) America/Argentina/Ushuaia",
        "offset": -3.0,
        "gmt": "GMT-3"
    },
    "America/Curacao": {
        "display_text": "(GMT-4) America/Curacao",
        "offset": -4.0,
        "gmt": "GMT-4"
    },
    "America/Asuncion": {
        "display_text": "(GMT-4) America/Asuncion",
        "offset": -4.0,
        "gmt": "GMT-4"
    },
    "America/Atikokan": {
        "display_text": "(GMT-5) America/Atikokan",
        "offset": -5.0,
        "gmt": "GMT-5"
    },
    "America/Bahia_Banderas": {
        "display_text": "(GMT-6) America/Bahia Banderas",
        "offset": -6.0,
        "gmt": "GMT-6"
    },
    "America/Bahia": {
        "display_text": "(GMT-3) America/Bahia",
        "offset": -3.0,
        "gmt": "GMT-3"
    },
    "America/Barbados": {
        "display_text": "(GMT-4) America/Barbados",
        "offset": -4.0,
        "gmt": "GMT-4"
    },
    "America/Belem": {
        "display_text": "(GMT-3) America/Belem",
        "offset": -3.0,
        "gmt": "GMT-3"
    },
    "America/Belize": {
        "display_text": "(GMT-6) America/Belize",
        "offset": -6.0,
        "gmt": "GMT-6"
    },
    "America/Blanc-Sablon": {
        "display_text": "(GMT-4) America/Blanc-Sablon",
        "offset": -4.0,
        "gmt": "GMT-4"
    },
    "America/Boa_Vista": {
        "display_text": "(GMT-4) America/Boa Vista",
        "offset": -4.0,
        "gmt": "GMT-4"
    },
    "America/Bogota": {
        "display_text": "(GMT-5) America/Bogota",
        "offset": -5.0,
        "gmt": "GMT-5"
    },
    "America/Boise": {
        "display_text": "(GMT-7) America/Boise",
        "offset": -7.0,
        "gmt": "GMT-7"
    },
    "America/Cambridge_Bay": {
        "display_text": "(GMT-7) America/Cambridge Bay",
        "offset": -7.0,
        "gmt": "GMT-7"
    },
    "America/Campo_Grande": {
        "display_text": "(GMT-4) America/Campo Grande",
        "offset": -4.0,
        "gmt": "GMT-4"
    },
    "America/Cancun": {
        "display_text": "(GMT-5) America/Cancun",
        "offset": -5.0,
        "gmt": "GMT-5"
    },
    "America/Caracas": {
        "display_text": "(GMT-4) America/Caracas",
        "offset": -4.0,
        "gmt": "GMT-4"
    },
    "America/Cayenne": {
        "display_text": "(GMT-3) America/Cayenne",
        "offset": -3.0,
        "gmt": "GMT-3"
    },
    "America/Panama": {
        "display_text": "(GMT-5) America/Panama",
        "offset": -5.0,
        "gmt": "GMT-5"
    },
    "America/Chicago": {
        "display_text": "(GMT-6) America/Chicago",
        "offset": -6.0,
        "gmt": "GMT-6"
    },
    "America/Chihuahua": {
        "display_text": "(GMT-7) America/Chihuahua",
        "offset": -7.0,
        "gmt": "GMT-7"
    },
    "America/Costa_Rica": {
        "display_text": "(GMT-6) America/Costa Rica",
        "offset": -6.0,
        "gmt": "GMT-6"
    },
    "America/Creston": {
        "display_text": "(GMT-7) America/Creston",
        "offset": -7.0,
        "gmt": "GMT-7"
    },
    "America/Cuiaba": {
        "display_text": "(GMT-4) America/Cuiaba",
        "offset": -4.0,
        "gmt": "GMT-4"
    },
    "America/Danmarkshavn": {
        "display_text": "(GMT+0) America/Danmarkshavn",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "America/Dawson_Creek": {
        "display_text": "(GMT-7) America/Dawson Creek",
        "offset": -7.0,
        "gmt": "GMT-7"
    },
    "America/Dawson": {
        "display_text": "(GMT-7) America/Dawson",
        "offset": -7.0,
        "gmt": "GMT-7"
    },
    "America/Denver": {
        "display_text": "(GMT-7) America/Denver",
        "offset": -7.0,
        "gmt": "GMT-7"
    },
    "America/Detroit": {
        "display_text": "(GMT-5) America/Detroit",
        "offset": -5.0,
        "gmt": "GMT-5"
    },
    "America/Edmonton": {
        "display_text": "(GMT-7) America/Edmonton",
        "offset": -7.0,
        "gmt": "GMT-7"
    },
    "America/Eirunepe": {
        "display_text": "(GMT-5) America/Eirunepe",
        "offset": -5.0,
        "gmt": "GMT-5"
    },
    "America/El_Salvador": {
        "display_text": "(GMT-6) America/El Salvador",
        "offset": -6.0,
        "gmt": "GMT-6"
    },
    "America/Tijuana": {
        "display_text": "(GMT-8) America/Tijuana",
        "offset": -8.0,
        "gmt": "GMT-8"
    },
    "America/Fort_Nelson": {
        "display_text": "(GMT-7) America/Fort Nelson",
        "offset": -7.0,
        "gmt": "GMT-7"
    },
    "America/Fort_Wayne": {
        "display_text": "(GMT-5) America/Fort Wayne",
        "offset": -5.0,
        "gmt": "GMT-5"
    },
    "America/Fortaleza": {
        "display_text": "(GMT-3) America/Fortaleza",
        "offset": -3.0,
        "gmt": "GMT-3"
    },
    "America/Glace_Bay": {
        "display_text": "(GMT-4) America/Glace Bay",
        "offset": -4.0,
        "gmt": "GMT-4"
    },
    "America/Godthab": {
        "display_text": "(GMT+0) America/Godthab",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "America/Goose_Bay": {
        "display_text": "(GMT-4) America/Goose Bay",
        "offset": -4.0,
        "gmt": "GMT-4"
    },
    "America/Grand_Turk": {
        "display_text": "(GMT-5) America/Grand Turk",
        "offset": -5.0,
        "gmt": "GMT-5"
    },
    "America/Guatemala": {
        "display_text": "(GMT-6) America/Guatemala",
        "offset": -6.0,
        "gmt": "GMT-6"
    },
    "America/Guayaquil": {
        "display_text": "(GMT-5) America/Guayaquil",
        "offset": -5.0,
        "gmt": "GMT-5"
    },
    "America/Guyana": {
        "display_text": "(GMT-4) America/Guyana",
        "offset": -4.0,
        "gmt": "GMT-4"
    },
    "America/Halifax": {
        "display_text": "(GMT-4) America/Halifax",
        "offset": -4.0,
        "gmt": "GMT-4"
    },
    "America/Havana": {
        "display_text": "(GMT-5) America/Havana",
        "offset": -5.0,
        "gmt": "GMT-5"
    },
    "America/Hermosillo": {
        "display_text": "(GMT-7) America/Hermosillo",
        "offset": -7.0,
        "gmt": "GMT-7"
    },
    "America/Indiana/Knox": {
        "display_text": "(GMT-6) America/Indiana/Knox",
        "offset": -6.0,
        "gmt": "GMT-6"
    },
    "America/Indiana/Marengo": {
        "display_text": "(GMT-5) America/Indiana/Marengo",
        "offset": -5.0,
        "gmt": "GMT-5"
    },
    "America/Indiana/Petersburg": {
        "display_text": "(GMT-5) America/Indiana/Petersburg",
        "offset": -5.0,
        "gmt": "GMT-5"
    },
    "America/Indiana/Tell_City": {
        "display_text": "(GMT-6) America/Indiana/Tell City",
        "offset": -6.0,
        "gmt": "GMT-6"
    },
    "America/Indiana/Vevay": {
        "display_text": "(GMT-5) America/Indiana/Vevay",
        "offset": -5.0,
        "gmt": "GMT-5"
    },
    "America/Indiana/Vincennes": {
        "display_text": "(GMT-5) America/Indiana/Vincennes",
        "offset": -5.0,
        "gmt": "GMT-5"
    },
    "America/Indiana/Winamac": {
        "display_text": "(GMT-5) America/Indiana/Winamac",
        "offset": -5.0,
        "gmt": "GMT-5"
    },
    "America/Inuvik": {
        "display_text": "(GMT-7) America/Inuvik",
        "offset": -7.0,
        "gmt": "GMT-7"
    },
    "America/Iqaluit": {
        "display_text": "(GMT-5) America/Iqaluit",
        "offset": -5.0,
        "gmt": "GMT-5"
    },
    "America/Jamaica": {
        "display_text": "(GMT-5) America/Jamaica",
        "offset": -5.0,
        "gmt": "GMT-5"
    },
    "America/Juneau": {
        "display_text": "(GMT-9) America/Juneau",
        "offset": -9.0,
        "gmt": "GMT-9"
    },
    "America/Kentucky/Louisville": {
        "display_text": "(GMT-5) America/Kentucky/Louisville",
        "offset": -5.0,
        "gmt": "GMT-5"
    },
    "America/Kentucky/Monticello": {
        "display_text": "(GMT-5) America/Kentucky/Monticello",
        "offset": -5.0,
        "gmt": "GMT-5"
    },
    "America/La_Paz": {
        "display_text": "(GMT-4) America/La Paz",
        "offset": -4.0,
        "gmt": "GMT-4"
    },
    "America/Lima": {
        "display_text": "(GMT-5) America/Lima",
        "offset": -5.0,
        "gmt": "GMT-5"
    },
    "America/Los_Angeles": {
        "display_text": "(GMT-8) America/Los Angeles",
        "offset": -8.0,
        "gmt": "GMT-8"
    },
    "America/Maceio": {
        "display_text": "(GMT-3) America/Maceio",
        "offset": -3.0,
        "gmt": "GMT-3"
    },
    "America/Managua": {
        "display_text": "(GMT-6) America/Managua",
        "offset": -6.0,
        "gmt": "GMT-6"
    },
    "America/Manaus": {
        "display_text": "(GMT-4) America/Manaus",
        "offset": -4.0,
        "gmt": "GMT-4"
    },
    "America/Martinique": {
        "display_text": "(GMT-4) America/Martinique",
        "offset": -4.0,
        "gmt": "GMT-4"
    },
    "America/Matamoros": {
        "display_text": "(GMT-6) America/Matamoros",
        "offset": -6.0,
        "gmt": "GMT-6"
    },
    "America/Mazatlan": {
        "display_text": "(GMT-7) America/Mazatlan",
        "offset": -7.0,
        "gmt": "GMT-7"
    },
    "America/Menominee": {
        "display_text": "(GMT-6) America/Menominee",
        "offset": -6.0,
        "gmt": "GMT-6"
    },
    "America/Merida": {
        "display_text": "(GMT-6) America/Merida",
        "offset": -6.0,
        "gmt": "GMT-6"
    },
    "America/Metlakatla": {
        "display_text": "(GMT-9) America/Metlakatla",
        "offset": -9.0,
        "gmt": "GMT-9"
    },
    "America/Mexico_City": {
        "display_text": "(GMT-6) America/Mexico City",
        "offset": -6.0,
        "gmt": "GMT-6"
    },
    "America/Miquelon": {
        "display_text": "(GMT-3) America/Miquelon",
        "offset": -3.0,
        "gmt": "GMT-3"
    },
    "America/Moncton": {
        "display_text": "(GMT-4) America/Moncton",
        "offset": -4.0,
        "gmt": "GMT-4"
    },
    "America/Monterrey": {
        "display_text": "(GMT-6) America/Monterrey",
        "offset": -6.0,
        "gmt": "GMT-6"
    },
    "America/Montevideo": {
        "display_text": "(GMT-3) America/Montevideo",
        "offset": -3.0,
        "gmt": "GMT-3"
    },
    "America/Toronto": {
        "display_text": "(GMT-5) America/Toronto",
        "offset": -5.0,
        "gmt": "GMT-5"
    },
    "America/Nassau": {
        "display_text": "(GMT-5) America/Nassau",
        "offset": -5.0,
        "gmt": "GMT-5"
    },
    "America/New_York": {
        "display_text": "(GMT-5) America/New York",
        "offset": -5.0,
        "gmt": "GMT-5"
    },
    "America/Nipigon": {
        "display_text": "(GMT-5) America/Nipigon",
        "offset": -5.0,
        "gmt": "GMT-5"
    },
    "America/Nome": {
        "display_text": "(GMT-9) America/Nome",
        "offset": -9.0,
        "gmt": "GMT-9"
    },
    "America/Noronha": {
        "display_text": "(GMT-2) America/Noronha",
        "offset": -2.0,
        "gmt": "GMT-2"
    },
    "America/North_Dakota/Beulah": {
        "display_text": "(GMT-6) America/North Dakota/Beulah",
        "offset": -6.0,
        "gmt": "GMT-6"
    },
    "America/North_Dakota/Center": {
        "display_text": "(GMT-6) America/North Dakota/Center",
        "offset": -6.0,
        "gmt": "GMT-6"
    },
    "America/North_Dakota/New_Salem": {
        "display_text": "(GMT-6) America/North Dakota/New Salem",
        "offset": -6.0,
        "gmt": "GMT-6"
    },
    "America/Ojinaga": {
        "display_text": "(GMT-7) America/Ojinaga",
        "offset": -7.0,
        "gmt": "GMT-7"
    },
    "America/Pangnirtung": {
        "display_text": "(GMT-5) America/Pangnirtung",
        "offset": -5.0,
        "gmt": "GMT-5"
    },
    "America/Paramaribo": {
        "display_text": "(GMT-3) America/Paramaribo",
        "offset": -3.0,
        "gmt": "GMT-3"
    },
    "America/Phoenix": {
        "display_text": "(GMT-7) America/Phoenix",
        "offset": -7.0,
        "gmt": "GMT-7"
    },
    "America/Port-au-Prince": {
        "display_text": "(GMT-5) America/Port-au-Prince",
        "offset": -5.0,
        "gmt": "GMT-5"
    },
    "America/Rio_Branco": {
        "display_text": "(GMT-5) America/Rio Branco",
        "offset": -5.0,
        "gmt": "GMT-5"
    },
    "America/Porto_Velho": {
        "display_text": "(GMT-4) America/Porto Velho",
        "offset": -4.0,
        "gmt": "GMT-4"
    },
    "America/Puerto_Rico": {
        "display_text": "(GMT-4) America/Puerto Rico",
        "offset": -4.0,
        "gmt": "GMT-4"
    },
    "America/Punta_Arenas": {
        "display_text": "(GMT-3) America/Punta Arenas",
        "offset": -3.0,
        "gmt": "GMT-3"
    },
    "America/Rainy_River": {
        "display_text": "(GMT-6) America/Rainy River",
        "offset": -6.0,
        "gmt": "GMT-6"
    },
    "America/Rankin_Inlet": {
        "display_text": "(GMT-6) America/Rankin Inlet",
        "offset": -6.0,
        "gmt": "GMT-6"
    },
    "America/Recife": {
        "display_text": "(GMT-3) America/Recife",
        "offset": -3.0,
        "gmt": "GMT-3"
    },
    "America/Regina": {
        "display_text": "(GMT-6) America/Regina",
        "offset": -6.0,
        "gmt": "GMT-6"
    },
    "America/Resolute": {
        "display_text": "(GMT-6) America/Resolute",
        "offset": -6.0,
        "gmt": "GMT-6"
    },
    "America/Santarem": {
        "display_text": "(GMT-3) America/Santarem",
        "offset": -3.0,
        "gmt": "GMT-3"
    },
    "America/Santiago": {
        "display_text": "(GMT-4) America/Santiago",
        "offset": -4.0,
        "gmt": "GMT-4"
    },
    "America/Santo_Domingo": {
        "display_text": "(GMT-4) America/Santo Domingo",
        "offset": -4.0,
        "gmt": "GMT-4"
    },
    "America/Sao_Paulo": {
        "display_text": "(GMT-3) America/Sao Paulo",
        "offset": -3.0,
        "gmt": "GMT-3"
    },
    "America/Scoresbysund": {
        "display_text": "(GMT-1) America/Scoresbysund",
        "offset": -1.0,
        "gmt": "GMT-1"
    },
    "America/Sitka": {
        "display_text": "(GMT-9) America/Sitka",
        "offset": -9.0,
        "gmt": "GMT-9"
    },
    "America/St_Johns": {
        "display_text": "(GMT-3.5) America/St Johns",
        "offset": -3.5,
        "gmt": "GMT-3.5"
    },
    "America/Swift_Current": {
        "display_text": "(GMT-6) America/Swift Current",
        "offset": -6.0,
        "gmt": "GMT-6"
    },
    "America/Tegucigalpa": {
        "display_text": "(GMT-6) America/Tegucigalpa",
        "offset": -6.0,
        "gmt": "GMT-6"
    },
    "America/Thule": {
        "display_text": "(GMT-4) America/Thule",
        "offset": -4.0,
        "gmt": "GMT-4"
    },
    "America/Thunder_Bay": {
        "display_text": "(GMT-5) America/Thunder Bay",
        "offset": -5.0,
        "gmt": "GMT-5"
    },
    "America/Vancouver": {
        "display_text": "(GMT-8) America/Vancouver",
        "offset": -8.0,
        "gmt": "GMT-8"
    },
    "America/Whitehorse": {
        "display_text": "(GMT-7) America/Whitehorse",
        "offset": -7.0,
        "gmt": "GMT-7"
    },
    "America/Winnipeg": {
        "display_text": "(GMT-6) America/Winnipeg",
        "offset": -6.0,
        "gmt": "GMT-6"
    },
    "America/Yakutat": {
        "display_text": "(GMT-9) America/Yakutat",
        "offset": -9.0,
        "gmt": "GMT-9"
    },
    "America/Yellowknife": {
        "display_text": "(GMT-7) America/Yellowknife",
        "offset": -7.0,
        "gmt": "GMT-7"
    },
    "Antarctica/Casey": {
        "display_text": "(GMT+8) Antarctica/Casey",
        "offset": 8.0,
        "gmt": "GMT+8"
    },
    "Antarctica/Davis": {
        "display_text": "(GMT+7) Antarctica/Davis",
        "offset": 7.0,
        "gmt": "GMT+7"
    },
    "Antarctica/DumontDUrville": {
        "display_text": "(GMT+10) Antarctica/DumontDUrville",
        "offset": 10.0,
        "gmt": "GMT+10"
    },
    "Antarctica/Macquarie": {
        "display_text": "(GMT+11) Antarctica/Macquarie",
        "offset": 11.0,
        "gmt": "GMT+11"
    },
    "Antarctica/Mawson": {
        "display_text": "(GMT+5) Antarctica/Mawson",
        "offset": 5.0,
        "gmt": "GMT+5"
    },
    "Pacific/Auckland": {
        "display_text": "(GMT+12) Pacific/Auckland",
        "offset": 12.0,
        "gmt": "GMT+12"
    },
    "Antarctica/Palmer": {
        "display_text": "(GMT-3) Antarctica/Palmer",
        "offset": -3.0,
        "gmt": "GMT-3"
    },
    "Antarctica/Rothera": {
        "display_text": "(GMT-3) Antarctica/Rothera",
        "offset": -3.0,
        "gmt": "GMT-3"
    },
    "Antarctica/Syowa": {
        "display_text": "(GMT+3) Antarctica/Syowa",
        "offset": 3.0,
        "gmt": "GMT+3"
    },
    "Antarctica/Troll": {
        "display_text": "(GMT+0) Antarctica/Troll",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Antarctica/Vostok": {
        "display_text": "(GMT+6) Antarctica/Vostok",
        "offset": 6.0,
        "gmt": "GMT+6"
    },
    "Europe/Oslo": {
        "display_text": "(GMT+1) Europe/Oslo",
        "offset": 1.0,
        "gmt": "GMT+1"
    },
    "Asia/Riyadh": {
        "display_text": "(GMT+3) Asia/Riyadh",
        "offset": 3.0,
        "gmt": "GMT+3"
    },
    "Asia/Almaty": {
        "display_text": "(GMT+6) Asia/Almaty",
        "offset": 6.0,
        "gmt": "GMT+6"
    },
    "Asia/Amman": {
        "display_text": "(GMT+2) Asia/Amman",
        "offset": 2.0,
        "gmt": "GMT+2"
    },
    "Asia/Anadyr": {
        "display_text": "(GMT+12) Asia/Anadyr",
        "offset": 12.0,
        "gmt": "GMT+12"
    },
    "Asia/Aqtau": {
        "display_text": "(GMT+5) Asia/Aqtau",
        "offset": 5.0,
        "gmt": "GMT+5"
    },
    "Asia/Aqtobe": {
        "display_text": "(GMT+5) Asia/Aqtobe",
        "offset": 5.0,
        "gmt": "GMT+5"
    },
    "Asia/Ashgabat": {
        "display_text": "(GMT+5) Asia/Ashgabat",
        "offset": 5.0,
        "gmt": "GMT+5"
    },
    "Asia/Atyrau": {
        "display_text": "(GMT+5) Asia/Atyrau",
        "offset": 5.0,
        "gmt": "GMT+5"
    },
    "Asia/Baghdad": {
        "display_text": "(GMT+3) Asia/Baghdad",
        "offset": 3.0,
        "gmt": "GMT+3"
    },
    "Asia/Qatar": {
        "display_text": "(GMT+3) Asia/Qatar",
        "offset": 3.0,
        "gmt": "GMT+3"
    },
    "Asia/Baku": {
        "display_text": "(GMT+4) Asia/Baku",
        "offset": 4.0,
        "gmt": "GMT+4"
    },
    "Asia/Bangkok": {
        "display_text": "(GMT+7) Asia/Bangkok",
        "offset": 7.0,
        "gmt": "GMT+7"
    },
    "Asia/Barnaul": {
        "display_text": "(GMT+7) Asia/Barnaul",
        "offset": 7.0,
        "gmt": "GMT+7"
    },
    "Asia/Beirut": {
        "display_text": "(GMT+2) Asia/Beirut",
        "offset": 2.0,
        "gmt": "GMT+2"
    },
    "Asia/Bishkek": {
        "display_text": "(GMT+6) Asia/Bishkek",
        "offset": 6.0,
        "gmt": "GMT+6"
    },
    "Asia/Brunei": {
        "display_text": "(GMT+8) Asia/Brunei",
        "offset": 8.0,
        "gmt": "GMT+8"
    },
    "Asia/Kolkata": {
        "display_text": "(GMT+5.5) Asia/Kolkata",
        "offset": 5.5,
        "gmt": "GMT+5.5"
    },
    "Asia/Chita": {
        "display_text": "(GMT+9) Asia/Chita",
        "offset": 9.0,
        "gmt": "GMT+9"
    },
    "Asia/Choibalsan": {
        "display_text": "(GMT+8) Asia/Choibalsan",
        "offset": 8.0,
        "gmt": "GMT+8"
    },
    "Asia/Shanghai": {
        "display_text": "(GMT+8) Asia/Shanghai",
        "offset": 8.0,
        "gmt": "GMT+8"
    },
    "Asia/Colombo": {
        "display_text": "(GMT+5.5) Asia/Colombo",
        "offset": 5.5,
        "gmt": "GMT+5.5"
    },
    "Asia/Dhaka": {
        "display_text": "(GMT+6) Asia/Dhaka",
        "offset": 6.0,
        "gmt": "GMT+6"
    },
    "Asia/Damascus": {
        "display_text": "(GMT+2) Asia/Damascus",
        "offset": 2.0,
        "gmt": "GMT+2"
    },
    "Asia/Dili": {
        "display_text": "(GMT+9) Asia/Dili",
        "offset": 9.0,
        "gmt": "GMT+9"
    },
    "Asia/Dubai": {
        "display_text": "(GMT+4) Asia/Dubai",
        "offset": 4.0,
        "gmt": "GMT+4"
    },
    "Asia/Dushanbe": {
        "display_text": "(GMT+5) Asia/Dushanbe",
        "offset": 5.0,
        "gmt": "GMT+5"
    },
    "Asia/Famagusta": {
        "display_text": "(GMT+2) Asia/Famagusta",
        "offset": 2.0,
        "gmt": "GMT+2"
    },
    "Asia/Gaza": {
        "display_text": "(GMT+2) Asia/Gaza",
        "offset": 2.0,
        "gmt": "GMT+2"
    },
    "Asia/Hebron": {
        "display_text": "(GMT+2) Asia/Hebron",
        "offset": 2.0,
        "gmt": "GMT+2"
    },
    "Asia/Ho_Chi_Minh": {
        "display_text": "(GMT+7) Asia/Ho Chi Minh",
        "offset": 7.0,
        "gmt": "GMT+7"
    },
    "Asia/Hong_Kong": {
        "display_text": "(GMT+8) Asia/Hong Kong",
        "offset": 8.0,
        "gmt": "GMT+8"
    },
    "Asia/Hovd": {
        "display_text": "(GMT+7) Asia/Hovd",
        "offset": 7.0,
        "gmt": "GMT+7"
    },
    "Asia/Irkutsk": {
        "display_text": "(GMT+8) Asia/Irkutsk",
        "offset": 8.0,
        "gmt": "GMT+8"
    },
    "Europe/Istanbul": {
        "display_text": "(GMT+3) Europe/Istanbul",
        "offset": 3.0,
        "gmt": "GMT+3"
    },
    "Asia/Jakarta": {
        "display_text": "(GMT+7) Asia/Jakarta",
        "offset": 7.0,
        "gmt": "GMT+7"
    },
    "Asia/Jayapura": {
        "display_text": "(GMT+9) Asia/Jayapura",
        "offset": 9.0,
        "gmt": "GMT+9"
    },
    "Asia/Jerusalem": {
        "display_text": "(GMT+2) Asia/Jerusalem",
        "offset": 2.0,
        "gmt": "GMT+2"
    },
    "Asia/Kabul": {
        "display_text": "(GMT+4.5) Asia/Kabul",
        "offset": 4.5,
        "gmt": "GMT+4.5"
    },
    "Asia/Kamchatka": {
        "display_text": "(GMT+12) Asia/Kamchatka",
        "offset": 12.0,
        "gmt": "GMT+12"
    },
    "Asia/Karachi": {
        "display_text": "(GMT+5) Asia/Karachi",
        "offset": 5.0,
        "gmt": "GMT+5"
    },
    "Asia/Urumqi": {
        "display_text": "(GMT+6) Asia/Urumqi",
        "offset": 6.0,
        "gmt": "GMT+6"
    },
    "Asia/Kathmandu": {
        "display_text": "(GMT+5.75) Asia/Kathmandu",
        "offset": 5.75,
        "gmt": "GMT+5.75"
    },
    "Asia/Khandyga": {
        "display_text": "(GMT+9) Asia/Khandyga",
        "offset": 9.0,
        "gmt": "GMT+9"
    },
    "Asia/Krasnoyarsk": {
        "display_text": "(GMT+7) Asia/Krasnoyarsk",
        "offset": 7.0,
        "gmt": "GMT+7"
    },
    "Asia/Kuala_Lumpur": {
        "display_text": "(GMT+8) Asia/Kuala Lumpur",
        "offset": 8.0,
        "gmt": "GMT+8"
    },
    "Asia/Kuching": {
        "display_text": "(GMT+8) Asia/Kuching",
        "offset": 8.0,
        "gmt": "GMT+8"
    },
    "Asia/Macau": {
        "display_text": "(GMT+8) Asia/Macau",
        "offset": 8.0,
        "gmt": "GMT+8"
    },
    "Asia/Magadan": {
        "display_text": "(GMT+11) Asia/Magadan",
        "offset": 11.0,
        "gmt": "GMT+11"
    },
    "Asia/Makassar": {
        "display_text": "(GMT+8) Asia/Makassar",
        "offset": 8.0,
        "gmt": "GMT+8"
    },
    "Asia/Manila": {
        "display_text": "(GMT+8) Asia/Manila",
        "offset": 8.0,
        "gmt": "GMT+8"
    },
    "Asia/Nicosia": {
        "display_text": "(GMT+2) Asia/Nicosia",
        "offset": 2.0,
        "gmt": "GMT+2"
    },
    "Asia/Novokuznetsk": {
        "display_text": "(GMT+7) Asia/Novokuznetsk",
        "offset": 7.0,
        "gmt": "GMT+7"
    },
    "Asia/Novosibirsk": {
        "display_text": "(GMT+7) Asia/Novosibirsk",
        "offset": 7.0,
        "gmt": "GMT+7"
    },
    "Asia/Omsk": {
        "display_text": "(GMT+6) Asia/Omsk",
        "offset": 6.0,
        "gmt": "GMT+6"
    },
    "Asia/Oral": {
        "display_text": "(GMT+5) Asia/Oral",
        "offset": 5.0,
        "gmt": "GMT+5"
    },
    "Asia/Pontianak": {
        "display_text": "(GMT+7) Asia/Pontianak",
        "offset": 7.0,
        "gmt": "GMT+7"
    },
    "Asia/Pyongyang": {
        "display_text": "(GMT+9) Asia/Pyongyang",
        "offset": 9.0,
        "gmt": "GMT+9"
    },
    "Asia/Qostanay": {
        "display_text": "(GMT+6) Asia/Qostanay",
        "offset": 6.0,
        "gmt": "GMT+6"
    },
    "Asia/Qyzylorda": {
        "display_text": "(GMT+5) Asia/Qyzylorda",
        "offset": 5.0,
        "gmt": "GMT+5"
    },
    "Asia/Rangoon": {
        "display_text": "(GMT+6.5) Asia/Rangoon",
        "offset": 6.5,
        "gmt": "GMT+6.5"
    },
    "Asia/Sakhalin": {
        "display_text": "(GMT+11) Asia/Sakhalin",
        "offset": 11.0,
        "gmt": "GMT+11"
    },
    "Asia/Samarkand": {
        "display_text": "(GMT+5) Asia/Samarkand",
        "offset": 5.0,
        "gmt": "GMT+5"
    },
    "Asia/Seoul": {
        "display_text": "(GMT+9) Asia/Seoul",
        "offset": 9.0,
        "gmt": "GMT+9"
    },
    "Asia/Srednekolymsk": {
        "display_text": "(GMT+11) Asia/Srednekolymsk",
        "offset": 11.0,
        "gmt": "GMT+11"
    },
    "Asia/Taipei": {
        "display_text": "(GMT+8) Asia/Taipei",
        "offset": 8.0,
        "gmt": "GMT+8"
    },
    "Asia/Tashkent": {
        "display_text": "(GMT+5) Asia/Tashkent",
        "offset": 5.0,
        "gmt": "GMT+5"
    },
    "Asia/Tbilisi": {
        "display_text": "(GMT+4) Asia/Tbilisi",
        "offset": 4.0,
        "gmt": "GMT+4"
    },
    "Asia/Tehran": {
        "display_text": "(GMT+3.5) Asia/Tehran",
        "offset": 3.5,
        "gmt": "GMT+3.5"
    },
    "Asia/Thimphu": {
        "display_text": "(GMT+6) Asia/Thimphu",
        "offset": 6.0,
        "gmt": "GMT+6"
    },
    "Asia/Tokyo": {
        "display_text": "(GMT+9) Asia/Tokyo",
        "offset": 9.0,
        "gmt": "GMT+9"
    },
    "Asia/Tomsk": {
        "display_text": "(GMT+7) Asia/Tomsk",
        "offset": 7.0,
        "gmt": "GMT+7"
    },
    "Asia/Ulaanbaatar": {
        "display_text": "(GMT+8) Asia/Ulaanbaatar",
        "offset": 8.0,
        "gmt": "GMT+8"
    },
    "Asia/Ust-Nera": {
        "display_text": "(GMT+10) Asia/Ust-Nera",
        "offset": 10.0,
        "gmt": "GMT+10"
    },
    "Asia/Vladivostok": {
        "display_text": "(GMT+10) Asia/Vladivostok",
        "offset": 10.0,
        "gmt": "GMT+10"
    },
    "Asia/Yakutsk": {
        "display_text": "(GMT+9) Asia/Yakutsk",
        "offset": 9.0,
        "gmt": "GMT+9"
    },
    "Asia/Yekaterinburg": {
        "display_text": "(GMT+5) Asia/Yekaterinburg",
        "offset": 5.0,
        "gmt": "GMT+5"
    },
    "Asia/Yerevan": {
        "display_text": "(GMT+4) Asia/Yerevan",
        "offset": 4.0,
        "gmt": "GMT+4"
    },
    "Atlantic/Azores": {
        "display_text": "(GMT-1) Atlantic/Azores",
        "offset": -1.0,
        "gmt": "GMT-1"
    },
    "Atlantic/Bermuda": {
        "display_text": "(GMT-4) Atlantic/Bermuda",
        "offset": -4.0,
        "gmt": "GMT-4"
    },
    "Atlantic/Canary": {
        "display_text": "(GMT+0) Atlantic/Canary",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Atlantic/Cape_Verde": {
        "display_text": "(GMT-1) Atlantic/Cape Verde",
        "offset": -1.0,
        "gmt": "GMT-1"
    },
    "Atlantic/Faroe": {
        "display_text": "(GMT+0) Atlantic/Faroe",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Atlantic/Madeira": {
        "display_text": "(GMT+0) Atlantic/Madeira",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Atlantic/Reykjavik": {
        "display_text": "(GMT+0) Atlantic/Reykjavik",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Atlantic/South_Georgia": {
        "display_text": "(GMT-2) Atlantic/South Georgia",
        "offset": -2.0,
        "gmt": "GMT-2"
    },
    "Atlantic/Stanley": {
        "display_text": "(GMT-3) Atlantic/Stanley",
        "offset": -3.0,
        "gmt": "GMT-3"
    },
    "Australia/Sydney": {
        "display_text": "(GMT+10) Australia/Sydney",
        "offset": 10.0,
        "gmt": "GMT+10"
    },
    "Australia/Adelaide": {
        "display_text": "(GMT+9.5) Australia/Adelaide",
        "offset": 9.5,
        "gmt": "GMT+9.5"
    },
    "Australia/Brisbane": {
        "display_text": "(GMT+10) Australia/Brisbane",
        "offset": 10.0,
        "gmt": "GMT+10"
    },
    "Australia/Broken_Hill": {
        "display_text": "(GMT+9.5) Australia/Broken Hill",
        "offset": 9.5,
        "gmt": "GMT+9.5"
    },
    "Australia/Currie": {
        "display_text": "(GMT+10) Australia/Currie",
        "offset": 10.0,
        "gmt": "GMT+10"
    },
    "Australia/Darwin": {
        "display_text": "(GMT+9.5) Australia/Darwin",
        "offset": 9.5,
        "gmt": "GMT+9.5"
    },
    "Australia/Eucla": {
        "display_text": "(GMT+8.75) Australia/Eucla",
        "offset": 8.75,
        "gmt": "GMT+8.75"
    },
    "Australia/Hobart": {
        "display_text": "(GMT+10) Australia/Hobart",
        "offset": 10.0,
        "gmt": "GMT+10"
    },
    "Australia/Lord_Howe": {
        "display_text": "(GMT+10.5) Australia/Lord Howe",
        "offset": 10.5,
        "gmt": "GMT+10.5"
    },
    "Australia/Lindeman": {
        "display_text": "(GMT+10) Australia/Lindeman",
        "offset": 10.0,
        "gmt": "GMT+10"
    },
    "Australia/Melbourne": {
        "display_text": "(GMT+10) Australia/Melbourne",
        "offset": 10.0,
        "gmt": "GMT+10"
    },
    "Australia/Perth": {
        "display_text": "(GMT+8) Australia/Perth",
        "offset": 8.0,
        "gmt": "GMT+8"
    },
    "CET": {
        "display_text": "(GMT+1) CET",
        "offset": 1.0,
        "gmt": "GMT+1"
    },
    "Pacific/Easter": {
        "display_text": "(GMT-6) Pacific/Easter",
        "offset": -6.0,
        "gmt": "GMT-6"
    },
    "CST6CDT": {
        "display_text": "(GMT-6) CST6CDT",
        "offset": -6.0,
        "gmt": "GMT-6"
    },
    "EET": {
        "display_text": "(GMT+2) EET",
        "offset": 2.0,
        "gmt": "GMT+2"
    },
    "Europe/Dublin": {
        "display_text": "(GMT+0) Europe/Dublin",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "EST": {
        "display_text": "(GMT-5) EST",
        "offset": -5.0,
        "gmt": "GMT-5"
    },
    "EST5EDT": {
        "display_text": "(GMT-5) EST5EDT",
        "offset": -5.0,
        "gmt": "GMT-5"
    },
    "Etc/GMT-0": {
        "display_text": "(GMT+0) Etc/GMT-0",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Etc/GMT-1": {
        "display_text": "(GMT+1) Etc/GMT-1",
        "offset": 1.0,
        "gmt": "GMT+1"
    },
    "Pacific/Port_Moresby": {
        "display_text": "(GMT+10) Pacific/Port Moresby",
        "offset": 10.0,
        "gmt": "GMT+10"
    },
    "Etc/GMT-11": {
        "display_text": "(GMT+11) Etc/GMT-11",
        "offset": 11.0,
        "gmt": "GMT+11"
    },
    "Pacific/Tarawa": {
        "display_text": "(GMT+12) Pacific/Tarawa",
        "offset": 12.0,
        "gmt": "GMT+12"
    },
    "Etc/GMT-13": {
        "display_text": "(GMT+13) Etc/GMT-13",
        "offset": 13.0,
        "gmt": "GMT+13"
    },
    "Etc/GMT-14": {
        "display_text": "(GMT+14) Etc/GMT-14",
        "offset": 14.0,
        "gmt": "GMT+14"
    },
    "Etc/GMT-2": {
        "display_text": "(GMT+2) Etc/GMT-2",
        "offset": 2.0,
        "gmt": "GMT+2"
    },
    "Etc/GMT-3": {
        "display_text": "(GMT+3) Etc/GMT-3",
        "offset": 3.0,
        "gmt": "GMT+3"
    },
    "Etc/GMT-4": {
        "display_text": "(GMT+4) Etc/GMT-4",
        "offset": 4.0,
        "gmt": "GMT+4"
    },
    "Etc/GMT-5": {
        "display_text": "(GMT+5) Etc/GMT-5",
        "offset": 5.0,
        "gmt": "GMT+5"
    },
    "Etc/GMT-6": {
        "display_text": "(GMT+6) Etc/GMT-6",
        "offset": 6.0,
        "gmt": "GMT+6"
    },
    "Indian/Christmas": {
        "display_text": "(GMT+7) Indian/Christmas",
        "offset": 7.0,
        "gmt": "GMT+7"
    },
    "Etc/GMT-8": {
        "display_text": "(GMT+8) Etc/GMT-8",
        "offset": 8.0,
        "gmt": "GMT+8"
    },
    "Pacific/Palau": {
        "display_text": "(GMT+9) Pacific/Palau",
        "offset": 9.0,
        "gmt": "GMT+9"
    },
    "Etc/GMT+1": {
        "display_text": "(GMT-1) Etc/GMT+1",
        "offset": -1.0,
        "gmt": "GMT-1"
    },
    "Etc/GMT+10": {
        "display_text": "(GMT-10) Etc/GMT+10",
        "offset": -10.0,
        "gmt": "GMT-10"
    },
    "Etc/GMT+11": {
        "display_text": "(GMT-11) Etc/GMT+11",
        "offset": -11.0,
        "gmt": "GMT-11"
    },
    "Etc/GMT+12": {
        "display_text": "(GMT-12) Etc/GMT+12",
        "offset": -12.0,
        "gmt": "GMT-12"
    },
    "Etc/GMT+3": {
        "display_text": "(GMT-3) Etc/GMT+3",
        "offset": -3.0,
        "gmt": "GMT-3"
    },
    "Etc/GMT+4": {
        "display_text": "(GMT-4) Etc/GMT+4",
        "offset": -4.0,
        "gmt": "GMT-4"
    },
    "Etc/GMT+5": {
        "display_text": "(GMT-5) Etc/GMT+5",
        "offset": -5.0,
        "gmt": "GMT-5"
    },
    "Etc/GMT+6": {
        "display_text": "(GMT-6) Etc/GMT+6",
        "offset": -6.0,
        "gmt": "GMT-6"
    },
    "Etc/GMT+7": {
        "display_text": "(GMT-7) Etc/GMT+7",
        "offset": -7.0,
        "gmt": "GMT-7"
    },
    "Etc/GMT+8": {
        "display_text": "(GMT-8) Etc/GMT+8",
        "offset": -8.0,
        "gmt": "GMT-8"
    },
    "Etc/GMT+9": {
        "display_text": "(GMT-9) Etc/GMT+9",
        "offset": -9.0,
        "gmt": "GMT-9"
    },
    "Etc/UTC": {
        "display_text": "(GMT+0) Etc/UTC",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Europe/Amsterdam": {
        "display_text": "(GMT+1) Europe/Amsterdam",
        "offset": 1.0,
        "gmt": "GMT+1"
    },
    "Europe/Andorra": {
        "display_text": "(GMT+1) Europe/Andorra",
        "offset": 1.0,
        "gmt": "GMT+1"
    },
    "Europe/Astrakhan": {
        "display_text": "(GMT+4) Europe/Astrakhan",
        "offset": 4.0,
        "gmt": "GMT+4"
    },
    "Europe/Athens": {
        "display_text": "(GMT+2) Europe/Athens",
        "offset": 2.0,
        "gmt": "GMT+2"
    },
    "Europe/London": {
        "display_text": "(GMT+0) Europe/London",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Europe/Belgrade": {
        "display_text": "(GMT+1) Europe/Belgrade",
        "offset": 1.0,
        "gmt": "GMT+1"
    },
    "Europe/Berlin": {
        "display_text": "(GMT+1) Europe/Berlin",
        "offset": 1.0,
        "gmt": "GMT+1"
    },
    "Europe/Prague": {
        "display_text": "(GMT+1) Europe/Prague",
        "offset": 1.0,
        "gmt": "GMT+1"
    },
    "Europe/Brussels": {
        "display_text": "(GMT+1) Europe/Brussels",
        "offset": 1.0,
        "gmt": "GMT+1"
    },
    "Europe/Bucharest": {
        "display_text": "(GMT+2) Europe/Bucharest",
        "offset": 2.0,
        "gmt": "GMT+2"
    },
    "Europe/Budapest": {
        "display_text": "(GMT+1) Europe/Budapest",
        "offset": 1.0,
        "gmt": "GMT+1"
    },
    "Europe/Zurich": {
        "display_text": "(GMT+1) Europe/Zurich",
        "offset": 1.0,
        "gmt": "GMT+1"
    },
    "Europe/Chisinau": {
        "display_text": "(GMT+2) Europe/Chisinau",
        "offset": 2.0,
        "gmt": "GMT+2"
    },
    "Europe/Copenhagen": {
        "display_text": "(GMT+1) Europe/Copenhagen",
        "offset": 1.0,
        "gmt": "GMT+1"
    },
    "Europe/Gibraltar": {
        "display_text": "(GMT+1) Europe/Gibraltar",
        "offset": 1.0,
        "gmt": "GMT+1"
    },
    "Europe/Helsinki": {
        "display_text": "(GMT+2) Europe/Helsinki",
        "offset": 2.0,
        "gmt": "GMT+2"
    },
    "Europe/Kaliningrad": {
        "display_text": "(GMT+2) Europe/Kaliningrad",
        "offset": 2.0,
        "gmt": "GMT+2"
    },
    "Europe/Kiev": {
        "display_text": "(GMT+2) Europe/Kiev",
        "offset": 2.0,
        "gmt": "GMT+2"
    },
    "Europe/Kirov": {
        "display_text": "(GMT+3) Europe/Kirov",
        "offset": 3.0,
        "gmt": "GMT+3"
    },
    "Europe/Lisbon": {
        "display_text": "(GMT+0) Europe/Lisbon",
        "offset": 0.0,
        "gmt": "GMT+0"
    },
    "Europe/Luxembourg": {
        "display_text": "(GMT+1) Europe/Luxembourg",
        "offset": 1.0,
        "gmt": "GMT+1"
    },
    "Europe/Madrid": {
        "display_text": "(GMT+1) Europe/Madrid",
        "offset": 1.0,
        "gmt": "GMT+1"
    },
    "Europe/Malta": {
        "display_text": "(GMT+1) Europe/Malta",
        "offset": 1.0,
        "gmt": "GMT+1"
    },
    "Europe/Minsk": {
        "display_text": "(GMT+3) Europe/Minsk",
        "offset": 3.0,
        "gmt": "GMT+3"
    },
    "Europe/Monaco": {
        "display_text": "(GMT+1) Europe/Monaco",
        "offset": 1.0,
        "gmt": "GMT+1"
    },
    "Europe/Moscow": {
        "display_text": "(GMT+3) Europe/Moscow",
        "offset": 3.0,
        "gmt": "GMT+3"
    },
    "Europe/Paris": {
        "display_text": "(GMT+1) Europe/Paris",
        "offset": 1.0,
        "gmt": "GMT+1"
    },
    "Europe/Riga": {
        "display_text": "(GMT+2) Europe/Riga",
        "offset": 2.0,
        "gmt": "GMT+2"
    },
    "Europe/Rome": {
        "display_text": "(GMT+1) Europe/Rome",
        "offset": 1.0,
        "gmt": "GMT+1"
    },
    "Europe/Samara": {
        "display_text": "(GMT+4) Europe/Samara",
        "offset": 4.0,
        "gmt": "GMT+4"
    },
    "Europe/Saratov": {
        "display_text": "(GMT+4) Europe/Saratov",
        "offset": 4.0,
        "gmt": "GMT+4"
    },
    "Europe/Simferopol": {
        "display_text": "(GMT+3) Europe/Simferopol",
        "offset": 3.0,
        "gmt": "GMT+3"
    },
    "Europe/Sofia": {
        "display_text": "(GMT+2) Europe/Sofia",
        "offset": 2.0,
        "gmt": "GMT+2"
    },
    "Europe/Stockholm": {
        "display_text": "(GMT+1) Europe/Stockholm",
        "offset": 1.0,
        "gmt": "GMT+1"
    },
    "Europe/Tallinn": {
        "display_text": "(GMT+2) Europe/Tallinn",
        "offset": 2.0,
        "gmt": "GMT+2"
    },
    "Europe/Tirane": {
        "display_text": "(GMT+1) Europe/Tirane",
        "offset": 1.0,
        "gmt": "GMT+1"
    },
    "Europe/Ulyanovsk": {
        "display_text": "(GMT+4) Europe/Ulyanovsk",
        "offset": 4.0,
        "gmt": "GMT+4"
    },
    "Europe/Uzhgorod": {
        "display_text": "(GMT+2) Europe/Uzhgorod",
        "offset": 2.0,
        "gmt": "GMT+2"
    },
    "Europe/Vienna": {
        "display_text": "(GMT+1) Europe/Vienna",
        "offset": 1.0,
        "gmt": "GMT+1"
    },
    "Europe/Vilnius": {
        "display_text": "(GMT+2) Europe/Vilnius",
        "offset": 2.0,
        "gmt": "GMT+2"
    },
    "Europe/Volgograd": {
        "display_text": "(GMT+4) Europe/Volgograd",
        "offset": 4.0,
        "gmt": "GMT+4"
    },
    "Europe/Warsaw": {
        "display_text": "(GMT+1) Europe/Warsaw",
        "offset": 1.0,
        "gmt": "GMT+1"
    },
    "Europe/Zaporozhye": {
        "display_text": "(GMT+2) Europe/Zaporozhye",
        "offset": 2.0,
        "gmt": "GMT+2"
    },
    "HST": {
        "display_text": "(GMT-10) HST",
        "offset": -10.0,
        "gmt": "GMT-10"
    },
    "Indian/Chagos": {
        "display_text": "(GMT+6) Indian/Chagos",
        "offset": 6.0,
        "gmt": "GMT+6"
    },
    "Indian/Cocos": {
        "display_text": "(GMT+6.5) Indian/Cocos",
        "offset": 6.5,
        "gmt": "GMT+6.5"
    },
    "Indian/Kerguelen": {
        "display_text": "(GMT+5) Indian/Kerguelen",
        "offset": 5.0,
        "gmt": "GMT+5"
    },
    "Indian/Mahe": {
        "display_text": "(GMT+4) Indian/Mahe",
        "offset": 4.0,
        "gmt": "GMT+4"
    },
    "Indian/Maldives": {
        "display_text": "(GMT+5) Indian/Maldives",
        "offset": 5.0,
        "gmt": "GMT+5"
    },
    "Indian/Mauritius": {
        "display_text": "(GMT+4) Indian/Mauritius",
        "offset": 4.0,
        "gmt": "GMT+4"
    },
    "Indian/Reunion": {
        "display_text": "(GMT+4) Indian/Reunion",
        "offset": 4.0,
        "gmt": "GMT+4"
    },
    "Pacific/Kwajalein": {
        "display_text": "(GMT+12) Pacific/Kwajalein",
        "offset": 12.0,
        "gmt": "GMT+12"
    },
    "MET": {
        "display_text": "(GMT+1) MET",
        "offset": 1.0,
        "gmt": "GMT+1"
    },
    "MST": {
        "display_text": "(GMT-7) MST",
        "offset": -7.0,
        "gmt": "GMT-7"
    },
    "MST7MDT": {
        "display_text": "(GMT-7) MST7MDT",
        "offset": -7.0,
        "gmt": "GMT-7"
    },
    "Pacific/Chatham": {
        "display_text": "(GMT+12.75) Pacific/Chatham",
        "offset": 12.75,
        "gmt": "GMT+12.75"
    },
    "Pacific/Apia": {
        "display_text": "(GMT+13) Pacific/Apia",
        "offset": 13.0,
        "gmt": "GMT+13"
    },
    "Pacific/Bougainville": {
        "display_text": "(GMT+11) Pacific/Bougainville",
        "offset": 11.0,
        "gmt": "GMT+11"
    },
    "Pacific/Chuuk": {
        "display_text": "(GMT+10) Pacific/Chuuk",
        "offset": 10.0,
        "gmt": "GMT+10"
    },
    "Pacific/Efate": {
        "display_text": "(GMT+11) Pacific/Efate",
        "offset": 11.0,
        "gmt": "GMT+11"
    },
    "Pacific/Enderbury": {
        "display_text": "(GMT+13) Pacific/Enderbury",
        "offset": 13.0,
        "gmt": "GMT+13"
    },
    "Pacific/Fakaofo": {
        "display_text": "(GMT+13) Pacific/Fakaofo",
        "offset": 13.0,
        "gmt": "GMT+13"
    },
    "Pacific/Fiji": {
        "display_text": "(GMT+12) Pacific/Fiji",
        "offset": 12.0,
        "gmt": "GMT+12"
    },
    "Pacific/Galapagos": {
        "display_text": "(GMT-6) Pacific/Galapagos",
        "offset": -6.0,
        "gmt": "GMT-6"
    },
    "Pacific/Gambier": {
        "display_text": "(GMT-9) Pacific/Gambier",
        "offset": -9.0,
        "gmt": "GMT-9"
    },
    "Pacific/Guadalcanal": {
        "display_text": "(GMT+11) Pacific/Guadalcanal",
        "offset": 11.0,
        "gmt": "GMT+11"
    },
    "Pacific/Guam": {
        "display_text": "(GMT+10) Pacific/Guam",
        "offset": 10.0,
        "gmt": "GMT+10"
    },
    "Pacific/Honolulu": {
        "display_text": "(GMT-10) Pacific/Honolulu",
        "offset": -10.0,
        "gmt": "GMT-10"
    },
    "Pacific/Kiritimati": {
        "display_text": "(GMT+14) Pacific/Kiritimati",
        "offset": 14.0,
        "gmt": "GMT+14"
    },
    "Pacific/Kosrae": {
        "display_text": "(GMT+11) Pacific/Kosrae",
        "offset": 11.0,
        "gmt": "GMT+11"
    },
    "Pacific/Majuro": {
        "display_text": "(GMT+12) Pacific/Majuro",
        "offset": 12.0,
        "gmt": "GMT+12"
    },
    "Pacific/Marquesas": {
        "display_text": "(GMT-9.5) Pacific/Marquesas",
        "offset": -9.5,
        "gmt": "GMT-9.5"
    },
    "Pacific/Pago_Pago": {
        "display_text": "(GMT-11) Pacific/Pago Pago",
        "offset": -11.0,
        "gmt": "GMT-11"
    },
    "Pacific/Nauru": {
        "display_text": "(GMT+12) Pacific/Nauru",
        "offset": 12.0,
        "gmt": "GMT+12"
    },
    "Pacific/Niue": {
        "display_text": "(GMT-11) Pacific/Niue",
        "offset": -11.0,
        "gmt": "GMT-11"
    },
    "Pacific/Norfolk": {
        "display_text": "(GMT+11) Pacific/Norfolk",
        "offset": 11.0,
        "gmt": "GMT+11"
    },
    "Pacific/Noumea": {
        "display_text": "(GMT+11) Pacific/Noumea",
        "offset": 11.0,
        "gmt": "GMT+11"
    },
    "Pacific/Pitcairn": {
        "display_text": "(GMT-8) Pacific/Pitcairn",
        "offset": -8.0,
        "gmt": "GMT-8"
    },
    "Pacific/Pohnpei": {
        "display_text": "(GMT+11) Pacific/Pohnpei",
        "offset": 11.0,
        "gmt": "GMT+11"
    },
    "Pacific/Rarotonga": {
        "display_text": "(GMT-10) Pacific/Rarotonga",
        "offset": -10.0,
        "gmt": "GMT-10"
    },
    "Pacific/Tahiti": {
        "display_text": "(GMT-10) Pacific/Tahiti",
        "offset": -10.0,
        "gmt": "GMT-10"
    },
    "Pacific/Tongatapu": {
        "display_text": "(GMT+13) Pacific/Tongatapu",
        "offset": 13.0,
        "gmt": "GMT+13"
    },
    "PST8PDT": {
        "display_text": "(GMT-8) PST8PDT",
        "offset": -8.0,
        "gmt": "GMT-8"
    },
    "WET": {
        "display_text": "(GMT+0) WET",
        "offset": 0.0,
        "gmt": "GMT+0"
    }
}

export default TimeZones;